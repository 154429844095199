import urls from "./urls"
import globalVariables from "./globalVariables"
import { post } from './serverRequests'

export async function getFreshToken() {
    let refresh_token = localStorage.getItem(globalVariables.AUTH_TOKEN)
    // console.log("refresh token",refresh_token)
    if (refresh_token === null) {
        return false
    }
    return true
}


export async function signOut() {
    let refresh_token = localStorage.getItem(globalVariables.AUTH_TOKEN)
    let headers = {
        "Authorization": `Bearer ${refresh_token}`,
        'content-type': 'application/json'
    }
    let post_data = {}
    let data = await post(urls.SIGNOUT, post_data, headers);
    // console.log("logout")
    if (data !== false) {
        
            localStorage.clear();
          
            window.location.replace('/');
        
           alert(data['detail'])
    
    }
    else {
        alert('Some thing went wrong. Please try again !!!')
    }
}