import React, {useState, useEffect} from 'react';
import Chart from 'react-apexcharts';
import globalVariables from "../../global_files/globalVariables";
import { get } from '../../global_files/serverRequests';
import urls from '../../global_files/urls';


 function Assignee()  {

    const [assigneeName, setAssigneeName] = useState([]);
    const [chartDimensions, setChartDimensions] = useState({width: window.innerWidth * 0.7, height: 540 });

    useEffect(() => {
        const handleResize = () => {
          setChartDimensions({
            width: window.innerWidth * 0.7,
            height: 540,
          });
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []); 


    useEffect( () => {

       const assignee = [];
       
       const getAssignee = async()=>{
        let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
        let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)
        console.log("----------access_tocken in employee list----------",access_tocken);
        console.log("----------dealer_uuid in employee list----------",dealer_uuid);
        let headers = {
        // 'content-type': 'application/json'
        'content-Type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${access_tocken}`,
        }
        const dataRequest = await get(`${urls.BASE_URL}/api/account/${dealer_uuid}/employee_list/`, headers);
        const dataResponse = dataRequest.results
        // console.log("========assigneee data====",dataResponse);
        for(let i=0; i<dataResponse.length; i++){
            assignee.push(dataResponse[i].username);
        }
        console.log("arrayyyyyy",assignee);
        setAssigneeName(assignee)
       }
       getAssignee();
    }, []);
        
    return (

        <div className='vh-100 scroll_hidden'>

        <React.Fragment>
        <div className='container-fluid mb-3' style={{marginLeft:'5cm'}}>
            {/* {assigneeName} */}
            <Chart
            type="bar"
            // width={1050}
            // height={540}
            width={chartDimensions.width}
            height={chartDimensions.height}
            series={[
                {
                    name:"Open",
                    data:[1, 8, 4, 4, 3],
                    color:"#ffc4c4"
                },
                {
                    name:"Inprogress",
                    data:[5, 2, 15, 3, 2],
                    color:"#b9f2ff"
                },
                {
                    name:"On Hold",
                    data:[5, 6, 10, 4, 2],
                    color:"#fcffa4"
                },
                {
                    name:"Accepted",
                    data:[5, 3, 8, 1, 2],
                    color:"#98fb98"
                },
                {
                    name:"Closed",
                    data:[5, 3, 6, 7, 2],
                    color:"#cec8ef"
                },
            ]}

            options={{
                // title:{
                //     text: "Issues' Summary"
                // },
                chart:{
                    stacked:true,
                },
                plotOptions:{
                    bar:{
                        // horizontal: true,
                        columnWidth: '40%'
                    }
                },
                stroke: {
                    width:3,
                },
                xaxis:{
                    title:{
                        text:"Issues by Assignee"
                    },
                    // categories:['Assignee1', 'Assignee2', 'Assignee3', 'Assignee4', 'Assignee5']
                    // categories: assigneeName,
                },
                // yaxis:{
                //     title:{
                //         text:"Issues by status",
                //     },
                    
                // },
                legend:{
                   position:'right' 
                },
                dataLabels:{
                    enabled:false,
                },
                grid:{
                    show:true,  //lines of graph
                }
            }}
            />
        
        </div>
        </React.Fragment>
        </div>
    )
  }


export default Assignee;
