import React, { Component } from 'react';
// import { render } from 'react-dom';
import '../adminDash.css';
// import Button from '@material-ui/core/Button';
// import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { get } from '../../global_files/serverRequests';
import globalVariables from "../../global_files/globalVariables";
import { getFreshToken } from '../../global_files/auth';
// import { Table } from 'reactstrap';
// import Tabpanels from './admin_dashboard';
import ActivityTabs from "../Admin/ActivityTabs";
import urls from '../../global_files/urls';




class MaintenanceDue extends Component {

  constructor(props) {
    super(props);
    this.state = {
    //   ticketInfo:[],
    DueMaint_List:[],
    //   elevator_list:[],
      currentPage: 1,
      rowsPerPage: 25,
      editTicket: null,
      searchInput:''

      
    };
  }

  componentDidMount(){
    // this.getemployeeList();
    this.handleList();
    this.validateSignedIn();
  }


validateSignedIn = async () => {
  let is_fresh_token = await getFreshToken()
  if (!is_fresh_token) {
    console.log("*************************is_fresh_token*******************************", is_fresh_token)
    alert("Your session has expired, please login again!!");
      window.location.replace('/login')
     return
  }
}

 handleList= async ()=>{
        let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
        let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)

          console.log("----------access_tocken in issue list----------",access_tocken)
          let headers = {
            
            'content-Type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${access_tocken}`,
        }
        
        let data = await get(`${urls.BASE_URL}/api/elevators/elevator_summary_indue/?user_id=${dealer_uuid}`, headers)

             console.log("----------data in handle list----------",data);
            //    data = data.results
              // let global_ticket_id = [];
              // global_ticket_id = data.map(ticketId => ticketId.ticket_id);
              if (data && data.length > 0) {
                this.setState({
                  DueMaint_List: data,
                  // global_ticket_id:global_ticket_id,
                });
                console.log("@@@@@@@@@@@@@@@@@In due Maint_List info array : ",this.state.DueMaint_List);
                
                }
  }

   handleChangePage = (newPage) => {
      this.setState({
        currentPage: newPage,
      });
    };

    handleSearchInputChange = (e) => {
        this.setState({ searchInput: e.target.value });
    };


render(){
    const { DueMaint_List, currentPage, rowsPerPage, searchInput } = this.state;
    const filteredTicketInfo = DueMaint_List.filter((list) =>
    list.building_name.toLowerCase().includes(searchInput.toLowerCase())||
    list.elevator_address.toLowerCase().includes(searchInput.toLowerCase()) 
   );

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredTicketInfo.slice(indexOfFirstRow, indexOfLastRow);

    
  return (
    
    <div className='vh-100 scroll_hidden'>

      <ActivityTabs/>
            {/* <div style={{ display: 'flex', backgroundColor:"#664fa7", color:"white", paddingTop:'2px' }}> */}
            <div className="sem-header-container">
                <span><h3 style={{height:"30px", paddingLeft:"15px", marginTop:"1px", paddingTop:"5px"}}> Smart Elevator Maintenance &emsp; &gt;&gt; <Link id="navigation"  to="/amc_count">Home</Link> &gt;&gt; In-Due</h3>
                </span>
                <span style={{ marginLeft: 'auto', marginRight:'10px', marginTop:'9px' }}>
                      <input
                          type="text"
                          id='search-input'
                          size="small"
                        //   value={searchInput}
                          onChange={this.handleSearchInputChange}
                          placeholder=" search "
                    />
                      {/* <button id='search'>Search</button> */}
                      
                    </span>
            </div>
          
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop:'0.3cm', paddingLeft:'8px'}}>
              <h3 style={{paddingLeft:'8px'}}> Maintenance In-Due Site Details   </h3>
              
          </div>
          <br/>
          <div>
            <table>
                <thead>
                <tr>
                      <th>Elevator name</th>
                      <th>Site name</th>
                      {/* <th>customer name</th> */}
                      {/* <th>customer contact</th> */}
                      <th>Installation date</th>
                      <th>Maintenance due date </th>
                      <th>Completed date</th>
                      <th>Engineer name</th>
                      {/* <th>engineer contact</th> */}
                      {/* <th>Action</th> */}
                  </tr>
                </thead>   
                <tbody>
                  {currentRows.map((list) => (
                        <tr key={list.elevator_id}>

                          <td>{list.elevator_name}</td>

                          <td>
                            <span>
                              {list.building_name},&nbsp;{list.elevator_address}
                            </span>
                          </td>

                          {/* <td>{list.client_id}</td> */}
                          

                          {/* <td>customer contact</td> */}
                          <td>{list.install_date}</td>
                          <td>{list.main_due_date}</td>

                          <td>{list.main_completed_date}</td>

                          <td>{list.username}</td>
                        </tr>
                      ))}
                </tbody>
            </table>
          </div>
          <div style={{marginTop:'2.2cm', display: 'flex', justifyContent: 'center', marginBottom: '2.2cm' }}>
            <button id ='rowsPerPageButton' onClick={() => this.handleChangePage(currentPage - 1)} disabled={currentPage === 1}>
              {"< Prev"}
            </button>
            <span style={{fontSize:'small'}}>
              {`Page ${currentPage} / ${Math.ceil(DueMaint_List.length / rowsPerPage)}`}
            </span>
            <button id ='rowsPerPageButton' onClick={() => this.handleChangePage(currentPage + 1)} disabled={currentPage === Math.ceil(DueMaint_List.length / rowsPerPage)}>
              {"Next >"}
            </button>

          </div>
          <br/><br/>
          
          <div id="copyright-footer">
            <p style={{fontSize:'12px'}}>
            <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
            </p>
          </div>
    </div> 
   );
}
}

export default MaintenanceDue;