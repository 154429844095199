import React, { Component } from 'react';
// import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { post, get } from '../../global_files/serverRequests';
import globalVariables from "../../global_files/globalVariables";
// import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';
// import "../Style/Admin.css"
import '../adminDash.css';
import Tabpanels from './tab_component';
import urls from '../../global_files/urls';
import axios from "axios";
import { getFreshToken } from '../../global_files/auth';



class CreateTicketElevatorTechWise extends Component {

  constructor(props) {
    super(props);
    const today = new Date().toISOString().split('T')[0];
    const dealer_user_id= String(localStorage.getItem(globalVariables.USER_ID))
    const user_name = localStorage.getItem(globalVariables.USER_NAME) || '';
    this.state = {
      elevator_id: '',
      ticket_key: '',
      // ticket_id:'',
      create_date:today,
      label : '',
      summary : '',
      description : '',
      reporter_name : dealer_user_id,
      assignee_name : '',
      status : 'Open',
      resolution : 'Unresolved',
      priority : 'Unspecified',
      start_date: today,
      target_date : '',
      close_date :'',
      update_date:'',
      searchTerm: '',
          matches: [],
          content: '',
      employee_list:[],
      elevator_list:[],
      elevator_name_list:[],
      subscriptionIds:[],

      is_required:'',
      customFieldArray:[],
      val: [],
      optionsState:{},
      user_name: user_name,      
    };
  }

  componentDidMount(){
    this.getemployeeList();
    this.getElevatorList();
    this.validateSignedIn();
    this.getPIDListToSendNotification();
    this.getCustomFields()
  }

  validateSignedIn = async () => {
    let is_fresh_token = await getFreshToken()
    if (!is_fresh_token) {
      alert("Your session has expired, please login again!!");
      this.props.history.replace('/');
      return
    }
}
  
  getElevatorList= async ()=>{
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
        
    console.log("----------access_tocken in employee list----------",access_tocken);
     let headers = {
      'content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${access_tocken}`,
  }
  // let response = await get(`${urls.BASE_URL}/api/users/${tech_Id}/elevators/`, headers)
   let data = await get(`${urls.BASE_URL}/api/elevators/`, headers)
      console.log("----------data in getElevator list----------",data);
        
        if (data && data.length > 0) {
           this.setState({
             elevator_list: data
          });
          
          console.log("/******** elevator id array : ",this.state.elevator_list);
                              
        }
      }

  getemployeeList= async ()=>{
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)
    console.log("----------access_tocken in employee list----------",access_tocken)
    let headers = {
      // 'content-type': 'application/json'
      'content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${access_tocken}`,
  }
  let data = await get(`${urls.BASE_URL}/api/account/${dealer_uuid}/employee_list/`, headers)
    // console.log("----------------------------------",data);
    // if (data !== false) {
       data = data.results
        console.log("----------data in employee list----------",data);
        if (data && data.length > 0) {
          this.setState({
            employee_list: data,
          });
          console.log("ticket info array : ",this.state.employee_list);
        }

} 

jsonData = () => {
    let elevator_id = localStorage.getItem(globalVariables.SELECTED_ELEVATOR_id);
    let tech_Id = localStorage.getItem(globalVariables.SELECTED_TECH_ID);

    const dataToSubmit = this.state.customFieldArray.map(field => ({
      custom_field: field.id,
      customised_type: "ticket",
      customised_field_id: this.state.getTicketId,
      value: String(field.value),
    }));
  let data = {
      
      "elevator_id":elevator_id,
      // "ticket_key":this.state.ticket_key,
      // "ticket_id":this.state.ticket_id,
      "summary":this.state.summary, 
      "description":this.state.description,
      "label":this.state.label, 
      "reporter":this.state.reporter_name,
      "assignee":tech_Id,
      "status":this.state.status,
      "resolution":this.state.resolution,
      "priority":this.state.priority,
      "start_date":this.state.start_date,
      "target_date":this.state.target_date,
                  
  }
  if(this.state.create_date){
    data.create_date = this.state.create_date;
  }
  if(this.state.close_date){
    data.close_date = this.state.close_date;
  }
  if(dataToSubmit){
    data.custom_field_values = dataToSubmit;
  }
  console.log("-----JSON DATA -------->",data)
  return JSON.stringify(data)
}
  
  insertCreateTicketInfo = async () => {
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    let label_input = this.state.label;
          console.log("----------access_tocken in issue list----------",access_tocken)
          let headers = {
            // 'content-type': 'application/json'
            'content-Type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${access_tocken}`,
        }
    
    let post_data = this.jsonData()
    console.log("post_data in insertCreateTicketInfo---------->",post_data);
      // Parse the JSON string into an object
  let postDataObj = JSON.parse(post_data);

  for (const key in postDataObj) {
    if (key !== 'close_date' && (postDataObj[key] === null || postDataObj[key] === "")) {
      alert("Please fill in all the required fields.");
      return;
    }
  }
    if (label_input.length>32){
      alert("Label should not be more than 32 characters!");
      return
    }
    try {
      let response = await post(`${urls.BASE_URL}/api/tickets/create/`, post_data, headers)
      console.log("=========response in insertCreateTicketInfo---------->",response)
      if (response) {
        
        console.log("============= successful insertCreateTicketInfo =======", response);
        alert('Ticket created successfully !!!');
        await this.SendNotification();
        window.location.replace("/admin_dashboard");
      } else {
        console.log("============= if insertCreateTicketInfo =======", response);
        alert("Failed to create ticket. Please try again.");
        }
    }catch (error) {
      console.error("Error in insertCreateTicketInfo:", error);
      alert("An error occurred while creating the ticket. Please try again later.");
    }
  }

  
   handleSearch = () => {
    const content = document.body.innerText;

    // Case-insensitive search for the searchTerm in the page content
    const regex = new RegExp(this.state.searchTerm, 'ig');
    const matches = [];
    let match;
    while ((match = regex.exec(content)) !== null) {
      matches.push(match.index);
    }

    this.setState({ matches });
    
  };

  handleDateChange = (event, dateKey) => {
    const selectedDate = event.target.value;
    const formattedDate = this.formatDate(selectedDate);

    this.setState({
      [dateKey]: formattedDate,
    });
  };

  formatDate = (inputDate) => {
    // Parse the input date assuming it's in "dd/mm/yyyy" format
    const [day, month, year] = inputDate.split('/');
    // Create a new Date object using the parsed values
    const parsedDate = new Date(`${year}-${month}-${day}`);
    // Format the date in "yyyy-MM-dd" format
    const formattedDate = parsedDate.toISOString().split('T')[0];
    return formattedDate;
  };

//   getDealerInfo= async ()=>{
//     let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
//     let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)
//     console.log("----------access_tocken in employee list----------",access_tocken)
//     let headers = {
//       // 'content-type': 'application/json'
//       'content-Type': 'application/json; charset=UTF-8',
//       'Authorization': `Bearer ${access_tocken}`,
//   }
//   let response = await get(`${urls.BASE_URL}/api/users/dealer_user/${dealer_uuid}/`, headers) 
//         console.log("-----------------getDealerInfo-----------------",response);
//         this.setState({reporter_name : response.dealer_username})
//         console.log("----------reporter_name----------",this.state.reporter_name);
    

// }

getPIDListToSendNotification= async ()=>{
  let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
  let headers = {
    'content-Type': 'application/json; charset=UTF-8',
    'Authorization': `Bearer ${access_tocken}`,
}
let data = await get(`${urls.BASE_URL}/api/users/get-pid-list-of-tech/`, headers) 
  console.log("----------------------------------",data);
  // if (data !== false) {
     data = data.app_pids
     this.setState({subscriptionIds:data})
      console.log("----------data in getPIDListToSendNotification list----------",data);
    
}

  
SendNotification = async () => {
  const { assignee_name, employee_list, elevator_list,  elevator_id } = this.state;

  // let elevator_id = localStorage.getItem(globalVariables.SELECTED_ELEVATOR_id);
  // let tech_Id = localStorage.getItem(globalVariables.SELECTED_TECH_ID);
  let elevator_name = localStorage.getItem(globalVariables.SELECTED_ELEVATOR);
  // let tech_name = localStorage.getItem(globalVariables.SELECTED_TECH_NAME);
  const selectedEmployee = employee_list.find((item) => item.user_id === assignee_name);
  let Assignee = selectedEmployee ? selectedEmployee.username : null;
  
  let subscriptionIds =this.state.subscriptionIds

  console.log("----tech_name in SendNotification", Assignee);
  console.log("----elevator_name in SendNotification", elevator_name);
  const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Basic ZjRhOGQ4ZGUtOWQxZS00M2I1LWE1MmYtYzFmNzBjYzdlNzA0'
  };

  const body = {
      app_id: '999400f4-2917-4a6e-bf38-28a1e25feb29',
      // included_segments: ['All'], // Send to all subscribers
      include_external_user_ids: subscriptionIds,
      headings:{en: `New Ticket is Created !`},
      contents: { en: `Ticket for elevator ${elevator_name} is Assigned to  ${Assignee}` }, // Notification content
  };

  try {
      const response = await axios.post('https://onesignal.com/api/v1/notifications', body, { headers });
      console.log('Notification sent successfully:', response.data);
  } catch (error) {
      console.error('Error sending notification:', error.response.data);
  }
};

/***********************************custom field load********************************* */

getCustomFields= async ()=>{
  let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
  let headers = {
    'content-Type': 'application/json; charset=UTF-8',
    'Authorization': `Bearer ${access_tocken}`,
}
let data = await get(`${urls.BASE_URL}/api/custom_fields/get_custom_fields_by_type/ticket/`, headers) 
  console.log("----------------getCustomFields ------------------",data);
      this.setState({
        customFieldArray: data
      })

    
}


handleChange = (e, index) => {
  const { customFieldArray } = this.state;
  const updatedFields = [...customFieldArray];

  if (updatedFields[index].format === 'checkbox') {
    if (!Array.isArray(updatedFields[index].value)) {
      updatedFields[index].value = [];
    }
    const checkboxIndex = updatedFields[index].value.findIndex(cb => cb.label === e.target.name);
    if (checkboxIndex !== -1) {
      updatedFields[index].value[checkboxIndex].value = e.target.checked;
    } else {
      updatedFields[index].value.push({ label: e.target.name, value: e.target.checked });
    }
  } else if (updatedFields[index].format === 'boolean') {
    updatedFields[index].value = e.target.checked;
  } else {
    updatedFields[index].value = e.target.value;
  }

  this.setState({
    customFieldArray: updatedFields
  });
  console.log("------custom values in customFieldArray ---------", this.state.customFieldArray);
};


handleMultipleCheckboxChange = (e, idx) => {
    const { optionsState } = this.state;
    // const newOptionsState = {
    //   ...optionsState,
    //   [idx]: e.target.checked
    // };

    this.setState({
      optionsState: {
        ...optionsState,
        [idx]: e.target.checked,
      },
    } , () => {
      console.log("handleMultipleCheckboxChange---->", this.state.optionsState);
  });
}
//default start date is set to current date
handleDateChange = (evt) => {
  this.setState({ start_date: evt.target.value });
}
  
  render(){
    const { employee_list, customFieldArray, optionsState } = this.state;
    let elevator_id = localStorage.getItem(globalVariables.SELECTED_ELEVATOR_id);
    // let tech_Id = localStorage.getItem(globalVariables.SELECTED_TECH_ID);
    let user_name = localStorage.getItem(globalVariables.USER_NAME);
    const dealer_user_id = String(localStorage.getItem(globalVariables.USER_ID)) || '';
  
  return (
    <div>
        <div>
        <Tabpanels/>        
        </div>
            <div className="bordered-div" >
               <div className="form-group">
                  <label htmlFor="ele_Id" >Elevator Id <span style={{color:'red'}}>*</span> : &nbsp;</label>
                  
                  <input
                      type="text"
                      id="ele_Id"
                      name="ele_Id"
                      style={{ width: '150px', height: '0.7cm' }}
                      
                      value={elevator_id}
                      readonly
                    />
               </div>
               <br/>

               <div className="form-group">
                  <label htmlFor="label"> Label <span style={{color:'red'}}>*</span> : &nbsp;</label>
                    <input
                      type="text"
                      id="label"
                      name="label"
                      required
                      style={{ width: '450px', height: '0.7cm' }}
                      placeholder=" fill upto 32 letters only (including spaces)"
                      value={this.state.label}
                      onChange={(evt) => this.setState({ label: evt.target.value })}
                    />
               </div>
               <br/>
               <div className="form-group">
                  <label htmlFor="summary"> Summary <span style={{color:'red'}}>*</span> : &nbsp;</label>
                  <input
                      type="text"
                      id="summary"
                      name="summary"
                      required
                      style={{ width: '450px', height: '0.7cm' }}
                      value={this.state.summary}
                      onChange={(evt) => this.setState({ summary: evt.target.value })}
                    />
               </div>
               <br/>
               
               <div className="form-group">
                  <label htmlFor="desc" > Description <span style={{color:'red'}}>*</span> : &nbsp;</label>
                  <textarea
                      id="desc"
                      name="desc"
                      required
                      style={{ width: '450px', height: '250px', resize: 'none' }}
                      value={this.state.description}
                      onChange={(evt) => this.setState({ description: evt.target.value })}
                    />
               </div>
               <br/>
               <div className="form-group">
                  <label htmlFor="reporter"> Reporter<span style={{color:'red'}}>*</span> : &nbsp;</label>
                  
                  <select
                    id="reporter"
                    name="reporter"
                    style={{ width: '150px', height: '0.7cm' }}
                    required
                    value={this.state.reporter_name}
                    onChange={(evt) => this.setState({ reporter_name: evt.target.value })}
                  >
                    <option value={dealer_user_id}> {user_name} </option>
                    {employee_list.map((item, index) => (
                      <option key={index} value={item.user_id}>
                        {item.username}
                      </option>
                    ))}
                    
                    
                  </select>
               </div>
               <br/>
               <div className="form-group">
                  <label htmlFor="assignee" > Assignee <span style={{color:'red'}}>*</span> : &nbsp;</label>
                  <select
                    id="assignee"
                    name="assignee"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.assignee_name}
                    onChange={(evt) => this.setState({ assignee_name: evt.target.value })}
                  >
                    <option > ----- </option>
                    
                    {employee_list.map((item, index) => (
                      <option key={index} value={item.user_id}>
                        {item.username}
                      </option>
                    ))}
                    
                    
                  </select>
                  {/* <input
                      type="text"
                      id="assignee"
                      name="assignee"
                      style={{ width: '150px', height: '0.7cm' }}
                      
                      value={tech_name}
                      readonly
                    /> */}
               </div>
               <br/>
               <div className="form-group">
                  <label htmlFor="status" > Status <span style={{color:'red'}}>*</span> : &nbsp;</label>
                  <select
                    id="status"
                    name="status"
                    style={{ width: '150px', height: '0.7cm' }}
                    // required
                    value={this.state.status}
                    onChange={(evt) => this.setState({ status: evt.target.value })}
                  >
                    {/* <option> -Select- </option> */}
                    <option value="Open"> Open </option>
                    <option value="InProgress"> In Progress </option>
                    <option value="OnHold"> On Hold </option>
                    <option value="Accepted"> Accepted </option>
                    <option value="Closed"> Closed </option>
                    
                    
                  </select>
               </div>
               <br/>
               <div className="form-group">
                  <label htmlFor="resolution" > Resolution : &nbsp;</label>
                  <select
                    id="resolution"
                    name="resolution"
                    style={{ width: '150px', height: '0.7cm' }}
                    required
                    value={this.state.resolution}
                    onChange={(evt) => this.setState({ resolution: evt.target.value })}
                  >
                    {/* <option> -Select- </option> */}
                    <option value="Unresolved"> Unresolved </option>
                    <option value="CompletedToSpec"> Completed To Spec </option>
                    <option value="CompletedNotSpec"> Completed Not Spec </option>
                    <option value="CannotComplete"> Cannot Complete </option>
                    <option value="Denied"> Denied </option>
                    <option value="Duplicate"> Duplicate </option>
                    
                    
                  </select>
               </div>
               <br/>
               <div className="form-group">
                  <label htmlFor="priority"> Priority <span style={{color:'red'}}>*</span> : &nbsp;</label>
                  <select
                    id="priority"
                    name="priority"
                    style={{ width: '150px', height: '0.7cm' }}
                    required
                    value={this.state.priority}
                    onChange={(evt) => this.setState({ priority: evt.target.value })}
                  >
                    {/* <option> -Select- </option> */}
                    <option value="Unspecified"> Unspecified </option>
                    <option value="Critical"> Critical </option>
                    <option value="High"> High </option>
                    <option value="Medium"> Medium </option>
                    <option value="Low"> Low </option>
                      
                  </select>
               </div>
               <br/>
               <div className="form-group">
               <label htmlFor="createdate"> Create Date : &nbsp;</label>
                  <input 
                  type="date" 
                  id="createdate" 
                  name="createdate" 
                  style={{ width: '150px', height: '0.7cm' }}
                  value={this.state.create_date}
                  onChange={(evt) => this.setState({ create_date: evt.target.value })}
                  ></input>
               </div>
               <br/>
               <div className="form-group">
               <label htmlFor="startdate" > Start Date<span style={{color:'red'}}>*</span> : &nbsp;</label>
                  <input 
                  type="date" 
                  id="startdate" 
                  name="startdate"  
                  style={{ width: '150px', height: '0.7cm' }}
                  required
                  value={this.state.start_date}
                  onChange={(evt) => this.setState({ start_date: evt.target.value })}
                  ></input>
               </div>
               <br/>
               <div className="form-group">
               <label htmlFor="Tdate" > Target Date <span style={{color:'red'}}>*</span> : &nbsp;</label>
                  <input 
                  type="date" 
                  id="Ttdate" 
                  name="Tdate" 
                  style={{ width: '150px', height: '0.7cm' }}
                  value={this.state.target_date}
                  onChange={(evt) => this.setState({ target_date: evt.target.value })}
                  ></input>
               </div>
               <br/>
               <div className="form-group">
               <label htmlFor="cdate"> Close Date : &nbsp;</label>
                  <input 
                  type="date" 
                  id="cdate" 
                  name="cdate"  
                  style={{ width: '150px', height: '0.7cm' }}
                  value={this.state.close_date}
                  onChange={(evt) => this.setState({ close_date: evt.target.value })}
                  ></input>
               </div>

               {customFieldArray.map((data, i) => {
        // Rendering input field based on selected type
        let inputField;
        switch (data.format) {
          case "integer":
            inputField = <input type="number" 
            style={{ width: '180px', height: '0.7cm' }}
            value={data.value} 
            onChange={(e) => this.handleChange(e, i)} 
            onKeyDown={(e) => {
              if (
                !/[0-9]/.test(e.key) &&
                !['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)
              ) {
                e.preventDefault();
              }
            }}
            />;
            break;
          case "date":
            inputField = <input type="date" 
            style={{ width: '150px', height: '0.7cm' }}
            value={data.value} 
            onChange={(e) => this.handleChange(e, i)} 
            />;
            break;
          case "boolean":
            inputField = <input type="checkbox" 
            checked={data.value} 
            onChange={(e) => this.handleChange(e, i)} 
            />;
            break;
          case "dropdown":
              inputField = (
                <div>
                  <select style={{ width: '180px', height: '0.7cm' }} value={data.value}onChange={(e) => this.handleChange(e, i)} >
                  <option value=" "> Select option</option>
                    {data.options.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                     
                    ))}
                  </select>
                  
                </div>
              );
            break;
            case 'checkbox':
              inputField = (
                <div>
                  {data.options && data.options.map((option, idx) => (
                    <>
                    <span key={idx}>
                      <label htmlFor={`option-${idx}`}>{option}</label>
                      <input 
                      type="checkbox" 
                      id={`option-${idx}`}
                      name={option}
                      checked={Array.isArray(data.value) ? data.value.find(cb => cb.label === option)?.value || false : false}
                      onChange={(e) => this.handleChange(e, i)}/> 
                    </span>&ensp;</>
                  ))}
                </div>
              );
            break;
          default:
            inputField = <input type="text" 
            style={{ width: '350px', height: '0.7cm' }}
            value={data.value} 
            onChange={(e) => this.handleChange(e, i)}
             />;
        }
       
        return (
          <div className="form-group" key={i}>
            <label>
            {data.name} : &emsp;</label>
            {inputField} &nbsp;
             <br /><br/>
          
          </div>
          
        );
      })}
         </div>
         
         <div style={{ width: '130px', height: '0.5cm', marginLeft:'170px', marginTop:'8px', marginBottom: '100px'  }}>
          <Link to="/admin_dashboard">
          <button style={{backgroundColor:'#664fa7', color:'white', cursor:'pointer'}} onClick={this.insertCreateTicketInfo}> Create Ticket</button>
          </Link>
         </div>
         <br/>
         <div id="copyright-footer">
            <p style={{fontSize:'12px'}}>
            <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
            </p>
          </div>
    </div>
    
  );
}
}


export default CreateTicketElevatorTechWise;