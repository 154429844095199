import React from 'react';
import '../adminDash.css';
import { Link } from 'react-router-dom';
import Homepage from '../Admin/Homepage';
import Footer from './Footer';
import SettingsTabImage from '../../assets/delete_account_logout.jpg'; 
import SidebarMenuIcon from '../../assets/sidebar_menu_icon.jpg'; 
import SettingsTabMenus from '../../assets/delete_acc2.jpg'; 
import DeleteAccountPopup from '../../assets/final_popup_delete_acc3.jpg'; 
import CompanyLogo from '../../assets/Neudeep_logo.jpeg';


function DeleteAccountInstructions() {
  return (
    <div >
        {/* <Homepage/> */}
        <div className="Homepage">
          <header className="Homepage-header">
            <span className="header-left">
              <img width="160" height="60" marginRight="12px"  src={CompanyLogo}  alt="editIcon"/>
            </span>
            <span> <h1>Smart Elevator Maintenance</h1></span>
            <span className="header-right">
              <Link to="/login" >
                <button className="login-button">Login</button>
              </Link>&nbsp;
            </span>
          </header>
          <nav className="Homepage-nav">
        <ul>
        <Link to="/about" className="link-no-underline1"><li><em>Delete User Account?</em></li></Link>
        </ul>
      </nav>
        </div>
        <div  style={{paddingLeft:'8.6%',paddingRight:'8.6%' }}>
          <div className='privacy'>
          <p className="privacy-bordered-div" style={{fontFamily:'serif', fontSize:'1em' }}>
          <h3 style={{color:'#5390d9'}}>Instructions to Delete User's account</h3>
          <li>When you delete your user account, all personal data associated with your account, including your profile information, usage history, and any stored files, will be permanently erased from our systems.</li>
          <li>This ensures that your private information is fully protected and no longer accessible.</li>
          <li>Our data deletion process complies with industry standards and legal regulations to maintain the highest level of privacy and security for our users.</li>
          <li> If you have any questions or concerns about your data privacy, please contact our support team for assistance.</li>

          <h4 style={{color:'#5e60ce'}}> Steps to follow, to delete your user account </h4>
          <span className="instructions-container">
            <span className="instruction-step">
            <p> 1. Open your android application.<br/> Click on the sidebar menu icon<br/> (upper left corner)</p>
            <img src={SidebarMenuIcon} alt="settingsImage" width={200} height={420}/>
            </span>
            <span className="instruction-step">
              <p>2. When sidebar menu opens,<br/>you will see the below screen.<br/>Click on 'Settings' </p>
            <img src={SettingsTabImage} alt="settingsImage" width={200} height={420}/> 
            </span>
            <span className="instruction-step">
              <p>3. here you can see <br/>'Delete Account' option<br/>click on it</p>
            <img src={SettingsTabMenus} alt="settingsImage" width={200} height={420}/>
            </span>
            <span className="instruction-step">
            <p>4. You will see the pop-up on screen<br/>as below click on 'Delete' option <br/>to delete your account permanently</p>
            <img src={DeleteAccountPopup} alt="settingsImage" width={200} height={420}/> 
            </span>
          </span>

          </p>
        </div>
        
        </div><br/>
        <Footer/>
    </div>
  );
}

export default DeleteAccountInstructions;
