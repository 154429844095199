import React, { Component } from 'react';
import '../adminDash.css';
import { Button  } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { get} from '../../global_files/serverRequests';
import globalVariables from "../../global_files/globalVariables";
import { getFreshToken } from '../../global_files/auth';
import urls from '../../global_files/urls';
import AddIcon from '@material-ui/icons/Add';
import Editicon from '../../assets/icons8-edit.png';
import Deleteicon from '../../assets/icons8-trash-50.png';
import Elevatoricon from '../../assets/icons8-elevator-doors-64.png';
import ReportIcon from '../../assets/report_icon2.png';
import ActivityTabs from "./ActivityTabs";

class ElevatorsTab extends Component {

  constructor(props) {
    super(props);
    this.state = {
      elevators_list:[],
      userr_id:[],
      openTicketCount:0,
      searchInput: '',
      currentPage: 1,
      rowsPerPage: 15,

      pending_ticket_count:0,
      
      open_ticket_count:0,
      assigned_ticket_count:0,
      closed_ticket_count:0,

      selectedTechnician:'',
      selectedFile: null
    };
  }

  // componentDidMount(){
  //   this.getElevatorsList();
  //   this.validateSignedIn();
  // }

  async componentDidMount() {
    const isSignedIn = await this.validateSignedIn();
    if (isSignedIn) {
      this.getElevatorsList();
    }
  }

validateSignedIn = async () => {
  let is_fresh_token = await getFreshToken()
  console.log("-------is_fresh_token----",is_fresh_token);

  if (!is_fresh_token) {
    alert("Your session has expired, please login again!!");
    window.location.replace('/login')
    return false;
  }
  return true;
}

  
  getElevatorsList= async ()=>{
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)
    localStorage.setItem(globalVariables.USER_ID,dealer_uuid)
    console.log("----------access_tocken in elevators Tab list----------",access_tocken);
    console.log("----------dealer_uuid in elevators tab list----------",dealer_uuid);
    let headers = {
      // 'content-type': 'application/json'
      'content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${access_tocken}`,
  }
  let response = await get(`${urls.BASE_URL}/api/users/${dealer_uuid}/elevators/`, headers) //'/users/${user_id}/elevators/'
    // console.log("----------------------------------",data);
    // if (data !== false) {
      let data = response.elevators
        console.log("----------data in elevators tab list----------",data);
        // let userr_Id = [];
        if (data && data.length > 0) {
            this.setState({
            elevators_list: data,
            // userr_id:userr_Id,
          });
          
          console.log("============ elevators tab array : ",this.state.elevators_list);
                    
        }
      }

  handleChangePage = (newPage) => {
          this.setState({
            currentPage: newPage,
          });
      };
  
  handleSearchInputChange = (e) => {
        this.setState({ searchInput: e.target.value });
      };    
  

      
      handleDelete =  async (selected_elevator_id) => {

        let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
          
          console.log("----------access_tocken in handleDelete list----------",access_tocken)
          // const url = `${urls.BASE_URL}/api/tickets/ticket-delete/${ticket_id}/`;
          const userConfirmed = window.confirm('Are you sure you want to delete this Elevator?');
  
          if (!userConfirmed) {
            return; // If the user clicks "Cancel" in the confirmation dialog, abort the delete operation
          }
          
          try {
            const response = await fetch(`${urls.BASE_URL}/api/elevators/${selected_elevator_id}/`, {
              method: 'DELETE',
              headers: {
                // 'content-Type': 'application/json; charset=UTF-8',
                'content-Type': 'application/json',
                'Authorization': `Bearer ${access_tocken}`,
              },
              
            });
            console.log("reponse in delete elevator ------------->", response);
            if (response.ok) {

            //   console.log("reponse.ok ------------->", response.ok);

              // const data =  response.json(); 
              console.log(`Elevator with id ${selected_elevator_id} deleted successfully`, response);
              alert(`Elevator with ID ${selected_elevator_id} deleted successfully`);
              window.location.reload();
              } 
            else {
            //   // Handle error cases
            //   console.error(`Failed to delete ticket with ID ${ticket_id}`); -----------------------
            const errorMessage =  response.statusText; // Read the error message from response body
            // console.error(`Failed to delete user with ID ${SelectedUserId}: ${errorMessage}`);
            // // Handle the error as needed, e.g., show an error message to the user
            alert(`Failed to delete user with ID ${selected_elevator_id}: ${errorMessage}`);
  
            }
          } catch (error) {
            console.error('Error during delete request:', error);
          }
       
      }

      handleEdit = (elevator_id) => {
        localStorage.setItem(globalVariables.SELECTED_ELEVATOR_id, elevator_id);
        window.location.replace('/edit_elevator')
      }; 
      
      handleElevatorClick = (elevator_name, elevator_id) => {
        // Update the state with the selected user_id
            
        localStorage.setItem(globalVariables.SELECTED_ELEVATOR, elevator_name);
        localStorage.setItem(globalVariables.SELECTED_ELEVATOR_id, elevator_id);
           
      };

      handleReport = (elevator_id, elevator_name) => {
        localStorage.setItem(globalVariables.SELECTED_ELEVATOR_id, elevator_id);
        localStorage.setItem(globalVariables.SELECTED_ELEVATOR, elevator_name);
       }; 
      


render(){
    const { elevators_list, currentPage, rowsPerPage, searchInput } = this.state;
       
    // Filter elevators based on the search input
    const filteredElevatorsList = elevators_list.filter((elevator) =>
    elevator.elevator_name.toLowerCase().includes(searchInput.toLowerCase())
    // || 
    // employee.building_name.toLowerCase().includes(searchInput.toLowerCase()) ||
    // employee.elevator_address.toLowerCase().includes(searchInput.toLowerCase())
    );

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredElevatorsList.slice(indexOfFirstRow, indexOfLastRow);

    
  return (
        <div>
          <div>
          <ActivityTabs/>
          {/* <div style={{ display: 'flex', backgroundColor:"#664fa7", color:"white", paddingTop:'2px' }}> */}
          <div className="sem-header-container">
                    <span><h3 style={{height:"30px", paddingLeft:"15px", marginTop:"1px", paddingTop:"5px"}}> Smart Elevator Maintenance &emsp; &gt;&gt; Elevators </h3></span>
                    <span style={{ marginLeft: 'auto', marginRight:'10px', marginTop:'9px' }}>
                    <input
                        type="text"
                        id='search-input'
                        size="small"
                        value={searchInput}
                        onChange={this.handleSearchInputChange}
                        placeholder=" search "
                    />
                    {/* <button id='search'> Search</button> */}
                    
                    </span>
                    
        </div>
          <br/>
          
            <div style={{display: 'flex', justifyContent: 'space-between', marginRight:'5px'}}>
              
              <Link to="/add_elevator" >
                <Button id='contactButton' startIcon={<AddIcon />}> Add Elevator </Button>
              </Link>
              
            </div>
                <br/>

            <div className="grid-container-elevator" style={{marginTop:'15px'}}>
              {currentRows.map((elevator) => (
                <div key={elevator.elevator_id} className="elevator-card">
                    
                    <span style={{ display: 'flex', justifyContent: 'space-between'}}>

                      <img width="26" height="26" src={Elevatoricon} alt="engineer"/>
                      <span>
                        <Link to = "/view_amc_report" style={{ marginRight:"8px"}}>
                        <img width="25" height="25"  src={ReportIcon} id='edit_icon' alt="reportIcon" onClick={() => this.handleReport(elevator.elevator_id, elevator.elevator_name)}/>
                        </Link>

                        <Link style={{ marginRight:"8px"}}>
                        <img width="20" height="20"  src={Editicon} id='edit_icon' alt="editIcon" onClick={() => this.handleEdit(elevator.elevator_id)}/>
                        </Link>
                        
                        
                        <Link onClick={() => this.handleDelete(elevator.elevator_id)} >
                        <img width="20" height="20" src={Deleteicon} id='delete_icon' alt="techIcon"/>
                        </Link>
                      </span>
                    </span>
                    
                    <h3>{elevator.elevator_name}</h3>
                    <p style={{fontSize:'14px'}}>Building name: {elevator.building_name}</p>
                    <p style={{fontSize:'14px'}}>Installation date: {elevator.install_date}</p>
                    <p style={{fontSize:'14px'}}>State: {elevator.main_state}</p>
                    
                    <p style={{fontSize:'14px'}}>Open ticket Count: <Link to={`/admin_dashboard?elevatorName=${encodeURIComponent(elevator.elevator_name)}`} style={{ color: 'neon' }} 
                      onClick={() => this.handleElevatorClick(elevator.elevator_name, elevator.elevator_id)}
                    ><b>{elevator.ticket_count}</b></Link>
                    </p>
                    
                                      
                  </div>
                
              ))}
            </div>
            
          <div style={{marginTop:'2.2cm', display: 'flex', justifyContent: 'center', marginBottom: '2.2cm' }}>
            <button id ='rowsPerPageButton' onClick={() => this.handleChangePage(currentPage - 1)} disabled={currentPage === 1}>
              {"< Prev"}
            </button>&emsp;
            <span style={{fontSize:'small'}}>
              {`Page ${currentPage} / ${Math.ceil(elevators_list.length / rowsPerPage)}`}
            </span>&emsp;
            <button id ='rowsPerPageButton' onClick={() => this.handleChangePage(currentPage + 1)} disabled={currentPage === Math.ceil(elevators_list.length / rowsPerPage)}>
              {"Next >"}
            </button>

          </div>
          </div>
          <br/><br/>
          <div id="copyright-footer">
            <p style={{fontSize:'12px'}}>
            <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
            </p>
          </div>

        </div>
   
  );
}
}
export default ElevatorsTab;