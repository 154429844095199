import React, {useState, useEffect} from 'react';
import Chart from 'react-apexcharts';


function Priority()  {

    const [chartDimensions, setChartDimensions] = useState({width: window.innerWidth * 0.7, height: 540 });

    useEffect(() => {
        const handleResize = () => {
          setChartDimensions({
            width: window.innerWidth * 0.7,
            height: 540,
          });
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []); 

        
    return (

        <div className='vh-100 scroll_hidden'>

        <React.Fragment>
        <div className='container-fluid mb-3' style={{marginLeft:'5cm'}}>
            
            <Chart
            type="bar"
            width={chartDimensions.width}
            height={chartDimensions.height}
            series={[
                {
                    name:"Open",
                    data:[1, 8, 4, 4, 3],
                    color:"#ffc4c4"
                },
                {
                    name:"Inprogress",
                    data:[5, 2, 15, 3, 2],
                    color:"#b9f2ff"
                },
                {
                    name:"On Hold",
                    data:[5, 6, 10, 4, 2],
                    color:"#fcffa4"
                },
                {
                    name:"Accepted",
                    data:[5, 3, 8, 1, 2],
                    color:"#98fb98"
                },
                {
                    name:"Closed",
                    data:[5, 3, 6, 7, 2],
                    color:"#cec8ef"
                },
            ]}

            options={{
                // title:{
                //     text: "Issues' Summary"
                // },
                chart:{
                    stacked:true,
                },
                plotOptions:{
                    bar:{
                        // horizontal: true,
                        columnWidth: '40%'
                    }
                },
                stroke: {
                    width:3,
                },
                xaxis:{
                    title:{
                        text:"Issues by Priority"
                    },
                    categories:['Low', 'Medium', 'High', 'Critical', 'Unspecified']
                },
                // yaxis:{
                //     title:{
                //         text:"Issues by status",
                //     },
                    
                // },
                legend:{
                   position:'right' 
                },
                dataLabels:{
                    enabled:false,
                },
                grid:{
                    show:true,  //lines of graph
                }
            }}
            />
        
        </div>
        </React.Fragment>
        </div>
    )
  }

export default Priority;
