import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { get } from '../../global_files/serverRequests';
import globalVariables from "../../global_files/globalVariables";
import { getFreshToken } from '../../global_files/auth';
import urls from '../../global_files/urls';
import Chart from 'react-apexcharts';
import ActivityTabs from "./ActivityTabs";

class AmcDashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      employee_list:[],
      completed_count:0,
      Due_count:0,
      not_renewed_count:0,

      open_ticket_count:0,
      onhold_ticket_count : 0,
      closed_ticket_count : 0,
      assigned_ticket_count :0 , //inprogress
      inprogress_ticket_count :0,

      main_completed: 0,
      main_due: 0,
      main_overDue: 0,

      user_id: [],
      series1: [44, 55, 41],
      options1: {
        chart: {
          width: 380,
          type: 'donut',
          dropShadow: {
            enabled: true,
            color: '#111',
            top: -1,
            left: 3,
            blur: 3,
            opacity: 0.2
          }
        },
        stroke: {
          width: 0,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true
                }
              }
            }
          }
        },
        labels: [ "amc-Completed", "amc-InDue", "amc-OverDue" ],
        
        states: {
          hover: {
            filter: 'none'
          }
        },
        theme: {
          palette: 'palette1'
        },
        title: {
          text: "AMC Reports"
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },

      series: [44, 55, 41, 17],
            options: {
              chart: {
                width: 300,
                // height: 200,
                type: 'donut',
                dropShadow: {
                  enabled: true,
                  color: '#111',
                  top: -1,
                  left: 3,
                  blur: 3,
                  opacity: 0.2
                }
              },
              stroke: {
                width: 0,
              },
              plotOptions: {
                pie: {
                  donut: {
                    // radius: '80px',
                    labels: {
                      show: true,
                      total: {
                        showAlways: true,
                        show: true
                      }
                    }
                  }
                }
              },
              labels: ["Open", "Accepted",  "Closed", "Inprogress", "OnHold" ],
              // dataLabels: {
              //   dropShadow: {
              //     blur: 3,
              //     opacity: 0.8
              //   }
              // },
              
              // fill: {
              // type: 'pattern',
              //   opacity: 1,
              //   pattern: {
              //     enabled: true,
              //     style: ['verticalLines', 'squares', 'horizontalLines', 'circles','slantedLines'],
              //   },
              // },
              states: {
                hover: {
                  filter: 'none'
                }
              },
              theme: {
                palette: 'palette2'
              },
              title: {
                text: "Issues"
              },
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }]
            },
           
    };
  }

  // componentDidMount(){
  //   this.getMaintenanceCount();
  //   this.validateSignedIn();
  //   this.getTicketCount();
  // }
  async componentDidMount() {
    const isSignedIn = await this.validateSignedIn();
    if (isSignedIn) {
      this.getMaintenanceCount();
      this.getTicketCount();
    }
  }

  validateSignedIn = async () => {
    let is_fresh_token = await getFreshToken()
    console.log("-------is_fresh_token----",is_fresh_token);
    if (!is_fresh_token) {
      alert("Your session has expired, please login again!!");
      window.location.replace('/login')
      return false;
    }
    return true;
}

getMaintenanceCount = async () => {
  try {
    let access_token = localStorage.getItem(globalVariables.AUTH_TOKEN);
    // let user_id = localStorage.getItem(globalVariables.SELECTED_TECH_ID)
    // let elevator_id = localStorage.getItem(globalVariables.SELECTED_ELEVATOR_id)
    let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)

    let headers = {
      'content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${access_token}`,
    };

    let data = await get(`${urls.BASE_URL}/api/elevators/elevator_summary/?user_id=${dealer_uuid}`, headers);
                          
    console.log("----------data in getMaintenanceCount list----------", data);

    if (data!== undefined) {
      this.setState({
        completed_count: data['completed'],
        Due_count: data['indue'],
        not_renewed_count: data['overdue'],
        series1: [
          data['completed'],
          data['indue'],
          data['overdue']          
        ]
      });
      
    }

  } catch (error) {
    console.error('Error fetching open ticket count:', error);
    
  }
};

getTicketCount = async () => {
  try {
    let access_token = localStorage.getItem(globalVariables.AUTH_TOKEN);
    let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)
    // let elevator_id = localStorage.getItem(globalVariables.SELECTED_ELEVATOR_id)
    let headers = {
      'content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${access_token}`,
    };

    let data = await get(`${urls.BASE_URL}/api/tickets/all-ticket-list-emp-count/${dealer_uuid}/`, headers);
    
    console.log("----------data in getTicketCount list----------", data['open_count']);

    if (data !== undefined) {
      // const openAndInProgressCount = data['open_count'] + data['inprogress_count'];
      this.setState({
        open_ticket_count : data['open_count'],
        onhold_ticket_count : data['onhold_count'],
        closed_ticket_count : data['closed_count'],
        assigned_ticket_count : data['accepted_count'],
        inprogress_ticket_count : data['inprogress_count'], 
        series: [
          data['open_count'],
          data['accepted_count'],
          data['closed_count'],
          data['inprogress_count'], 
          data['onhold_count']
        ]
      });
      console.log("----------data in open_ticket_count ----------", this.state.open_ticket_count);
    }

  } catch (error) {
    console.error('Error fetching open ticket count:', error);
    
  }
};

render(){

  return (
    <div>
        <div>
          <ActivityTabs/>
            <div className="sem-header-container">
                <span><h3 style={{height:"30px", paddingLeft:"15px", marginTop:"1px", paddingTop:"5px"}}> Smart Elevator Maintenance &emsp; &gt;&gt; Home </h3>
                </span>
                <span style={{ marginLeft: 'auto', marginRight:'10px', marginTop:'9px' }}>
                      <input
                          type="text"
                          id='search-input'
                          size="small"
                          onChange={this.handleSearchInputChange}
                          placeholder=" search "
                    />
                     
                    </span>
                      
            </div>

            <div className="sem-Home-container">
              <div  className="issue_tracking_container">

                  <div className="issue_tracking" >
                    <h4 style={{marginLeft:'5px'}}> Issue Tracking </h4>
                    <table>
                          <thead>
                            <tr>
                                <th></th>
                                <th>Open </th>
                                <th>Inprogress</th>
                                <th>OnHold</th>
                                <th>Accepted</th>
                                <th>Closed</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                                  <td> <b>Tickets' count</b></td>
                                  <td><Link to ='/all_open_tickets'>{this.state.open_ticket_count}</Link></td>
                                  <td><Link to ='/all_Inprogress_tickets'>{ this.state.inprogress_ticket_count}</Link></td>
                                  <td><Link to ='/all_OnHold_tickets'>{this.state.onhold_ticket_count}</Link></td>
                                  <td><Link to ='/all_accepted_tickets'>{this.state.assigned_ticket_count}</Link></td>
                                  <td><Link to ='/all_closed_tickets'>{ this.state.closed_ticket_count}</Link></td>
                                    
                            </tr> 
                          </tbody>
                    </table>
                    <br/>
                    
                  </div>

                  <div className="issue_tracking" style={{marginTop:'55px'}}>
                      <h4 style={{marginLeft:'5px'}}> AMC Tracking </h4>
                          <table>
                              <thead>
                                  <tr>
                                      <th></th>
                                      <th>Maintenance completed</th>
                                      <th>Maintenance in-due</th>
                                      <th>AMC not renewed</th>
                                                          
                                  </tr>
                              </thead>
                              <tbody>
                      
                                  <tr>
                                      <td> <b> AMC Count</b></td>
                                      
                                      <td><Link to ='/maintenance_completed'>{this.state.completed_count}</Link></td>
                                      
                                      
                                      <td><Link to ='/maintenance_Indue'>{ this.state.Due_count}</Link></td>
                                      
                                      
                                      <td><Link to ='/maintenance_NotRenewed'>{this.state.not_renewed_count}</Link></td>
                                      
                                      
                                  </tr> 
                              </tbody>
                          </table>
                          <br/>
                  </div>
                
              </div>
              <div  className="issue_tracking_container">
                <div className="donut" >
                  <Chart 
                  options={this.state.options} 
                  series={this.state.series} 
                  type="donut" 
                  width="380" />
                </div>
                <div className="donut" >
                  <Chart 
                  options={this.state.options1} 
                  series={this.state.series1} 
                  type="donut" 
                  width="380" />
                </div>
              </div>
            </div>
            <br/>
        </div>
        <br/>
        <br/>
        <div id="copyright-footer">
            <p style={{fontSize:'12px'}}>
            <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
            </p>
          </div>
   </div>
   
  );
}
}
export default AmcDashboard;
