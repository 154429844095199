import React, { Component } from 'react';
// import { render } from 'react-dom';
import '../adminDash.css';
import { Link } from 'react-router-dom';
import { get } from '../../global_files/serverRequests';
import globalVariables from "../../global_files/globalVariables";
import Tabpanel from './tabpanel_allTech_Issues';
import {getFreshToken } from '../../global_files/auth';
import urls from '../../global_files/urls';
import ActivityTabs from "./ActivityTabs";



class CustomerRaisedIssueList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editElevatorId:'',
      editSummary:'',
      editDescription:'',
      editlabel:'',
      editReporter:'',
      editAssignee:'',
      editStatus:'',
      editPriority:'',
      editStartDate:'',
      editTargetDate:'',
      ticketInfo:[],
      employee_list:[],
      elevator_list:[],
      currentPage: 1,
      rowsPerPage: 25,
      editTicket: null,
      searchInput:'',
            
    };
  }

  componentDidMount(){
    this.handleList();
    this.validateSignedIn();
  }

  validateSignedIn = async () => {
    let is_fresh_token = await getFreshToken()
    if (!is_fresh_token) {
      console.log("********************************************************")
      console.log(is_fresh_token)
      console.log("********************************************************")
      alert("Your session has expired, please login again!!");
      window.location.replace('/login')
       return
    }
  }

 handleList= async ()=>{
          let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN);
          let cust_name = localStorage.getItem(globalVariables.SELECTED_CUSTOMER_NAME);
        //   let tech_Id = localStorage.getItem(globalVariables.SELECTED_TECH_ID);
          let dealer_uuid = localStorage.getItem(globalVariables.USER_ID);

          console.log("----------access_tocken in issue list----------",access_tocken)
          console.log("----------selected cust_name----------", cust_name);
          let headers = {
            
            'content-Type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${access_tocken}`,
        }
        // let data = await get(`${urls.BASE_URL}/api/tickets/list/`, headers)
        let data = await get(`${urls.BASE_URL}/api/tickets/all-ticket-list-emp/${dealer_uuid}/?assignee_name=${cust_name}`, headers)
             console.log("----------data in Tech_issues handle list----------",data);
            
              if (data && data.length > 0) {
                this.setState({
                  ticketInfo: data,
                  
                });
                console.log("ticket info array : ",this.state.ticketInfo);
                
                }
  }

      
    // handleEdit = (ticket) => {
    //             localStorage.setItem(globalVariables.SELECTED_TICKET, ticket);
    //             window.location.replace('/update_ticket_from_home_page')
    // };

    // handleDelete =  async (ticketId) => {
    //   let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    //   localStorage.setItem(globalVariables.SELECTED_TICKET, ticketId);
    //   let ticket_id = localStorage.getItem(globalVariables.SELECTED_TICKET);
        
    //     console.log("----------access_tocken in handleDelete list----------",access_tocken)
    //     // const url = `${urls.BASE_URL}/api/tickets/ticket-delete/${ticket_id}/`;
    //     const userConfirmed = window.confirm('Are you sure you want to delete this ticket?');

    //     if (!userConfirmed) {
    //       return; // If the user clicks "Cancel" in the confirmation dialog, abort the delete operation
    //     }
        
    //     try {
    //       const response = await fetch(`${urls.BASE_URL}/api/tickets/ticket-delete/${ticket_id}/`, {
    //         method: 'DELETE',
    //         headers: {
    //           'content-Type': 'application/json; charset=UTF-8',
    //           'Authorization': `Bearer ${access_tocken}`,
    //         },
    //       });
    //       console.log("resssssss in delete ticket Issues", response);
    //       if (response.ok) {
    //         // const data =  response.json(); 
    //         console.log(`Ticket with ID ${ticket_id} deleted successfully`, response);
    //         alert(`Ticket with ID ${ticket_id} deleted successfully`);
    //         this.handleList();
    //         window.location.reload();
    //         } else {
    //       //   // Handle error cases
    //         console.error(`Failed to delete ticket with ID ${ticket_id}`);
    //       }
    //     } catch (error) {
    //       console.error('Error during delete request:', error);
    //     }
        

    // }
  
    // handleSaveEdit = async () => {
    //   this.setState({
    //     editTicket: null,
    //   });
    // };

    handleChangePage = (newPage) => {
      this.setState({
        currentPage: newPage,
      });
    };

    handleSearchInputChange = (e) => {
        this.setState({ searchInput: e.target.value });
    };

render(){
    const { ticketInfo, currentPage, rowsPerPage, searchInput } = this.state;

    let cust_name = localStorage.getItem(globalVariables.SELECTED_CUSTOMER_NAME);

    const filteredTicketInfo = ticketInfo.filter((ticket) =>
    ticket.ticket_key.toLowerCase().includes(searchInput.toLowerCase())||
    ticket.status.toLowerCase().includes(searchInput.toLowerCase()) ||
    ticket.priority.toLowerCase().includes(searchInput.toLowerCase()) ||
    ticket.label.toLowerCase().includes(searchInput.toLowerCase()) ||
    ticket.assignee_name.toString().includes(searchInput.toString())
    );

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredTicketInfo.slice(indexOfFirstRow, indexOfLastRow);

    
  return (
    
    <div className='vh-100 scroll_hidden'>
        <ActivityTabs/>
                {/* <div style={{ display: 'flex', backgroundColor:"#664fa7", color:"white", paddingTop:'2px' }}> */}
                <div className="sem-header-container">
                    <span><h3 style={{height:"30px", paddingLeft:"15px", marginTop:"1px", paddingTop:"5px"}}> Smart Elevator Maintenance &emsp; &gt;&gt; <Link to ="/customers" id="navigation">Customers</Link>&ensp;&gt;&gt; <em>{cust_name}</em> Issues &gt;&gt; 
                    </h3></span>
                    <span style={{ marginLeft: 'auto', marginRight:'5px',marginTop:"9px" }}>
                        <input
                            type="text"
                            id='search-input'
                            size="small"
                            value={searchInput}
                            onChange={this.handleSearchInputChange}
                            placeholder=" search "
                        />
                        {/* <button id='search' onClick={this.handleSearch} >Search</button> */}
                    </span>
                </div>
        <Tabpanel/>
          <h3 style={{paddingLeft:'8px'}}> Tickets </h3>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop:'0.3cm', paddingLeft:'8px'}}>
              <span>
              <input
                            type="text"
                            id='search-ticket'
                            size="small"
                            value={this.state.searchInput}
                            onChange={this.handleSearchInputChange}
                            placeholder=" search Ticket "
                        />
              </span>
              <span>

              </span>
          </div>
          <br/>
          <div>
            <table>
                <thead>
                    <tr>
                        <th>Ticket key</th>
                        <th>Elevator Name</th>
                        <th>Status</th>
                        <th>Priority</th>
                        <th>Label</th>
                        <th>Assignee</th>
                        <th>Updated</th>
                    </tr>
                </thead>   
                <tbody>
                  {currentRows.map((ticket) => (
                        <tr key={ticket.ticket_key}>
                          
                          <td>
                            <span style={{
                                    display: 'inline-block',
                                    backgroundColor:"#664fa7",
                                    color:"white",
                                    paddingTop: '1.1px',
                                    paddingLeft:'2.5px',
                                    paddingRight:'2.5px',
                                    borderRadius: '4px',
                                    }}>
                              {ticket.ticket_key}
                            </span>
                          </td>

                          <td>{ticket.elevator_name} </td>

                          <td style={{ color:ticket.status.toLowerCase() === 'open' ? 'rgb(224, 15, 15)' : 'inherit' }}>{ticket.status}</td>
                          

                          <td>
                              {ticket.priority === 'High' ? (
                                <span style={{color:'rgb(224, 15, 15)', fontSize:'1.2em'}}> &uarr;&uarr; </span>
                              ) : ticket.priority === 'Medium' ? (
                                <span ><img width="12" height="12" src="https://img.icons8.com/metro/26/228BE6/equal-sign.png" alt="equal-sign"/> </span>
                              ) : ticket.priority === 'Critical' ? (
                                <span ><img width="12" height="17" src="https://img.icons8.com/fluency-systems-filled/48/FA5252/chevron.png" alt="chevron"/> </span>
                              ):null}
                              {ticket.priority}
                          </td>

                          <td>{ticket.label}</td>

                          <td>{ticket.assignee_name}</td>

                          <td>
                          {new Date(ticket.update_date).toLocaleString('en-US', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true,
                          })} </td>
                          

                          {/* <td>
                            <button style={{ fontWeight:'bold'}} onClick={() => this.handleEdit(ticket.ticket_id)} >
                              Edit
                            </button> &nbsp;
                            <button style={{ color:'rgb(224, 15, 15)', fontWeight:'bold'}} onClick={() => this.handleDelete(ticket.ticket_id)}>
                              Delete
                            </button>
                          </td> */}
                        </tr>
                      ))}
                </tbody>
            </table>
          </div>
          <div style={{marginTop:'2.2cm', display: 'flex', justifyContent: 'center', marginBottom: '2.2cm' }}>
            <button id ='rowsPerPageButton' onClick={() => this.handleChangePage(currentPage - 1)} disabled={currentPage === 1}>
              {"< Prev"}
            </button>
            <span style={{fontSize:'small'}}>
              {`Page ${currentPage} / ${Math.ceil(ticketInfo.length / rowsPerPage)}`}
            </span>
            <button id ='rowsPerPageButton' onClick={() => this.handleChangePage(currentPage + 1)} disabled={currentPage === Math.ceil(ticketInfo.length / rowsPerPage)}>
              {"Next >"}
            </button>

          </div>
          <div id="copyright-footer">
            <p style={{fontSize:'12px'}}>
            <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
            </p>
          </div>
    </div> 
   );
}
}

export default CustomerRaisedIssueList;