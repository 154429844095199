import React, { Component } from 'react';
import { post, get } from '../../global_files/serverRequests';
import globalVariables from "../../global_files/globalVariables";
import '../adminDash.css';
import TabComponent from './tab_component';
import urls from '../../global_files/urls'
import axios from "axios";
import { getFreshToken } from '../../global_files/auth';


class update_ticket extends Component {

  constructor(props) {
    super(props);
    const user_name = localStorage.getItem(globalVariables.USER_NAME) || '';
    this.state = {
    employee_list:[],
    ticket_data:[],
    subscriptionIds:[],
    editCustomFields:[],
    customFieldArray:[],
    user_name: user_name,
    };
  }

  componentDidMount(){
    this.fetchIssueData();
    this.getemployeeList();
    this.validateSignedIn();
    this.getPIDListToSendNotification();
  }


validateSignedIn = async () => {
  let is_fresh_token = await getFreshToken()
  if (!is_fresh_token) {
    console.log("*************************is_fresh_token*******************************", is_fresh_token)
    alert("Your session has expired, please login again!!");
      window.location.replace('/login')
     return
  }
}

   fetchIssueData=async()=> {
    try {
      let ticket = localStorage.getItem(globalVariables.SELECTED_TICKET);
      let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    
    console.log("----------access_tocken in employee list----------",access_tocken)
    let headers = {
      
      'content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${access_tocken}`,
  }
      let response = await get (`${urls.BASE_URL}/api/tickets/ticket-details/${ticket}/`, headers);
      
      console.log("================Issue data =============", response);
                  this.setState({
                    // ticket_data: response,
                    editElevatorId: response.elevator_id,
                    getTicketId: response.ticket_id,
                    editTicketKey: response.ticket_key,
                    editSummary: response.summary,
                    editDescription: response.description,
                    editlabel: response.label,
                    editReporter: response.reporter_name,
                    editAssignee: response.assignee_name,
                    editStatus: response.status,
                    editResolution: response.resolution,
                    editPriority: response.priority,
                    editStartDate: response.start_date,
                    editTargetDate: response.target_date,
                    editCloseDate: response.close_date,
                    editCustomFields: response.custom_field_values,
               });
                // Call getCustomFields to merge the custom fields with issue data
                this.getCustomFields(response.custom_field_values);

                  // console.log("=========editPriority info  : ", this.state.editPriority);
                  // console.log("=========editResolution info  : ", this.state.editResolution);
                  // console.log("=========editReporter info  : ", this.state.editReporter);
                  // console.log("=========editStatus info  : ", this.state.editStatus);
          // }
    } catch (error) {
      console.error('Error fetching issue data:', error);
    }
  }
  
  getemployeeList= async ()=>{
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)
    console.log("----------access_tocken in employee list----------",access_tocken)
    let headers = {
      'Content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${access_tocken}`,
  }
  let data = await get(`${urls.BASE_URL}/api/account/${dealer_uuid}/employee_list/`, headers)
    // console.log("----------------------------------",data);
    // if (data !== false) {
       data = data.results
        console.log("----------data in employee list----------",data);
        if (data && data.length > 0) {
          this.setState({
            employee_list: data,
          });
          console.log("ticket info array : ",this.state.employee_list);
        }

    }


    jsonData = () => {
      const { editAssignee, employee_list, customFieldArray } = this.state;

      // Find the corresponding user_id based on the selected username
      const selectedEmployee = employee_list.find((item) => item.username === editAssignee);
      const user_id = selectedEmployee ? selectedEmployee.user_id : null;

      const dataToSubmit = customFieldArray.map(field => ({
        custom_field: field.id,
        customised_type: "ticket",
        value: String(field.value),
      }));

      let data = {
          
          // "elevator_id": this.state.editElevatorId,
          // "ticket_key":this.state.ticket_key,
          "ticket_id":this.state.ticket_id,
          
          "summary":this.state.editSummary, 
          "description":this.state.editDescription,
          "label":this.state.editlabel, 
          // "reporter":this.state.reporter_name,
          "assignee":user_id,
          "status":this.state.editStatus,
          "resolution":this.state.resolution,
          "priority":this.state.priority,
          // "create_date":this.state.create_date,
          "start_date":this.state.start_date,
          "target_date":this.state.target_date,
          "close_date":this.state.close_date,
          "custom_field_values":dataToSubmit
      }
      console.log("-----JSON DATA -------->",data)
      return JSON.stringify(data)
    }
  
  insertUpdatedTicketInfo = async () => {
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
          console.log("----------access_tocken in issue list----------",access_tocken)
          let headers = {
            // 'content-type': 'application/json'
            'content-Type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${access_tocken}`,
        }
    
    let post_data = this.jsonData()
    console.log("post_data in insertUpdatedTicketInfo---------->",post_data);
    // Parse the JSON string into an object
  let postDataObj = JSON.parse(post_data);

  for (const key in postDataObj) {
    if (key !== 'close_date' && postDataObj[key] === null || postDataObj[key] === undefined || postDataObj[key] === "") {
      alert("Please fill in all the required fields.");
      return;
    }
  }
    let response = await post(`${urls.BASE_URL}/api/tickets/ticket-update/${this.state.getTicketId}/`, post_data, headers)
    if (response) {
        console.log("response in insertCreateTicketInfo ---->",response)
            alert('Ticket updated successfully !!!')
            
            console.log("=============successfull insertUpdatedTicketInfo =======",response);
           await this.SendNotification();
            window.location.replace("/admin_dashboard");
        }
        
    else {
      alert("Some error/Server error occured, can not update the ticket")
        // console.log("============= can not update =======",response);
        window.location.replace("/admin_dashboard");
    }
  }

 
  
getPIDListToSendNotification= async ()=>{
  let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
   let headers = {
    'content-Type': 'application/json; charset=UTF-8',
    'Authorization': `Bearer ${access_tocken}`,
}
let data = await get(`${urls.BASE_URL}/api/users/get-pid-list-of-tech/`, headers) 
  console.log("----------------------------------",data);
  // if (data !== false) {
     data = data.app_pids
     this.setState({subscriptionIds:data})
      console.log("----------data in getPIDListToSendNotification list----------",data);
   
}
  
  SendNotification = async () => {
    let ticket_key = this.state.editTicketKey
    let editAssignee = this.state.editAssignee
    let status = this.state.editStatus

    let subscriptionIds =this.state.subscriptionIds
    console.log("----ticket key in SendNotification", ticket_key);
    console.log("----assignee in SendNotification", editAssignee);
    const headers = {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Basic ZjRhOGQ4ZGUtOWQxZS00M2I1LWE1MmYtYzFmNzBjYzdlNzA0'
    };

    const body = {
        app_id: '999400f4-2917-4a6e-bf38-28a1e25feb29',
        // included_segments: ['All'], // Send to all subscribers 
        include_external_user_ids: subscriptionIds, //----> specific users  include_player_ids: subscriptionIds, // Target specific users
        headings:{en: `Ticket ${ticket_key} is Updated !`},
        contents: { en: `Ticket status is ${status} and Assignee is ${editAssignee}` }, // Notification content
    };

    try {
        const response = await axios.post('https://onesignal.com/api/v1/notifications', body, { headers });
        console.log('Notification sent successfully:', response.data);
    } catch (error) {
        console.error('Error sending notification:', error.response.data);
    }
};


getCustomFields= async (editCustomFields)=>{
  let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
  let headers = {
    'content-Type': 'application/json; charset=UTF-8',
    'Authorization': `Bearer ${access_tocken}`,
}
let data = await get(`${urls.BASE_URL}/api/custom_fields/get_custom_fields_by_type/ticket/`, headers) 
  console.log("----------------getCustomFields ------------------",data);

  // const initializedData = data.map(field => ({
  //   ...field,
  //   value: field.format === 'boolean' ? false : '' // Initialize boolean fields as false and others as empty string
  // }));

  const initializedData = editCustomFields.map(item => ({
    id: item.custom_field.id,
    name: item.custom_field.name,
    format: item.custom_field.format,
    options: item.custom_field.options,
    value: item.value
  }));
      // this.setState({
      //   customFieldArray: data
      // })
      this.setState({
        customFieldArray: initializedData
      })
     
}


  // handleChange = (e, index) => {
  //   const { customFieldArray } = this.state;
  //   const updatedFields = [...customFieldArray];
  //   updatedFields[index].value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

  //   this.setState({
  //     customFieldArray: updatedFields
  //   });
  //   console.log("------custom values in customFieldArray ---------", this.state.customFieldArray);
  // };

  handleChange = (e, index) => {
    const { customFieldArray } = this.state;
    const updatedFields = [...customFieldArray];
    // updatedFields[index].value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    if (updatedFields[index].format === 'checkbox') {
      const optionLabel = e.target.value;
      const isChecked = e.target.checked;
      // let updatedValue = [...updatedFields[index].value]; // Create a copy of the value array

      let updatedValue;

      // If value is not an array, initialize it as an empty array
      if (!Array.isArray(updatedFields[index].value)) {
        updatedValue = [];
      } else {
        updatedValue = [...updatedFields[index].value]; // Create a copy of the value array
      }
  
      if (isChecked) {
        // If checkbox is checked, add the label to the value array
        updatedValue.push(optionLabel);
      } else {
        // If checkbox is unchecked, remove the label from the value array
        updatedValue = updatedValue.filter(label => label !== optionLabel);
      }
  
      // Update the value of the checkbox field
      updatedFields[index].value = updatedValue;
    } else if (updatedFields[index].format === 'boolean') {
      updatedFields[index].value = e.target.checked;
    } else {
      // For other formats (e.g., integer, date, dropdown), update value directly
      updatedFields[index].value = e.target.value;
    }

    this.setState({
      customFieldArray: updatedFields
    });
    console.log("------custom values in customFieldArray ---------", this.state.customFieldArray);
  };

  
  render(){
    const { employee_list, customFieldArray } = this.state;
    // const { issueData } = this.state;
    let user_name = localStorage.getItem(globalVariables.USER_NAME);

  return (
    <div>
         <div>
         <TabComponent/>    
                </div>
            <div className="bordered-div" >
            <form style={{ marginLeft:'7%'}}>
               <div className="form-group">
                  <label htmlFor="ele_Id" >Elevator Id : &nbsp;</label>
                  <input
                    id="ele_Id"
                    type="text"
                    // name="ele_Id"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.editElevatorId}
                    readOnly
                    // onChange={handleChange}
                  />
                  </div>
               <br/>
               <div className="form-group">
                  <label htmlFor="key" > Ticket key  : &nbsp;</label>
                    <input
                      type="text"
                      id="key"
                      name="key"
                      required
                      style={{ width: '350px', height: '0.7cm' }}
                      value={this.state.editTicketKey}
                      readOnly
                      // onChange={handleChange}
                    />
               </div>
               <br/>
               <div className="form-group">
                  <label htmlFor="label" > Label  : &nbsp;</label>
                    <input
                      type="text"
                      id="label"
                      name="label"
                      required
                      style={{ width: '350px', height: '0.7cm' }}
                      value={this.state.editlabel}
                      onChange={(e) => this.setState({ editlabel: e.target.value })}
                    />
               </div>
               <br/>
               <div className="form-group">
                  <label htmlFor="summary" > Summary : &nbsp;</label>
                  <input
                      type="text"
                      id="summary"
                      name="summary"
                      required
                      style={{ width: '350px', height: '0.7cm' }}
                      value={this.state.editSummary}
                      onChange={(e) => this.setState({ editSummary: e.target.value })}
                    />
               </div>
               <br/>
               <div className="form-group">
                  <label htmlFor="desc" > Description : &nbsp;</label>
                  <input
                      type="text"
                      id="desc"
                      name="desc"
                      required
                      style={{ width: '350px', height: '0.7cm' }}
                      value={this.state.editDescription}
                      onChange={(e) => this.setState({ editDescription: e.target.value })}
                    />
               </div>
               <br/>
               <div className="form-group">
                  <label htmlFor="reporter" > Reporter : &nbsp;</label>
                  <select
                    id="reporter"
                    name="reporter"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.editReporter}
                    // readOnly
                    onChange={(e) => this.setState({ editReporter: e.target.value })}
                  >
                    <option > ----- </option>
                    <option  value={user_name} > {user_name} </option>
                    {employee_list.map((item, index) => (
                      <option key={index} value={item.username}>
                        {item.username}
                      </option>
                    ))}
                  </select>
               </div>
               <br/>
               <div className="form-group">
                  <label htmlFor="assignee"> Assignee : &nbsp;</label>
                  <select
                    id="assignee"
                    name="assignee"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.editAssignee}
                    onChange={(e) => this.setState({ editAssignee: e.target.value })}
                  >
                    <option > ----- </option>
                    
                    {employee_list.map((item, index) => (
                      <option key={index} value={item.username}>
                        {item.username}
                      </option>
                    ))}
                 </select>
               </div>
               <br/>
               <div className="form-group">
                  <label htmlFor="status" > Status : &nbsp;</label>
                  <select
                    id="status"
                    name="status"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.editStatus}
                    onChange={(e) => this.setState({ editStatus: e.target.value })}
                  >
                    <option> -Select- </option>
                    <option value="Open"> Open </option>
                    <option value="InProgress"> In Progress </option>
                    <option value="OnHold"> On Hold </option>
                    <option value="Accepted"> Accepted </option>
                    <option value="Closed"> Closed </option>
                    
                    
                  </select>
               </div>
               <br/>
               <div className="form-group">
                  <label htmlFor="resolution"> Resolution : &nbsp;</label>
                  <select
                    id="resolution"
                    name="resolution"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.editResolution}
                    readOnly
                    // onChange={(e) => this.setState({ editResolution: e.target.value })}
                  >
                    <option> -Select- </option>
                    <option value="Unresolved"> Unresolved </option>
                    <option value="CompletedToSpec"> Completed To Spec </option>
                    <option value="CompletedNotSpec"> Completed Not Spec </option>
                    <option value="CannotComplete"> Cannot Complete </option>
                    <option value="Denied"> Denied </option>
                    <option value="Duplicate"> Duplicate </option>
                    
                    
                  </select>
               </div>
               <br/>
               <div className="form-group">
                  <label htmlFor="priority"> Priority : &nbsp;</label>
                  <select
                    id="priority"
                    name="priority"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.editPriority}
                    readOnly
                    // onChange={(e) => this.setState({ editPriority: e.target.value })}
                  >
                    <option> -Select- </option>
                    <option value="Unspecified"> Unspecified </option>
                    <option value="Critical"> Critical </option>
                    <option value="High"> High </option>
                    <option value="Medium"> Medium </option>
                    <option value="Low"> Low </option>
                      
                  </select>
               </div>
               <br/>
               <div className="form-group">
               <label htmlFor="startdate" > start Date : &nbsp;</label>
                  <input type="date" id="startdate" name="startdate" value={this.state.editStartDate} style={{ width: '150px', height: '0.7cm' }}
                  onChange={(e) => this.setState({ editStartDate: e.target.value })}
                  // readOnly 
                  ></input>
               </div>
               <br/>
               <div className="form-group">
               <label htmlFor="Tdate" > Target Date : &nbsp;</label>
                  <input type="date" id="Ttdate" name="Tdate" value={this.state.editTargetDate}style={{ width: '150px', height: '0.7cm' }}
                  onChange={(e) => this.setState({ editTargetDate: e.target.value })}
                  // readOnly
                  ></input>
               </div>
               <br/>
               <div className="form-group">
               <label htmlFor="cdate" > Close Date  &nbsp;: &nbsp;</label>
                  <input type="date" id="cdate" name="cdate"  value={this.state.editCloseDate} style={{ width: '150px', height: '0.7cm' }}
                  onChange={(e) => this.setState({ editTargetDate: e.target.value })} 
                  // readOnly
                  ></input>
               </div>

               {customFieldArray.map((data, i) => {
        // Rendering input field based on selected type
        let inputField;
        switch (data.format) {
          case "integer":
            inputField = <input type="number" 
            style={{ width: '180px', height: '0.7cm' }}
            value={data.value} 
            onChange={(e) => this.handleChange(e, i)} 
            onKeyDown={(e) => {
              if (
                !/[0-9]/.test(e.key) &&
                !['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)
              ) {
                e.preventDefault();
              }
            }}
            />;
            break;
          case "date":
            inputField = <input type="date" 
            style={{ width: '150px', height: '0.7cm' }}
            value={data.value} 
            onChange={(e) => this.handleChange(e, i)} 
            />;
            break;
          case "boolean":
            inputField = <input type="checkbox" 
            checked={data.value} 
            onChange={(e) => this.handleChange(e, i)} 
            />;
            break;
          case "dropdown":
              inputField = (
                <div>
                  <select style={{ width: '180px', height: '0.7cm' }} value={data.value} onChange={(e) => this.handleChange(e, i)} >
                  <option value=" "> Select option</option>
                    {data.options.map((option, index) => (
                      <option key={index} value={option}>{option}</option>
                    ))}
                  </select>
                  
                </div>
              );
              break;
          case 'checkbox':
              inputField = (
                <div>
                  {data.options && data.options.map((option, index) => (
                    <span key={index}>
                    <label htmlFor={`option-${index}`}>{option}</label>
                    <input 
                      type="checkbox" 
                      id={`option-${index}`} 
                      value={option} 
                      checked={data.value && data.value.includes(option)}  
                      onChange={(e) => this.handleChange(e, i)}
                    /> 
                    &ensp;
                  </span>
                  ))}
                </div>
              );
              break;
          default:
            inputField = <input type="text" 
            style={{ width: '350px', height: '0.7cm' }}
            value={data.value} 
            onChange={(e) => this.handleChange(e, i)}
             />;
        }
       
        return (
          <div className="form-group" style={{marginTop:"20px"}} key={i}>
            <label>
            {data.name} : &emsp;</label>
                {inputField} &nbsp;
                <br />
            
          </div>
          
        );
      })}
      </form>
         </div>
         
         <div style={{ width: '160px', height: '0.4cm', marginLeft:'130px', marginTop:'8px', marginBottom: '100px'  }}>
          <button style={{backgroundColor:'#664fa7', color:'white', cursor:'pointer'}} onClick={this.insertUpdatedTicketInfo}> update Ticket</button>
         </div> 
         <div id="copyright-footer">
            <p style={{fontSize:'12px'}}>
            <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
            </p>
          </div>
    </div>
    
    
    
  )
}
}


export default update_ticket;