import React, { Component } from 'react';
// import { post, get, patch } from '../../global_files/serverRequests';
import globalVariables from "../../global_files/globalVariables";
import '../adminDash.css';
import { getFreshToken } from '../../global_files/auth';
import urls from '../../global_files/urls';
import GlobalTabpanel from './Tab_panel_global';
import { Link } from 'react-router-dom';
import ActivityTabs from "./ActivityTabs";


class EditTech extends Component {

  constructor(props) {
    super(props);
    this.state = {
    employee_list:[],
    ticket_data:[],

    user_type: 'TechnicianUser',
    user_name: '',
    edit_tech_email:'',
    tech_password:'',
    conf_tech_pass:'',
    edit_first_name:'',
    edit_last_name:'',
    edit_mob_num:'',
    dealer_email:'',
    dealer_passcode:'',

        
    };
  }

  componentDidMount(){
     this.fetchTechData();
    // this.getemployeeList();
    this.validateSignedIn();
  }

  validateSignedIn = async () => {
    let is_fresh_token = await getFreshToken()
    if (!is_fresh_token) {
      alert("Your session has expired, please login again!!");
        this.props.history.replace("/");
        // window.location.replace('/login')
        return
    }
    
}

   fetchTechData=async()=> {
    try {
      let tech_id = localStorage.getItem(globalVariables.SELECTED_TECH_ID);
      let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    
    console.log("----------tech_id in fetchTechData----------",tech_id)
    let headers = {
      
      'content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${access_tocken}`,
  }
      // let response = await patch (`${urls.BASE_URL}/api/users/${tech_id}/update_user/`, headers); // https://semapp.in/api/users/$userId/update_user/

      const data = await fetch(`${urls.BASE_URL}/api/users/${tech_id}/update_user/`, {
        headers: headers,
        method: 'PATCH',
        // body: post_data,
    });
      let response = await data.json();

      // Step 3: Populate the input boxes with the fetched data
      // this.setState({ issueData: response });
      console.log("================IfetchTechData ressss=============", response);
      console.log("================fetchTechData data=============", data);
      // if (response && response.length > 0) {
                  this.setState({
                    edit_tech_email:response.data.email,
                    edit_first_name:response.data.first_name,
                    edit_last_name:response.data.last_name,
                    edit_mob_num:response.data.phoneno,
                   
               });
                  console.log("=========edit_mob_num info  : ", this.state.edit_mob_num);
                  console.log("=========edit_tech_email info  : ", this.state.edit_tech_email);
                  console.log("=========edit_first_name info  : ", this.state.edit_first_name);
                  console.log("=========edit_last_name info  : ", this.state.edit_last_name);
          // }
    } catch (error) {
      console.error('Error fetching issue data:', error);
    }
  }
  

    jsonData = () => {
      let data = {
          
        
        "email":this.state.edit_tech_email, 
         
        "first_name":this.state.edit_first_name,
        "last_name":this.state.edit_last_name,
        
        "phoneno": this.state.edit_mob_num
                
      }
      console.log("-----JSON DATA -------->",data)
      return JSON.stringify(data)
    }
  
  insertUpdatedTechInfo = async () => {
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    // localStorage.setItem(globalVariables.USER_ID, selected_user_id);
        // let SelectedUserId = localStorage.getItem(globalVariables.USER_ID);
        let tech_id = localStorage.getItem(globalVariables.SELECTED_TECH_ID);
          console.log("----------access_tocken in insertUpdatedTechInfo----------",access_tocken)
          let headers = {
            // 'content-type': 'application/json'
            'content-Type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${access_tocken}`,
        }
    
    let post_data = this.jsonData()
    console.log("post_data in insertUpdatedTechInfo---------->",post_data);
    // Parse the JSON string into an object
  let postDataObj = JSON.parse(post_data);

  for (const key in postDataObj) {
    if (postDataObj[key] === null || postDataObj[key] === undefined || postDataObj[key] === "") {
      alert("Please fill in all the required fields.");
      return;
    }
  }
  const response = await fetch(`${urls.BASE_URL}/api/users/${tech_id}/update_user/`, {
    headers: headers,
    method: 'PATCH',
    body: post_data,
  });
  console.log("response from update Technician------->", response);
  if (response.ok){
      alert("Technician info updated successfully");
      window.location.replace("/technicians");
  }
  
  }


  render(){

  return (
    <div>
            <ActivityTabs/>
                {/* <div style={{ display: 'flex', backgroundColor:"#664fa7", color:"white", paddingTop:'2px' }}> */}
                <div className="sem-header-container">
                    <span><h3 style={{height:"30px", paddingLeft:"15px", marginTop:"1px", paddingTop:"5px"}}> Smart Elevator Maintenance &emsp; &gt;&gt; &nbsp;
                    <Link id="navigation" to="/technicians">Technicians</Link> &gt;&gt; &nbsp;
                    {/* {tech_name} &gt;&gt; */}
                    <Link id="navigation" >Edit Technician</Link> 
                    {/* {Elevator_name} &gt;&gt; <Link id="navigation" to="/admin_dashboard">Overview</Link> */}
                    </h3></span>
                    <span style={{ marginLeft: 'auto', marginRight:'5px',marginTop:"9px" }}>
                        <input
                            type="text"
                            id='search-input'
                            size="small"
                            // value={searchTerm}
                            // onChange={this.handleSearchChange}
                            placeholder=" search "
                        />
                        {/* <button id='search' onClick={this.handleSearch} >Search</button> */}
                    </span>
                </div>
                <GlobalTabpanel/>
            <div className="bordered-div" >
            <div>
                  <label htmlFor="user_type" style={{marginLeft : '95px'}}> User Type : &emsp;&emsp;&emsp;&emsp;</label>
                    <input
                      type="text"
                      id="user_type"
                      name="user_type"
                      readOnly
                      style={{ width: '350px', height: '0.7cm' }}
                      value={this.state.user_type}
                    //   onChange={(evt) => this.setState({ ticket_key: evt.target.value })}
                    />
               </div>
               <br/>
               {/* <div style={{ marginLeft:'30px'}}>
                  <label htmlFor="user_name" style={{marginLeft : '65px', marginRight:'30px'}}>User name <span style={{color:'red'}}>*</span>&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp;</label>
                  <input
                      type="text"
                      id="user_name"
                      name="user_name"
                      required
                      style={{ width: '350px', height: '0.7cm' }}
                      // value={this.state.label}
                      // onChange={handleChange}
                      placeholder=" fill upto 32 letters only (including spaces)"
                      value={this.state.user_name}
                      onChange={(evt) => this.setState({ user_name: evt.target.value })}
                    />
               </div>
               <br/> */}
               <div>
                  <label htmlFor="tech_email" style={{marginLeft : '95px'}}> Email <span style={{color:'red'}}>*</span>&ensp; : &nbsp;&emsp;&emsp;&emsp;&emsp;&ensp;</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      required
                      style={{ width: '350px', height: '0.7cm' }}
                      value={this.state.edit_tech_email}
                      onChange={(evt) => this.setState({ edit_tech_email: evt.target.value })}
                    />
               </div>
               <br/>
               
               <div>
                  <label htmlFor="first_name" style={{marginLeft : '95px'}}> First name <span style={{color:'red'}}>*</span> &nbsp;&nbsp;&nbsp; : &nbsp;&emsp;&ensp;</label>
                  <input
                      type="text"
                      id="first_name"
                      name="first_name"
                      required
                      style={{ width: '350px', height: '0.7cm' }}
                      
                      value={this.state.edit_first_name}
                      onChange={(evt) => this.setState({ edit_first_name: evt.target.value })}
                    />
               </div>
               <br/>
               <div>
                  <label htmlFor="last_name" style={{marginLeft : '95px'}}> Last name <span style={{color:'red'}}>*</span> &nbsp;&nbsp;&nbsp; : &nbsp;&emsp;&ensp;</label>
                  <input
                      type="text"
                      id="last_name"
                      name="last_name"
                      required
                      style={{ width: '350px', height: '0.7cm' }}
                      
                      value={this.state.edit_last_name}
                      onChange={(evt) => this.setState({ edit_last_name: evt.target.value })}
                    />
               </div>
               <br/>
               <div>
                  <label htmlFor="mob_num" style={{marginLeft : '95px'}}> Mobile no. <span style={{color:'red'}}>*</span> &nbsp;&nbsp;&nbsp; :&nbsp;&emsp;&ensp;</label>
                  <input
                      type="tel"
                      id="mob_num"
                      name="mob_num"
                      required
                      style={{ width: '350px', height: '0.7cm' }}
                      value={this.state.edit_mob_num}
                      onChange={(evt) => this.setState({ edit_mob_num: evt.target.value })}
                    />
               </div>
               <br/>
               

            </div>

         
         <div style={{ width: '160px', height: '0.4cm', marginLeft:'130px', marginTop:'8px', marginBottom: '100px'  }}>
          <button style={{backgroundColor:'#664fa7', color:'white', cursor:'pointer'}} onClick={this.insertUpdatedTechInfo}> update Technician Info</button>
         </div> 
         <div id="copyright-footer">
            <p style={{fontSize:'12px'}}>
            <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
            </p>
          </div>
    </div>
    
    
    
  )
}
}


export default EditTech;