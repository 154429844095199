import React, { Component } from 'react';
// import { render } from 'react-dom';
import '../adminDash.css';
// import Button from '@material-ui/core/Button';
import { Button  } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { get } from '../../global_files/serverRequests';
import globalVariables from "../../global_files/globalVariables";
import { signOut, getFreshToken } from '../../global_files/auth';
import urls from '../../global_files/urls';
import  Techicon from '../../assets/tech-icon.png';
import Editicon from '../../assets/icons8-edit.png';
import Deleteicon from '../../assets/icons8-trash-50.png';
import ActivityTabs from "./ActivityTabs";

class technicianList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      employee_list:[],
      userr_id:[],
      openTicketCount:0,
      searchInput: '',
      currentPage: 1,
      rowsPerPage: 15,

      pending_ticket_count:0,
      open_ticket_count:0,
      assigned_ticket_count:0,
      closed_ticket_count:0,
      selectedTechnician:''
      
    };
  }

  async componentDidMount() {
    const isSignedIn = await this.validateSignedIn();
    if (isSignedIn) {
      this.getemployeeList();
    }
  }

validateSignedIn = async () => {
  let is_fresh_token = await getFreshToken()
  console.log("-------is_fresh_token----",is_fresh_token);
  if (!is_fresh_token) {
     alert("Your session has expired, please login again!!");
      window.location.replace('/login')
      return false;
    }
    return true;
  
}

  
  getemployeeList= async ()=>{
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)
    localStorage.setItem(globalVariables.USER_ID,dealer_uuid)
    console.log("----------access_tocken in employee list----------",access_tocken);
    console.log("----------dealer_uuid in employee list----------",dealer_uuid);
    let headers = {
      // 'content-type': 'application/json'
      'content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${access_tocken}`,
  }
  let response = await get(`${urls.BASE_URL}/api/account/${dealer_uuid}/employee_list/`, headers)
    // console.log("----------------------------------",data);
    // if (data !== false) {
      let data = response.results
        console.log("----------data in employee list----------",data);
        let userr_Id = [];
        if (data && data.length > 0) {
          userr_Id = data.map(employee => employee.user_id);
          console.log("*******************************user_Id****",userr_Id);
          // this.state.userr_id.map(userr_id => this.getOpenTicketCount(userr_id));
          // Loop through userr_id array sequentially
            // for (const userr_id of userr_Id) {
            //   await this.getOpenTicketCount(userr_id);
            // }
          this.setState({
            employee_list: data,
            // userr_id:userr_Id,
          });
          
          
          // if (this.state.userr_id && Array.isArray(this.state.userr_id)) {
          //   await Promise.all( this.state.userr_id.map(userr_id => this.getOpenTicketCount(userr_id)));
          // }
          // this.getTicketCount();
          console.log("============ employee and ticket Count array : ",this.state.employee_list);
                    
        }
      }


// getOpenTicketCount = async (userr_id) => {
//   try {
//     let access_token = localStorage.getItem(globalVariables.AUTH_TOKEN);
//     let headers = {
//       'content-Type': 'application/json; charset=UTF-8',
//       'Authorization': `Bearer ${access_token}`,
//     };

//     let data = await get(`${urls.BASE_URL}/api/tickets/ticket-list-count-employee/${userr_id}/`, headers);
    
//     console.log("----------data in getOpenTicketCount list----------", data);

//     if (data) {
//       this.setState((prevState) => {
//         return {
//           openTicketCount: {
//             ...prevState.openTicketCount,
//             // [userr_id]: data.open,
//             [userr_id]: {
//               open: data.open || 0,
//               // !== undefined ? data.open : prevState.openTicketCount[userr_id]?.open || 0,
//               closed: data.closed || 0, 
//               // !== undefined ? data.closed : prevState.openTicketCount[userr_id]?.closed || 0,
//               onhold: data.onhold || 0
//               // !== undefined ? data.onhold : prevState.openTicketCount[userr_id]?.onhold || 0,
//             },
//           },
//         };
//       });
      
//     }
//   } catch (error) {
//     console.error('Error fetching open ticket count:', error);
//     // Handle the error appropriately
//   }
// };


  handleChangePage = (newPage) => {
          this.setState({
            currentPage: newPage,
          });
      };
  
  handleSearchInputChange = (e) => {
        this.setState({ searchInput: e.target.value });
      };    
  

  handleTechClick = (tech_id,tech_name) => {
        // Update the state with the selected user_id
            
      localStorage.setItem(globalVariables.SELECTED_TECH_ID, tech_id);
      localStorage.setItem(globalVariables.SELECTED_TECH_NAME, tech_name);
      
           console.log("////////globalVariables.selectedTechnician naameeeeeee///////", tech_id);
      };  
      
      handleDelete =  async (selected_user_email) => {

        let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
        // localStorage.setItem(globalVariables.USER_ID);
        let dealer_uuid = localStorage.getItem(globalVariables.USER_ID);
          
          console.log("----------access_tocken in handleDelete list----------",access_tocken)
          // const url = `${urls.BASE_URL}/api/tickets/ticket-delete/${ticket_id}/`;
          const userConfirmed = window.confirm('Are you sure you want to delete this user?');
  
          if (!userConfirmed) {
            return; // If the user clicks "Cancel" in the confirmation dialog, abort the delete operation
          }
          
          try {

            let post_data = {
              "email" : selected_user_email
            }
            let data = JSON.stringify(post_data)

             const response = await fetch(`${urls.BASE_URL}/api/account/${dealer_uuid}/user_delete/`, {
              method: 'PATCH',
              headers: {
                // 'content-Type': 'application/json; charset=UTF-8',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_tocken}`,
              },
              body: data
            });
            console.log("reponse ------------->", response);
            
            // console.log("reponse in delete cust user ------------->", response);
            if (response.ok) {
              // const data =  response.json(); 
              console.log(`User with ID ${selected_user_email} deleted successfully`, response);
              alert(`User with ID ${selected_user_email} deleted successfully`);
              window.location.reload();
              } 
            else {
            //   // Handle error cases
            //   console.error(`Failed to delete ticket with ID ${ticket_id}`); -----------------------
            const errorMessage = response.statusText; // Read the error message from response body
            // console.error(`Failed to delete user with ID ${SelectedUserId}: ${errorMessage}`);
            // // Handle the error as needed, e.g., show an error message to the user
            alert(`Failed to delete user with ID ${selected_user_email}: ${errorMessage}`);
  
            }
          } catch (error) {
            console.error('Error during delete request:', error);
          }
          
  
      }

     handleEdit = (tech_id) => {
        localStorage.setItem(globalVariables.SELECTED_TECH_ID, tech_id);
        window.location.replace('/edit_tech')
    };

  

render(){
    const { employee_list, currentPage, rowsPerPage, searchInput } = this.state;
    let user_name = localStorage.getItem(globalVariables.USER_NAME)
    // const { employee_list } = this.state;
  
    

    // Filter elevators based on the search input
    const filteredEmployeeList = employee_list.filter((employee) =>
    employee.username.toLowerCase().includes(searchInput.toLowerCase())
    
    );

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredEmployeeList.slice(indexOfFirstRow, indexOfLastRow);

  return (
        <div>
          <ActivityTabs/>
              {/* <div style={{ display: 'flex', backgroundColor:"#664fa7", color:"white", paddingTop:'2px' }}> */}
              <div className="sem-header-container">
                    <span><h3 style={{height:"30px", paddingLeft:"15px", marginTop:"1px", paddingTop:"5px"}}> Smart Elevator Maintenance &emsp; &gt;&gt; Technicians </h3></span>
                    <span style={{ marginLeft: 'auto', marginright:'10px', marginTop:'9px' }}>
                    <input
                        type="text"
                        id='search-input'
                        size="small"
                        value={searchInput}
                        onChange={this.handleSearchInputChange}
                        placeholder=" search "
                    />
                                       
                    </span>
                    
        </div>
          <br/>
          
            <div style={{display: 'flex', justifyContent: 'space-between', marginRight:'5px'}}>
              
              <Link to="/add_technicians" >
                <Button id='contactButton' startIcon={<AddIcon />}> Add Technicians </Button>
              </Link>
              {/* <Link to="/all_tech_issues">
                <Button id='contactButton' > Upload Data </Button>
              </Link> */}
              
              
            </div>
                <br/>

            <div className="grid-container" style={{marginTop:'10px'}}>
              {currentRows.map((employee) => (
                <div key={employee.user_id} className="elevator-card">
                  <span style={{ display: 'flex', justifyContent: 'space-between'}}>
                    <img width="26" height="26" src={Techicon} alt="techIcon"/>
                    <span>
                    <Link>
                    <img width="20" height="20" marginRight="12px"  src={Editicon} id='edit_icon' alt="techIcon" title="Edit" onClick={() => this.handleEdit(employee.user_id)}/>
                    </Link>&ensp;
                    
                    
                    <Link >
                    <img width="20" height="20" src={Deleteicon} id='delete_icon' alt="techIcon" title="Delete"  onClick={() => this.handleDelete(employee.email)}/>
                    </Link>
                    </span></span>
                    

                  
                  {/* {TechIcon} */}
                  {/* <Link to={`/elevatorsList`} style={{ color: '#664fa7' }} onClick={() => this.handleTechClick(employee.user_id, employee.username)} > */}
                    <h3 
                    // className="hover-effect"
                    >{employee.username}</h3>
                  {/* </Link> */}

                  <p style={{fontSize:'14px'}}>Employee's ticket count : <Link to={`/tech_issues`} onClick={() => this.handleTechClick(employee.user_id, employee.username)} >
                    <b>{employee.ticket_count}</b></Link><br/>
                  </p>
                    {/* <span style={{display:"flex", justifyContent:"flex-end"}}>
                    <Link to={`/elevatorsList`} onClick={() => this.handleTechClick(employee.user_id, employee.username)} >
                    <img width="19" height="18" src={Deleteicon} id='delete_icon' alt="techIcon"/>
                    </Link>
                    </span> */}
                  </div>
                
              ))}
            </div>
            
          <div style={{marginTop:'2.2cm', display: 'flex', justifyContent: 'center', marginBottom: '2.2cm' }}>
            <button id ='rowsPerPageButton' onClick={() => this.handleChangePage(currentPage - 1)} disabled={currentPage === 1}>
              {"< Prev"}
            </button>&emsp;
            <span style={{fontSize:'small'}}>
              {`Page ${currentPage} / ${Math.ceil(employee_list.length / rowsPerPage)}`}
            </span>&emsp;
            <button id ='rowsPerPageButton' onClick={() => this.handleChangePage(currentPage + 1)} disabled={currentPage === Math.ceil(employee_list.length / rowsPerPage)}>
              {"Next >"}
            </button>

          </div>
          <div id="copyright-footer">
            <p style={{fontSize:'12px'}}>
            <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
            </p>
          </div>

        </div>
   
  );
}
}
export default technicianList;