import React, { Component } from 'react';
// import { render } from 'react-dom';
import '../adminDash.css';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { get } from '../../global_files/serverRequests';
import globalVariables from "../../global_files/globalVariables";
import { getFreshToken } from '../../global_files/auth';
import urls from '../../global_files/urls';
import ActivityTabs from "./ActivityTabs";


class all_tech_issues extends Component {

  constructor(props) {
    super(props);
    this.handleApplyFilters = this.handleApplyFilters.bind(this);
    this.state = {
      ticketInfo:[],
      employee_list:[],

      currentPage: 1,
      rowsPerPage: 20,
      editTicket: null,
      searchTerm:'',
      selectedAssignee:'',
      selectedElevator:'',
      selectedStatus:'',
      filteredTicketInfo: null, 
      statusArray:['open', 'closed', 'InProgress', 'Accepted', 'OnHold']

    };
    
  }

  
  // async componentDidMount() {
  //   const isSignedIn = await this.validateSignedIn();
  //   if (isSignedIn) {
  //     this.handleList();
  //     this.getemployeeList();
  //   }
  // }

  componentDidMount() {

      this.handleList();
      this.getemployeeList();
  }

  validateSignedIn = async () => {
    let is_fresh_token = await getFreshToken()
    console.log("-------is_fresh_token----",is_fresh_token);
    if (!is_fresh_token) {
        alert("Your session has expired, please login again!!");
        window.location.replace('/login')
        return false;
    }
    return true;
}

  handleList= async ()=>{
          let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
          let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)

          // console.log("----------access_tocken in issue list----------",access_tocken)
          console.log("----------dealer_uuid in issue list----------",dealer_uuid)
          let headers = {
            // 'content-type': 'application/json'
            'content-Type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${access_tocken}`,
        }
        // let data = await get(`${urls.BASE_URL}/api/tickets/list/`, headers)  //https://semapp.in/api/tickets/all-ticket-list-emp/${user_id}
         let data = await get(`${urls.BASE_URL}/api/tickets/all-ticket-list-emp/${dealer_uuid}/`, headers)  
            if (data && data.length > 0) {

              let Assignee = []
              Assignee = data.map(employee => employee.assignee_name);
              console.log("********************ticket info***********Assignee****",Assignee);
                this.setState({
                  ticketInfo: data,
                },()=>{
                  console.log("ticket info array all tech issues: ",this.state.ticketInfo);
                });
                
                console.log("ticket info array : ")
                
                }

      }

  handleChangePage = (newPage) => {
          this.setState({
            currentPage: newPage,
          });
      };   
  
      
       
      getemployeeList= async ()=>{
        let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
        let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)
        localStorage.setItem(globalVariables.USER_ID,dealer_uuid)
        console.log("----------access_tocken in employee list----------",access_tocken);
        console.log("----------dealer_uuid in employee list----------",dealer_uuid);
        let headers = {
          // 'content-type': 'application/json'
          'content-Type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${access_tocken}`,
      }
      let response = await get(`${urls.BASE_URL}/api/account/${dealer_uuid}/employee_list/`, headers)
        // console.log("----------------------------------",data);
        // if (data !== false) {
          let data = response.results
            console.log("----------data in employee list----------",data);
            let userr_Id = [];
            if (data && data.length > 0) {
              userr_Id = data.map(employee => employee.first_name);
              console.log("*******************************user_Id****",userr_Id);
              // this.state.userr_id.map(userr_id => this.getOpenTicketCount(userr_id));
              // Loop through userr_id array sequentially
                // for (const userr_id of userr_Id) {
                //   await this.getOpenTicketCount(userr_id);
                // }
              this.setState({
                employee_list: data,
                // userr_id:userr_Id,
              });
              
              
              // if (this.state.userr_id && Array.isArray(this.state.userr_id)) {
              //   await Promise.all( this.state.userr_id.map(userr_id => this.getOpenTicketCount(userr_id)));
              // }
              // this.getTicketCount();
              console.log("============ employee and ticket Count array : ",this.state.employee_list);
                        
            }
          }


    /****************************eidt/update ***************/
    handleEdit = (ticket) => {
      
      localStorage.setItem(globalVariables.SELECTED_TICKET, ticket);
      window.location.replace('/update_ticket_from_home_page')
    };
  

    handleDelete = async (ticketId) => {
      let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
      localStorage.setItem(globalVariables.SELECTED_TICKET, ticketId);
      let ticket_id = localStorage.getItem(globalVariables.SELECTED_TICKET);
        
        console.log("----------access_tocken in handleDelete list----------",access_tocken)
        // const url = `${urls.BASE_URL}/api/tickets/ticket-delete/${ticket_id}/`;
        const userConfirmed = window.confirm('Are you sure you want to delete this ticket?');

        if (!userConfirmed) {
          return; // If the user clicks "Cancel" in the confirmation dialog, abort the delete operation
        }
        
        try {
          const response = await fetch(`${urls.BASE_URL}/api/tickets/ticket-delete/${ticket_id}/`, {
            method: 'DELETE',
            headers: {
              'content-Type': 'application/json; charset=UTF-8',
              'Authorization': `Bearer ${access_tocken}`,
            },
          });
      
          if (response.ok) {
            // const data =  response.json(); 
            console.log(`Ticket with ID ${ticket_id} deleted successfully`, response);
            alert(`Ticket with ID ${ticket_id} deleted successfully`);
            window.location.reload();
            } else {
          //   // Handle error cases
            console.error(`Failed to delete ticket with ID ${ticket_id}`);
          }
        } catch (error) {
          console.error('Error during delete request:', error);
        }
        

    }

    handleSearchChange = (e) => {
      this.setState({ searchTerm: e.target.value });
  };

  /*******************filter records from date- to date************* */

  handleFromDateChange = (date) => {
    console.log("Selected date:", date);
    this.setState({ fromDate: date }, () => {
      console.log("Updated from date:", this.state.fromDate);
    });
    // console.log("Updated from date:", this.state.fromDate);
  };

  handleToDateChange = (date) => {
    // this.setState({ toDate: date });
    this.setState({ toDate: date }, () => {
      console.log("Updated toDate :", this.state.toDate);
    });
    // console.log("tooooooooo date", this.state.toDate);
  };


  handleAssigneeChange = (e) => {
    this.setState({ selectedAssignee: e.target.value });
  };

  handleElevatorChange = (e) => {
    this.setState({ selectedElevator: e.target.value });
  };
  
  handleStatusChange = (e) => {
    this.setState({ selectedStatus: e.target.value });
  };

  handleApplyFilters = () => {
    // Apply filters based on selectedAssignee and selectedStatus
    const { ticketInfo, selectedAssignee, selectedStatus, selectedElevator } = this.state;
  
    const filteredTicketInfo = ticketInfo.filter((ticket) => {
      const assigneeFilter = !selectedAssignee || ticket.assignee_name.toLowerCase() === selectedAssignee.toLowerCase();
      const statusFilter = !selectedStatus || ticket.status.toLowerCase() === selectedStatus.toLowerCase();
      const elevatorFilter = !selectedElevator || ticket.elevator_name.toLowerCase() === selectedElevator.toLowerCase();
      return (assigneeFilter && statusFilter && elevatorFilter);
    });
  
    this.setState({ filteredTicketInfo }, ()=> {
      console.log("-------handleApplyFilters------", this.state.filteredTicketInfo);
    });

    
  };

  // handleApplyFilters = () => {
  //   const { ticketInfo, selectedAssignee, selectedStatus } = this.state;
  
  //   if (!selectedAssignee && !selectedStatus) {
  //     // No filters applied
  //     this.setState({ filteredTicketInfo: null });
  //     return;
  //   }
  
  //   const filteredTicketInfo = ticketInfo.filter((ticket) => {
  //     const assigneeFilter = !selectedAssignee || ticket.assignee_name.toLowerCase() === selectedAssignee.toLowerCase();
  //     const statusFilter = !selectedStatus || ticket.status.toLowerCase() === selectedStatus.toLowerCase();
  //     return assigneeFilter && statusFilter;
  //   });
  
  //   this.setState({ filteredTicketInfo });
  // };
    

render(){
    const { ticketInfo, currentPage, rowsPerPage, searchTerm, fromDate, toDate, employee_list, statusArray, selectedAssignee, selectedStatus, selectedElevator, filteredTicketInfo } = this.state;

    const dataToDisplay = filteredTicketInfo || ticketInfo;

        const filteredTicketInfo_1 = dataToDisplay.filter((ticket) =>
        ticket.ticket_key.toLowerCase().includes(searchTerm.toLowerCase())||
        ticket.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ticket.priority.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ticket.label.toLowerCase().includes(searchTerm.toLowerCase())||
        // ticket.elevator_name.toLowerCase().includes(searchTerm.toLowerCase())||
        ticket.assignee_name.toLowerCase().includes(searchTerm.toLowerCase())
        );

        // Filter based on date range
        const filteredByDate = filteredTicketInfo_1.filter((ticket) => {
          const ticketDate = new Date(ticket.update_date);
          ticketDate.setHours(0, 0, 0, 0); // Set time to midnight to compare only the date
        
          if (fromDate && toDate) {
            const fromDateMidnight = new Date(fromDate);
            const toDateMidnight = new Date(toDate);
        
            fromDateMidnight.setHours(0, 0, 0, 0);
            toDateMidnight.setHours(0, 0, 0, 0);
        
            return ticketDate >= fromDateMidnight && ticketDate <= toDateMidnight;
          } else {
            return true;
          }
        });
            

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredByDate.slice(indexOfFirstRow, indexOfLastRow);

    // Create a Set to store unique assignee names
  const uniqueAssignees = new Set(ticketInfo.map(ticket => ticket.assignee_name));

      // Option elements for assignee dropdown employee_list
      // Convert the Set back to an array and map to options
  const assigneeOptions = [...uniqueAssignees].map((assignee, index) => (
    <option key={index} value={assignee}>
      {assignee}
    </option>
  ));

  const uniqueElevator = new Set(ticketInfo.map(ticket => ticket.elevator_name));
  const elevatorOptions = [...uniqueElevator].map((elevator, index) => (
    <option key={index} value={elevator}>
      {elevator}
    </option>
  ));

      // Option elements for status dropdown
      const statusOptions = statusArray.map((status) => (
        <option key={status.id} value={status}>
          {status}
        </option>
      ));

  //       // Render ticket information based on filter
  // const ticketRows = filteredTicketInfo.map((ticket, index) => (
  //   <tr key={index}>
  //     {/* Render ticket information */}
  //   </tr>
  // ));

  return (
    <div className='vh-100 scroll_hidden'>
      <div>
              <ActivityTabs/>
                {/* <div style={{ display: 'flex', backgroundColor:"#664fa7", color:"white", paddingTop:'2px' }}> */}
                <div className="sem-header-container">
                    <span><h3 style={{height:"30px", paddingLeft:"15px", marginTop:"1px", paddingTop:"5px"}}> Smart Elevator Maintenance &emsp; &gt;&gt; All Issues
                    </h3></span>
                    <span style={{ marginLeft: 'auto', marginRight:'5px',marginTop:"9px" }}>
                        <input
                            type="text"
                            id='search-input'
                            size="small"
                            value={searchTerm}
                            onChange={this.handleSearchChange}
                            placeholder=" search "
                        />
                    </span>
                </div>
          <div>
          
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop:'0.8cm'}}>
                <h3 style={{paddingLeft:'5px'}}> Tickets </h3> 
                <span>
                  <Link to="/Issue">
                    <Button id='contactButton' startIcon={<AddIcon />}> Add New Ticket</Button>
                  </Link>
                </span>
                
              </div>
              <div style={{ backgroundColor:'#f4f6fc'}}>
              <div style={{marginLeft:'10px'}}>
                <hr style={{backgroundColor: '#e1e2ce', height:'1px', border:'0.1px'}}></hr>
              
                  <label style={{color:'#0000ff'}}>Filters </label><br/><br/>
                  <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                    <span>
                      
                        <label style={{fontSize:'14px', color:'#664fa7'}}><b>From Date: </b>&nbsp;</label>
                          <input
                            type="date"
                            value={fromDate ? fromDate.toISOString().split('T')[0] : ''}
                            onChange={(e) => this.handleFromDateChange(new Date(e.target.value))}
                          />&emsp;
                                        
                          <label style={{fontSize:'14px', color:'#664fa7'}}><b>To Date: </b>&nbsp;</label>
                          <input
                            type="date"
                            value={toDate ? toDate.toISOString().split('T')[0] : ''}
                            onChange={(e) => this.handleToDateChange(new Date(e.target.value))}
                          />
                    </span>
                    {/* <span className="line"></span> */}

                   

                    {/* <span className="line"></span> */}

                    <span >
                      <label style={{fontSize:'14px', color:'#664fa7'}}><b>Search with keywords: </b>&nbsp;</label>
                                <input
                                    type="text"
                                    id='search-keyword'
                                    size="small"
                                    value={searchTerm}
                                    onChange={this.handleSearchChange}
                                    placeholder="Type here"
                                />
                      
                    </span>

                    
                  </div><br/>
                  <div>
                    <span>
                      <label htmlFor="assignee-dropdown" style={{fontSize:'14px', color:'#664fa7'}}><b>Assignee:</b>&ensp;</label>
                      <select id="assignee-dropdown" onChange={this.handleAssigneeChange} value={selectedAssignee}>
                        <option value="">All</option>
                        {assigneeOptions}
                      </select>&emsp;

                      <label htmlFor="elevator-dropdown" style={{fontSize:'14px', color:'#664fa7'}}><b>Elevator:</b>&ensp;</label>
                      <select id="elevator-dropdown" onChange={this.handleElevatorChange} value={selectedElevator}>
                        <option value="">All</option>
                        {elevatorOptions}
                      </select>&emsp;

                      <label htmlFor="status-dropdown" style={{fontSize:'14px', color:'#664fa7'}}><b>Status:</b>&ensp;</label>
                      <select id="status-dropdown" onChange={this.handleStatusChange} value={selectedStatus}>
                        <option value="">All</option>
                        {statusOptions}
                      </select>&emsp;
                      <button onClick={this.handleApplyFilters} style={{backgroundColor:'#75c69d', color:'#fff'}}><b>Apply Filter</b></button>
                    </span>
                  </div>

              </div><br/>
              

              
                <br/>
          </div><br/>
          {/* <hr style={{backgroundColor: '#e1e2ce', height:'0.5px', border:'0.1px'}}></hr> */}
                <div>
                  
                  <table>
                      <thead>
                        <tr>
                          <th>Ticket key</th>
                            <th>Status</th>
                            <th>Priority</th>
                            <th>Elevator Name</th>
                            <th>Label</th>
                            <th>Assignee</th>
                            <th>Updated</th>
                            <th>Action</th>
                        </tr>
                      </thead>   
                      <tbody>
                        {currentRows.map((ticket) => (
                              <tr key={ticket.ticket_key}>

                                <td>
                                  <span style={{
                                          display: 'inline-block',
                                          backgroundColor:"#664fa7",
                                          color:"white",
                                          paddingTop: '1.1px',
                                          paddingLeft:'2.5px',
                                          paddingRight:'2.5px',
                                          borderRadius: '4px',
                                          }}>
                                    {ticket.ticket_key}
                                  </span>
                                </td>

                                <td style={{ color:ticket.status.toLowerCase() === 'open' ? 'rgb(224, 15, 15)' : 'inherit' }}>{ticket.status}</td>
                                

                                <td>
                                    {ticket.priority === 'High' ? (
                                      <span style={{color:'rgb(224, 15, 15)', fontSize:'1.2em'}}> &uarr;&uarr; </span>
                                    ) : ticket.priority === 'Medium' ? (
                                      <span ><img width="12" height="12" src="https://img.icons8.com/metro/26/228BE6/equal-sign.png" alt="equal-sign"/> </span>
                                    ) : ticket.priority === 'Critical' ? (
                                      <span ><img width="12" height="17" src="https://img.icons8.com/fluency-systems-filled/48/FA5252/chevron.png" alt="chevron"/> </span>
                                    ):null}
                                    {ticket.priority}
                                </td>
                                <td>{ticket.elevator_name}</td>
                                <td>{ticket.label}</td>

                                <td>{ticket.assignee_name}</td>

                                <td>
                                {new Date(ticket.update_date).toLocaleString('en-US', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  hour12: true,
                                })} </td>
                                

                                <td>
                                  <button style={{ fontWeight:'bold'}} onClick={() => this.handleEdit(ticket.ticket_id)} > Edit </button> &nbsp;
                                  <button style={{ color:'rgb(224, 15, 15)', fontWeight:'bold'}} onClick={() => this.handleDelete(ticket.ticket_id)}>
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                      </tbody>
                  </table>
                      
                </div>

                <div style={{marginTop:'2.2cm', display: 'flex', justifyContent: 'center', marginBottom:'2.2cm', }}>
                  <button id ='rowsPerPageButton' onClick={() => this.handleChangePage(currentPage - 1)} disabled={currentPage === 1}>
                    {"< Prev"}
                  </button>&emsp;
                  <span style={{fontSize:'small'}}>
                    {`Page ${currentPage} / ${Math.ceil(ticketInfo.length / rowsPerPage)}`}
                  </span>&emsp;
                  <button id ='rowsPerPageButton' onClick={() => this.handleChangePage(currentPage + 1)} disabled={currentPage === Math.ceil(ticketInfo.length / rowsPerPage)}>
                    {"Next >"}
                  </button>

                </div>
          </div> 
      </div> 
        <div id="copyright-footer">
            <p style={{fontSize:'12px'}}>
            <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
            </p>
          </div>
    </div>
  );
}
}
export default all_tech_issues;