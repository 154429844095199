import React, { Component } from 'react';
// import { render } from 'react-dom';
import '../adminDash.css';
// import Button from '@material-ui/core/Button';
// import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { get } from '../../global_files/serverRequests';
import globalVariables from "../../global_files/globalVariables";
import urls from '../../global_files/urls';
import { getFreshToken } from '../../global_files/auth';
import ActivityTabs from "../Admin/ActivityTabs";


class AmcReportList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      Amc_Report_List:[],
      currentPage: 1,
      rowsPerPage: 25,
      searchInput:''

      
    };
  }

  componentDidMount(){
    this.handleReportList();
    this.validateSignedIn();
  }

//   async componentDidMount() {
//     const isSignedIn = await this.validateSignedIn();
//     if (isSignedIn) {
//       this.handleReportList();
//     }
//   }

validateSignedIn = async () => {
  let is_fresh_token = await getFreshToken()
  if (!is_fresh_token) {
    console.log("*************************is_fresh_token*******************************", is_fresh_token)
    alert("Your session has expired, please login again!!");
      window.location.replace('/login')
     return
  }
}

 handleReportList= async ()=>{
          let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
          let elevator_id = localStorage.getItem(globalVariables.SELECTED_ELEVATOR_id)
                    
          console.log("----------access_tocken in handleReportList----------",access_tocken);
          console.log("----------elevator_id in handleReportList----------",elevator_id);

          let headers = {
            
            'content-Type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${access_tocken}`,
        }
        
        let data = await get(`${urls.BASE_URL}/api/cust-reports/${elevator_id}/reports/`, headers) //cust-reports/elevator_id/reports/

             console.log("----------data in handle list----------",data);
            //    data = data.results
              
              if (data && data.length > 0) {
                this.setState({
                    Amc_Report_List: data,
                });
                console.log("-------------Amc_Report_List info array : ",this.state.Amc_Report_List);
                
                }
  }

  handleViewReport = async(report_id) =>{
    console.log("view report for report id -------->", report_id);
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)

    let headers = {
        'Authorization': `Bearer ${access_tocken}`,
    }

    try{
        const response = await fetch(`https://semapp.in/api/cust-reports/${report_id}/read/`, {
        headers: headers,
        });

         console.log("----------data in handle list----------",response);
        //    data = data.results
        if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
    
          // Open the PDF in a new tab
          window.open(url, '_blank', 'noopener,noreferrer');
        } catch (error) {
          console.error('Error fetching the PDF:', error);
        }


  }

   handleChangePage = (newPage) => {
      this.setState({
        currentPage: newPage,
      });
    };

    handleSearchInputChange = (e) => {
        this.setState({ searchInput: e.target.value });
    };

   

render(){
    const { Amc_Report_List, currentPage, rowsPerPage, searchInput } = this.state;

    let elevatorName = localStorage.getItem(globalVariables.SELECTED_ELEVATOR);
   
    const filteredReportInfo = Amc_Report_List.filter((list) =>
    list.report_id.toLowerCase().includes(searchInput.toLowerCase())||
    list.report_date.toLowerCase().includes(searchInput.toLowerCase()) 
    );

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredReportInfo.slice(indexOfFirstRow, indexOfLastRow);

    
  return (
    
    <div className='vh-100 scroll_hidden'>

          <ActivityTabs/>
            {/* <div style={{ display: 'flex', backgroundColor:"#664fa7", color:"white", paddingTop:'2px' }}> */}
            <div className="sem-header-container">
                <span><h3 style={{height:"30px", paddingLeft:"15px", marginTop:"1px", paddingTop:"5px"}}> Smart Elevator Maintenance &emsp; &gt;&gt; <Link id="navigation" to="/elevators"> Elevators </Link> &gt;&gt; {elevatorName} &gt;&gt; &nbsp; AMC Reports </h3>
                </span>
                <span style={{ marginLeft: 'auto', marginRight:'10px', marginTop:'9px' }}>
                      <input
                          type="text"
                          id='search-input'
                          size="small"
                          onChange={this.handleSearchInputChange}
                          placeholder=" search "
                    />
                </span>
            </div>
          
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop:'0.3cm', paddingLeft:'8px'}}>
              <h3 style={{paddingLeft:'8px'}}> Annual Maintenance Reports   </h3>
              
          </div>
          <br/>
                    
            <div class="report_container">
                {currentRows.map((list) => (
                    <div className="card"  key={list.report_id}>
                        <h4>Report ID: {list.report_id}</h4>
                        <p>Date: {list.report_date}</p>
                        <a href="#" role="button" onClick={(e) => { e.preventDefault(); this.handleViewReport(list.report_id)}}>View Report</a>
                    </div>
                ))}
            </div>

          <div style={{marginTop:'2.2cm', display: 'flex', justifyContent: 'center', marginBottom: '2.2cm' }}>
            <button id ='rowsPerPageButton' onClick={() => this.handleChangePage(currentPage - 1)} disabled={currentPage === 1}>
              {"< Prev"}
            </button>
            <span style={{fontSize:'small'}}>
              {`Page ${currentPage} / ${Math.ceil(Amc_Report_List.length / rowsPerPage)}`}
            </span>
            <button id ='rowsPerPageButton' onClick={() => this.handleChangePage(currentPage + 1)} disabled={currentPage === Math.ceil(Amc_Report_List.length / rowsPerPage)}>
              {"Next >"}
            </button>

          </div>
          <div id="copyright-footer">
            <p style={{fontSize:'12px'}}>
            <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
            </p>
          </div>
    </div> 
   );
}
}

export default AmcReportList;