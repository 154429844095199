import React, { Component } from 'react';
import '../adminDash.css';
// import { Link } from 'react-router-dom';
import Homepage from '../Admin/Homepage';
import DashboardHomePagePic from '../../assets/Dashboard_Homepage_Image.png';
import Footer from './Footer';
// import urls from '../../global_files/urls';
import GoogleMapIcon from '../../assets/google_map_icon.png';
import AmcNotify from '../../assets/notification_filled_icon.png';
import Upload from '../../assets/upload_icon.png';
import PdfIcon from '../../assets/pdf-icon1.png';

class About extends Component {

  componentDidMount() {
    const coll = document.getElementsByClassName('collapsible');

    for (let i = 0; i < coll.length; i++) {
      coll[i].addEventListener('click', this.toggleContent);
    }
  }

  componentWillUnmount() {
    const coll = document.getElementsByClassName('collapsible');

    for (let i = 0; i < coll.length; i++) {
      coll[i].removeEventListener('click', this.toggleContent);
    }
  }

  toggleContent = (event) => {
    event.currentTarget.classList.toggle('active');
    const content = event.currentTarget.nextElementSibling;
    if (content.style.display === 'block') {
      content.style.display = 'none';
    } else {
      content.style.display = 'block';
    }
  };


  render() {
    return (
      <div >
          <Homepage/>
          <div className='about' style={{ paddingLeft:'8.6%',paddingRight:'8.6%' }}>
          <div class="container">
            <h2 class="title">
              <span class="title-word title-word-1">"Elevate </span>           
              <span class="title-word title-word-2">Efficiency: </span>
              <span class="title-word title-word-3">Track, </span>
              <span class="title-word title-word-4">Resolve, </span>
              <span class="title-word title-word-2">and </span>
              <span class="title-word title-word-3">Report </span>
              <span class="title-word title-word-4"> &nbsp;with </span>
              <span class="title-word title-word-5">Smart </span>
              <span class="title-word title-word-6">Elevator </span>
              <span class="title-word title-word-5">Maintenance </span>
              <span class="title-word title-word-3">(SEM) </span>
              <span class="title-word title-word-1">App"</span>
            </h2>
          </div>
          
          <p style={{fontFamily: 'serif', lineHeight: '1.3', fontSize:'1.01em'}}>

              <b><em>The SEM (Smart Elevator Maintenance) </em></b>application integrates with an Android application, providing a platform for managing elevator maintenance and service contracts.<br/>
              The applications primarily serves Dealers(admins), Society managers, Technicians, Clients, offering a login dashboard with access to key functionalities.<br/>

              <h2 style={{color:'#664fa7'}}>EXPLORE &nbsp;THE &nbsp;PLATFORM &nbsp; &gt;&gt;</h2>

              <b>1. Login Dashboard:</b> Upon logging in, dealer is directed to the homepage. <br/>
              <b>2. Homepage:</b> The homepage features a navigation bar with four tabs: <span style={{color:'#8855b2'}}><b><em>Home, All Issues, Technicians, Customers, Elevators, Settings </em></b></span><br/> <br/>
                <div style={{display:'grid',  gridTemplateColumns: '1fr 1fr', columnGap: '8px'}}>
                  <span id='Dashboard_Homepage_Pic'>
                    <img src={DashboardHomePagePic} alt='Dashboard_Homepage'/> 
                  </span>
                  <div>
                      
                      <button type="button" class="collapsible">Home <span>&#10147;</span></button>
                        <div class="content_collapsible">
                          <ul><li><span id='homePage_list'><b>'Home' Tab:</b></span> Displays a table representation of :<ol><li> maintenance contracts count sorted based on categories such as Maintenance Completed, Maintenance Renewed, and Maintenance in Due with the respective counts. Clicking on any count within these categories reveals a detailed list of contracts, including elevator name, address, contract completion date, next renewal date, technician's name, etc. </li>
                                                                            <li> Issues count sorted based on categories such as Open, Inprogress, Onhold, Accepted, Closed. Clicking on any count within these categories reveals a detailed list of issues.</li>
                                                                            <li> Pie chart showing the total ticket count and percentage of tickets with respect to their status as open/new, accepted by technician, Inprogress, On-hold, closed to track the progress</li></ol></li>
                          </ul>
                        </div>

                      <button type="button" class="collapsible">All Issues <span>&#10147;</span></button>
                        <div class="content_collapsible">
                          <p>
                          Lists all issues or tickets raised by all the dealers, technicians, or customers, both from the Android app and the website.<br/>
                          Dealer can search for specific tickets using keywords of labels, summary or status of ticket or filter by creation date (from a-b-c date to x-y-z date).<br/>
                          Dealer have the ability to edit or delete ticket information or create new ticket.
                          </p>
                        </div>

                      <button type="button" class="collapsible">Technicians <span>&#10147;</span></button>
                        <div class="content_collapsible">
                          <p>
                          Provides functionalities related to managing technicians like:<br/>
                          New technician registration,<br/>
                          Displaying a list of registered technicians assigned under the logged-in dealer or admin.<br/>
                          Each technician's username and the count of tickets assigned to them are shown in card view format.<br/>
                          Clicking on a count redirects to the page where, the information about those assigned tickets to that technician is listed with options for: <br/>
                          editing or deleting tickets by the dealer or admin user.
                          </p>
                        </div>

                      <button type="button" class="collapsible">Customers <span>&#10147;</span></button>
                        <div class="content_collapsible">
                          <p>
                          Provides a button to register/add new customer<br/>
                          Displays the list of customers registered under the dealer/admin along with the issue count raised by the specific customer.<br/>
                          Clicking on the issue count of a specific customer provides detailed ticket information in a table format.
                          </p>
                        </div>

                      <button type="button" class="collapsible">Elevator <span>&#10147;</span></button>
                        <div class="content_collapsible">
                          <p>
                          Provides a button to register/add new elevators.<br/>
                          Also displays the list of Elevators (registered under the dealer/admin who have logged in)<br/>
                          along with the issue count raised against the specific elevator.<br/>
                          Clicking on the issue count of a specific elevator provides detailed ticket information in a table format,<br/>
                          sorted by ticket states (open, on-hold, completed, closed), with options for editing or deleting tickets for the dealer/admin.
                          </p>
                        </div>

                      <button type="button" class="collapsible">Settings <span>&#10147;</span></button>
                        <div class="content_collapsible">
                          <p>
                            Provides the switch to enable or disable 'OTP varification facility'. <br/>
                            to notify customers that the ticket status is updating to 'closed' indicating that the task/issue is resolved.
                          </p>
                        </div>
                  </div>
                </div>
          </p> 
          {/* b9929f */}
          <div className="why-sem">
            <h2>WHY &nbsp;SEM &nbsp;APPLICATION ?</h2>
          </div><br/>
              <div style={{display:'flex', justifyContent:'space-between'}}>

                  <div className="HomePagecard">
                    <div className='left-side-issue'>
                    </div>
                    <div className='right-side'>
                      <p style={{ fontSize:'16px', letterSpacing: '3px', color: '#e30000'}}>NEED ASSISTANCE ?</p>
                      <h2 style={{fontFamily: 'Fraunces'}}>Raise Issues</h2>
                      <p style={{ fontSize:'15px'}}>Create ticket for your issue <br/><br/>
                        View list  of all tickets.<br/><br/>
                        Search ticket from the list or filter by date .<br/><br/>
                        Edit ticket information or Delete ticket.</p>
                    </div>
                  </div>

                  <div className="HomePagecard">
                    <div className='left-side-tech'>
                    </div>
                    <div className='right-side'>
                      <p style={{ fontSize:'16px', letterSpacing: '3px', color: '#e30000'}}> NEED TO TRACK PRODUCTIVITY? </p>
                      <h2 style={{fontFamily: 'Fraunces'}}>Manage Technicians</h2>
                      <p style={{ fontSize:'15px'}}>Register the technicians.<br/><br/>
                        View list of registered technicians along with the assigned tickets' count<br/><br/>
                        Click on ticket count to see detailed ticket info
                      </p>
                    </div>
                  </div>
                    
              </div><br/>

              <div style={{display:'flex', justifyContent:'space-between'}}>
                <div className="HomePagecard">
                  <div className='left-side-notify'>
                  </div>
                  
                  <div className='right-side'>
                    <p style={{ fontSize:'16px', letterSpacing: '3px', color: '#e30000'}}>WHY MISS OUT THE UPDATES?</p>
                    <h2 style={{fontFamily: 'Fraunces'}}>Get Notifications</h2>
                    <p style={{ fontSize:'15px'}}>facility to notify the SEM app users regarding the ticket updates.<br/><br/>
                    OTP verification functionality.
                    
                    </p>
                  </div>
                </div>
                    <div className="HomePagecard">
                  <div className='left-side-elevator'>
                  </div>
                  
                  <div className='right-side'>
                    <p style={{ fontSize:'16px', letterSpacing: '3px', color: '#e30000'}}>INSTALLING NEW ELEVATORS?</p>
                    <h2 style={{fontFamily: 'Fraunces'}}>Manage Elevators with client requirements</h2>
                    <p style={{ fontSize:'15px'}}>Register your Elevators<br/><br/>
                    View registered elevators' list, along with count of issues if any are raised                  
                    </p>
                  </div>
                    </div>
                </div><br/>

              <div>

          </div>

          <div className='banner_cards'>
            <h3>DISCOVER &nbsp;POWERFUL &nbsp;FEATURES &nbsp;WITH &nbsp;OUR &nbsp;APP</h3>
                <ul>
                    <li style={{backgroundColor:'#0B374D'}}>
                        <div class="icon"><img src={GoogleMapIcon} alt="mapIcon"/></div>
                        <div class="title">Google Map</div>
                        <div class="descr">With real-time navigation and precise directions, our team can reach your site without any hassle, providing you with the best service experience.</div>
                    </li>
                    <li style={{backgroundColor:'#1286A8'}}>
                        <div class="icon"><img src={AmcNotify} alt="amcIcon"/></div>
                        <div class="title">AMC notifications</div>
                        <div class="descr">Receive notifications well in advance of your Annual Maintenance Contract (AMC) renewal date , giving you ample time to review and renew your contract without any last-minute rush.
                </div>
                    </li>
                    <li style={{backgroundColor:'#D2B53B'}}>
                        <div class="icon"><img src={Upload} alt="uploadIcon"/></div>
                        <div class="title">UPLOAD DATA</div>
                        <div class="descr">allows dealers (admins) to effortlessly upload CSV or Excel files containing their existing customers and elevator data with just one click, eliminating the need for manual registration. </div>
                    </li>
                     {/* <li style={{backgroundColor:'#DA611E'}}>
                        <div class="icon"><i class="fa-brands fa-js"></i></div>
                        <div class="title">Javascript</div>
                        <div class="descr">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor laboriosam odio alias.</div>
                    </li> */}
                    <li style={{backgroundColor:'#AC2A1A'}}>
                        <div class="icon"><img src={PdfIcon} alt="pdfIcon" width="40px"/></div>
                        <div class="title">AMC Report </div>
                        <div class="descr">Our app allows users to generate annual maintenance contracts and save them as PDF reports.
                        Users can easily view, share the saved pdf reports through E-mail, WhatsApp, etc </div>
                    </li>
                </ul>
          </div>
          
          {/* <div>
            <p>
            "This integrated platform streamlines Elevator maintenance contract management, technician assignment, and issue tracking for elevator servicing, elevator registration, customer registration, enhancing efficiency of employee and customer satisfaction."
            </p>
          </div> */}

      </div>
      
          <Footer/>
    </div>
    );
  }
}

export default About;
