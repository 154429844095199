
export default class globalVariables {

    static IP_ADDRESS = "ip_address"

    static AUTH_TOKEN = "auth_token"
    static REFRESH_TOKEN = "refresh_token"
    static TOKEN_EXPIRY = "token_expiry"

    static USER_TYPE = "user_type"
    static USER_NAME = "username"
    static USER_EMAIL = "user_email"
    static USER_EMAIL = "user_email"
    static DEALER_EMAIL = "admin_email"

    static USER_ID = "user_id"
    static SELECTED_TECH_ID = "tech_user_id"
    static SELECTED_CUSTOMER_NAME = "selected_cust_name"
    static SELECTED_ELEVATOR = "selected_elevator"
    static SELECTED_ELEVATOR_id = "elevator_id"
    static SELECTED_TECH_NAME = "selected_tech_name"
    static SELECTED_TICKET = "ticket_id"
    static USER_TYPE_ADMIN = 'admin'
    // static USER_TYPE_OPERATOR = 'operator'
    static SELECTED_TRACKER = 'tracker'

    static USER_STATE_ACTIVE = 1
    static USER_STATE_INACTIVE = 0

    static USER_ACCESS_ALLOW = 1
    static USER_ACCESS_DENY = 0

    static BATCHES_DATA = "batches_data"
    static WIFI_DATA = "wifi_data"

}