import axios from "axios";
import React, { Component } from 'react';
import { post, get } from '../../global_files/serverRequests';
import globalVariables from "../../global_files/globalVariables";
import { Link } from 'react-router-dom';
import '../adminDash.css';
import TabpanelForAllTechIssues from './tabpanel_allTech_Issues';
import { getFreshToken } from '../../global_files/auth';
import urls from '../../global_files/urls';
import ActivityTabs from "./ActivityTabs";


class Issue extends Component {

  constructor(props) {
    super(props);
    const today = new Date().toISOString().split('T')[0];
    const dealer_user_id= String(localStorage.getItem(globalVariables.USER_ID))
    const user_name = localStorage.getItem(globalVariables.USER_NAME) || '';
    // Initialize optionsState with all checkboxes set to false
    // Initialize optionsState with all checkboxes set to false if options are provided
    const optionsState = {};
    if (props.data && props.data.options) {
      props.data.options.forEach((_, idx) => {
        optionsState[idx] = false;
      });
    }

    this.state = {
      optionsState,
      elevator_id: 0,
      ticket_key: '',
      // ticket_id:'',
      create_date:today,
      label : '',
      summary : '',
      description : '',
      reporter_name : dealer_user_id,
      assignee_name : '',
      elevator_name:'',
      status : 'Open',
      resolution : 'Unresolved',
      priority : 'Unspecified',
      start_date : today,
      target_date : '',
      close_date :'',
      update_date:'',
          matches: [],
          content: '',
      employee_list:[],
      elevator_list:[],
      subscriptionIds:[],

      // customFieldName:'',
      // customFieldType:'',
      // customFieldDescription:'',
      
      is_required:'',
      customFieldArray:[],
      val: [],
      // optionsState:{}
      user_name: user_name,
      
    };
  }

  componentDidMount(){
    this.getemployeeList();
    this.getElevatorList();
    this.validateSignedIn();
    // this.getDealerInfo();
    this.getPIDListToSendNotification();
    this.getCustomFields()
  }

  validateSignedIn = async () => {
    let is_fresh_token = await getFreshToken()
    if (!is_fresh_token) {
        //this.props.history.replace("/sign_in");
        alert("Your session has expired, please login again!!");
        window.location.replace('/login')
        return
    }
} 
  getElevatorList= async ()=>{
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)
        
    console.log("----------access_tocken in employee list----------",access_tocken);
     let headers = {
      'content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${access_tocken}`,
  }
  // let response = await get(`${urls.BASE_URL}/api/users/${tech_Id}/elevators/`, headers) 
   let response = await get(`${urls.BASE_URL}/api/users/${dealer_uuid}/elevators/`, headers)
  //  let data = await get(`${urls.BASE_URL}/api/users/${dealer_uuid}/elevators/`, headers)
      console.log("----------data in getElevator list----------",response);
        // data = data.elevators
        let data = response.elevators
        if (data && data.length > 0) {
           this.setState({
             elevator_list: data,
            //  elevatorName : data.elevator_name
          });
          
          console.log("/******** elevator id array : ",this.state.elevator_list);
          // console.log("/******** elevator name  : ",this.state.elevator_list);
                              
        }
      }

  getemployeeList= async ()=>{
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)
    console.log("----------access_tocken in employee list----------",access_tocken)
    let headers = {
      // 'content-type': 'application/json'
      'content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${access_tocken}`,
  }
  let data = await get(`${urls.BASE_URL}/api/account/${dealer_uuid}/employee_list/`, headers)
    // console.log("----------------------------------",data);
    // if (data !== false) {
       data = data.results
        console.log(" issue ----------data in employee list----------",data);
        if (data && data.length > 0) {
          this.setState({
            employee_list: data,
          });
          console.log("ticket info array : ",this.state.employee_list);
        }

}

// CustomFieldJsonData=()=>{
// const dataToSubmit = this.state.customFieldArray.map(field => {
//   let value;

//   if (field.type === 'checkbox') {
//     // Assuming field.value is an array of objects like [{label: 'Good condition', value: true}, {label: 'Needs repair', value: false}]
//     value = field.value.map(checkbox => `${checkbox.label}: ${checkbox.value}`);
//   } else {
//     value = String(field.value); // Ensure no undefined values are sent
//   }

//   return {
//     custom_field: field.id,
//     customised_type: "ticket",
//     customised_field_id: this.state.getTicketId,
//     value: value,
//     // is_required: false // Uncomment if this field is required
//   };
// });
// console.log("-------dataToSubmit-------",dataToSubmit);
// this.jsonData(dataToSubmit);
// }


jsonData = () => {
  // const dataToSubmit = this.state.customFieldArray.map(field => ({
  //   custom_field: field.id,
  //   customised_type: "ticket",
  //   customised_field_id: this.state.getTicketId,
  //   value: String(field.value), // Ensure no undefined values are sent
  //   // is_required: false // Uncomment if this field is required
  // }));

  // {
  //   custom_field: 2,
  //   customised_type: "ticket",
  //   customised_field_id: this.state.getTicketId,
  //   value: ["Good condition: true", "Needs repair: false"]
  // }
  const dataToSubmit = this.state.customFieldArray.map(field => {
    let value;

    if (field.format === 'checkbox') {
      // Filter only true values and join their labels
      const trueLabels = (field.value || [])
        .filter(checkbox => checkbox.value)
        .map(checkbox => checkbox.label);
      value = trueLabels.join(', ');
    } else {
      value = field.value !== undefined ? String(field.value) : ''; // Ensure no undefined values are sent
    }

    return {
      custom_field: field.id,
      customised_type: "ticket",
      customised_field_id: this.state.getTicketId,
      value: value,
      // is_required: false // Uncomment if this field is required
    };
  });
  
  
  let data = {
      
      "elevator_id":this.state.elevator_id,
      // "ticket_key":this.state.ticket_key,
      // "ticket_id":this.state.ticket_id,
      "summary":this.state.summary, 
      "description":this.state.description,
      "label":this.state.label, 
      "reporter":this.state.reporter_name,
      // "assignee":this.state.assignee_name,
      "status":this.state.status,
      "resolution":this.state.resolution,
      "priority":this.state.priority,
      // "create_date":this.state.create_date,
      "start_date":this.state.start_date,
      "target_date":this.state.target_date,
      // "close_date":this.state.close_date,
      // "custom_field_values" : dataToSubmit

            
  }
  if(this.state.create_date){
    data.create_date = this.state.create_date;
  }
  if(this.state.close_date){
    data.close_date = this.state.close_date;
  }
  if(this.state.assignee_name){
    data.assignee_name = this.state.assignee_name;
  }
  if(dataToSubmit){
    data.custom_field_values = dataToSubmit;
  }
  console.log("-----JSON DATA -------->",data)
  return JSON.stringify(data)
}
  
  insertCreateTicketInfo = async () => {
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    let label_input = this.state.label;
          console.log("----------access_tocken in issue list----------",access_tocken)
          let headers = {
            'content-Type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${access_tocken}`,
          }
    
    let post_data = this.jsonData()

    console.log("post_data in insertCreateTicketInfo---------->",post_data)

    // Parse the JSON string into an object
  let postDataObj = JSON.parse(post_data);

  for (const key in postDataObj) {
    if (key !== 'close_date' && key !== 'assignee_name' && (postDataObj[key] === null ||  postDataObj[key] === "")) {
      alert("Please fill in all the required fields.");
      return;
    }
  }
  if (label_input.length>32){
      alert("Label should not be more than 32 characters!");
      return
  }
    try {
      let response = await post(`${urls.BASE_URL}/api/tickets/create/`, post_data, headers)
      if (response) {
        console.log("============= successful insertCreateTicketInfo =======", response);
             
        alert('Ticket created successfully !!!')
        await this.SendNotification()
        window.location.replace("/all_tech_issues");
      } else {
        console.log("============= if insertCreateTicketInfo =======", response);
        alert("Failed to create ticket. Please try again.");
        }
    } catch (error) {
      console.error("Error in insertCreateTicketInfo:", error);
      alert("An error occurred while creating the ticket. Please try again later.");
    }
  }


  handleDateChange = (event, dateKey) => {
    const selectedDate = event.target.value;
    const formattedDate = this.formatDate(selectedDate);

    this.setState({
      [dateKey]: formattedDate,
    });
  };

  formatDate = (inputDate) => {
    // Parse the input date assuming it's in "dd/mm/yyyy" format
    const [day, month, year] = inputDate.split('/');
    // Create a new Date object using the parsed values
    const parsedDate = new Date(`${year}-${month}-${day}`);
    // Format the date in "yyyy-MM-dd" format
    const formattedDate = parsedDate.toISOString().split('T')[0];
    return formattedDate;
  };

  /********************************************Send notification***************************************************** */

  getPIDListToSendNotification= async ()=>{
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    let headers = {
      'content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${access_tocken}`,
  }
  let data = await get(`${urls.BASE_URL}/api/users/get-pid-list-of-tech/`, headers) 
    console.log("----------------------------------",data);
    // if (data !== false) {
       data = data.app_pids
       this.setState({subscriptionIds:data})
        console.log("----------data in getPIDListToSendNotification list----------",data);
      
  }
  
SendNotification = async () => {
  const { assignee_name, employee_list, elevator_list, elevator_name, elevator_id } = this.state;

  const selectedEmployee = employee_list.find((item) => item.user_id === assignee_name);
  let Assignee = selectedEmployee ? selectedEmployee.username : null;

  const selectedElevator = elevator_list.find((elevator) => elevator.elevator_id === elevator_id);
  let elevatorName = selectedElevator ? selectedElevator.elevator_name : null;
  
  let subscriptionIds =this.state.subscriptionIds

  console.log("----Assignee in SendNotification", Assignee);
  console.log("----elevator_name in SendNotification", elevatorName);
  const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Basic ZjRhOGQ4ZGUtOWQxZS00M2I1LWE1MmYtYzFmNzBjYzdlNzA0'
  };

  const body = {
      app_id: '999400f4-2917-4a6e-bf38-28a1e25feb29',
      // included_segments: ['All'], // Send to all subscribers
      include_external_user_ids: subscriptionIds,
      headings:{en: `New Ticket is Created !`},
      contents: { en: `New ticket for elevator ${elevatorName} is Assigned to ${Assignee}` }, // Notification content
  };

  try {
      const response = await axios.post('https://onesignal.com/api/v1/notifications', body, { headers });
      console.log('Notification sent successfully:', response.data);
  } catch (error) {
      console.error('Error sending notification:', error.response.data);
  }
};

/****************************************Custom field section************************************************* */

getCustomFields= async ()=>{
  let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
  let headers = {
    'content-Type': 'application/json; charset=UTF-8',
    'Authorization': `Bearer ${access_tocken}`,
}
let data = await get(`${urls.BASE_URL}/api/custom_fields/get_custom_fields_by_type/ticket/`, headers) 
  console.log("----------------getCustomFields ------------------",data);
      this.setState({
        customFieldArray: data
      })

    
}


handleChange = (e, index) => {
    const { customFieldArray } = this.state;
    const updatedFields = [...customFieldArray];

    if (updatedFields[index].format === 'checkbox') {
      if (!Array.isArray(updatedFields[index].value)) {
        updatedFields[index].value = [];
      }
      const checkboxIndex = updatedFields[index].value.findIndex(cb => cb.label === e.target.name);
      if (checkboxIndex !== -1) {
        updatedFields[index].value[checkboxIndex].value = e.target.checked;
      } else {
        updatedFields[index].value.push({ label: e.target.name, value: e.target.checked });
      }
    } else if (updatedFields[index].format === 'boolean') {
      updatedFields[index].value = e.target.checked;
    } else {
      updatedFields[index].value = e.target.value;
    }

    this.setState({
      customFieldArray: updatedFields
    });
    console.log("------custom values in customFieldArray ---------", this.state.customFieldArray);
  };

  
  handleMultipleCheckboxChange = (e, idx) => {
    const { optionsState } = this.state;
    const newOptionsState = {
      ...optionsState,
      [idx]: e.target.checked
    };

  //   this.setState({
  //     optionsState: {
  //       ...optionsState,
  //       [idx]: e.target.checked,
  //     },
  //   } , () => {
  //     console.log("handleMultipleCheckboxChange---->", this.state.optionsState);
  // });

    this.setState({ optionsState: newOptionsState }, () => {
      console.log("handleMultipleCheckboxChange---->", this.state.optionsState);
    });

  };
  
  render(){
    const { employee_list, elevator_list, customFieldArray, optionsState } = this.state;
    let user_name = localStorage.getItem(globalVariables.USER_NAME);
    const dealer_user_id = String(localStorage.getItem(globalVariables.USER_ID)) || '';
  return (
    <div>
        <ActivityTabs/>
        {/* <div style={{ display: 'flex', backgroundColor:"#664fa7", color:"white", paddingTop:'2px' }}> */}
        <div className="sem-header-container">
                    <span><h3 style={{height:"30px", paddingLeft:"15px", marginTop:"1px", paddingTop:"5px"}}> Smart Elevator Maintenance &emsp; &gt;&gt; 
                    <Link id="navigation" to="/technicians">Technicians</Link> &gt;&gt; 
                    
                    <Link id="navigation" to="/all_tech_issues">All Issues</Link> &gt;&gt; 
                    
                    </h3></span>
                    <span style={{ marginLeft: 'auto', marginRight:'5px',marginTop:"9px" }}>
                        <input
                            type="text"
                            id='search-input'
                            size="small"
                            placeholder=" search "
                        />
                    </span>
        </div>
        <TabpanelForAllTechIssues/>
            <div className="bordered-div" >
            <form style={{ marginLeft:'7%'}}>
               <div className="form-group">
                  <label htmlFor="ele_Id" >Elevator Name <span style={{color:'red'}}>*</span>: &nbsp;</label>
                  <select
                    id="ele_Id"
                    name="ele_Id"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.elevator_id}
                    onChange={(evt) => this.setState({ elevator_id: evt.target.value })}
                  >
                    <option > ----- </option>
                    {elevator_list.map((elevator, index) => (
                      <option key={index} value={elevator.elevator_id}>
                        {elevator.elevator_name}
                      </option>
                    ))}
                    
                    
                    
                  </select>
               </div>
               <br/>
               
               <div className="form-group">
                  <label htmlFor="label" > Label <span style={{color:'red'}}>*</span> : &nbsp;</label>
                    <input
                      type="text"
                      id="label"
                      name="label"
                      required
                      style={{ width: '450px', height: '0.7cm' }}
                      placeholder=" fill upto 32 letters only (including spaces)"
                      value={this.state.label}
                      onChange={(evt) => this.setState({ label: evt.target.value })}
                    />
               </div>
               <br/>
               <div className="form-group">
                  <label htmlFor="summary" > Summary <span style={{color:'red'}}>*</span> : &nbsp;</label>
                  <input
                      type="text"
                      id="summary"
                      name="summary"
                      required
                      style={{ width: '450px', height: '0.7cm' }}
                      // value={this.state.summary}
                      // onChange={handleChange}
                      value={this.state.summary}
                      onChange={(evt) => this.setState({ summary: evt.target.value })}
                    />
               </div>
               <br/>
               
               <div className="form-group">
                  <label htmlFor="desc"> Description <span style={{color:'red'}}>*</span> : &nbsp;</label>
                  <textarea
                      // type="text"
                      id="desc"
                      name="desc"
                      required
                      style={{ width: '450px', height: '250px', resize: 'none' }}
                      value={this.state.description}
                      onChange={(evt) => this.setState({ description: evt.target.value })}
                    />
               </div>
               <br/>
               <div className="form-group">
                  <label htmlFor="reporter" > Reporter<span style={{color:'red'}}>*</span> : &nbsp;</label>
                  
                  <select
                    id="reporter"
                    name="reporter"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.reporter_name}
                    onChange={(evt) => this.setState({ reporter_name: evt.target.value })}
                  >
                    <option value={dealer_user_id}> {user_name} </option>
                    {employee_list.map((item, index) => (
                      <option key={index} value={item.user_id}>
                        {item.username}
                      </option>
                    ))}
                  </select>
               </div>
               <br/>
               <div className="form-group">
                  <label htmlFor="assignee" > Assignee <span style={{color:'red'}}>*</span> : &nbsp;</label>
                  <select
                    id="assignee"
                    name="assignee"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.assignee_name}
                    onChange={(evt) => this.setState({ assignee_name: evt.target.value })}
                  >
                    <option > ----- </option>
                    
                    {employee_list.map((item, index) => (
                      <option key={index} value={item.user_id}>
                        {item.username}
                      </option>
                    ))}
                    
                    
                  </select>
               </div>
               <br/>
               <div className="form-group">
                  <label htmlFor="status" > Status <span style={{color:'red'}}>*</span> : &nbsp;</label>
                  <select
                    id="status"
                    name="status"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.status}
                    onChange={(evt) => this.setState({ status: evt.target.value })}
                  >
                    {/* <option> -Select- </option> */}
                    <option value="Open"> Open </option>
                    <option value="InProgress"> In Progress </option>
                    <option value="OnHold"> On Hold </option>
                    <option value="Accepted"> Accepted </option>
                    <option value="Closed"> Closed </option>
                    
                    
                  </select>
               </div>
               <br/>
               <div className="form-group">
                  <label htmlFor="resolution" > Resolution : &nbsp;</label>
                  <select
                    id="resolution"
                    name="resolution"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.resolution}
                    onChange={(evt) => this.setState({ resolution: evt.target.value })}
                  >
                    {/* <option> -Select- </option> */}
                    <option value="Unresolved"> Unresolved </option>
                    <option value="CompletedToSpec"> Completed To Spec </option>
                    <option value="CompletedNotSpec"> Completed Not Spec </option>
                    <option value="CannotComplete"> Cannot Complete </option>
                    <option value="Denied"> Denied </option>
                    <option value="Duplicate"> Duplicate </option>
                    
                    
                  </select>
               </div>
               <br/>
               <div className="form-group">
                  <label htmlFor="priority" > Priority <span style={{color:'red'}}>*</span> : &nbsp;</label>
                  <select
                    id="priority"
                    name="priority"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.priority}
                    onChange={(evt) => this.setState({ priority: evt.target.value })}
                  >
                    {/* <option> -Select- </option> */}
                    <option value="Unspecified"> Unspecified </option>
                    <option value="Critical"> Critical </option>
                    <option value="High"> High </option>
                    <option value="Medium"> Medium </option>
                    <option value="Low"> Low </option>
                      
                  </select>
               </div>
               <br/>
               <div className="form-group">
               <label htmlFor="createdate" >Create Date : &nbsp;</label>
                  <input 
                  type="date" 
                  id="createdate" 
                  name="createdate" 
                  style={{ width: '150px', height: '0.7cm' }}
                  value={this.state.create_date}
                  onChange={(evt) => this.setState({ create_date: evt.target.value })}
                  ></input>
               </div>
               <br/>
               <div className="form-group">
               <label htmlFor="startdate" > Start Date<span style={{color:'red'}}>*</span> : &nbsp;</label>
                  <input 
                  type="date" 
                  id="startdate" 
                  name="startdate"  
                  style={{ width: '150px', height: '0.7cm' }}
                  value={this.state.start_date}
                  onChange={(evt) => this.setState({ start_date: evt.target.value })}
                  ></input>
               </div>
               <br/>
               <div className="form-group">
               <label htmlFor="Tdate" > Target Date <span style={{color:'red'}}>*</span> : &nbsp;</label>
                  <input 
                  type="date" 
                  id="Ttdate" 
                  name="Tdate" 
                  style={{ width: '150px', height: '0.7cm' }}
                  value={this.state.target_date}
                  onChange={(evt) => this.setState({ target_date: evt.target.value })}
                  ></input>
               </div>
               <br/>
               <div className="form-group">
               <label htmlFor="cdate" > Close Date : &nbsp;</label>
                  <input 
                  type="date" 
                  id="cdate" 
                  name="cdate"  
                  style={{ width: '150px', height: '0.7cm' }}
                  value={this.state.close_date}
                  onChange={(evt) => this.setState({ close_date: evt.target.value })}
                  ></input>
               </div><br/>

               {customFieldArray.map((data, i) => {
        // Rendering input field based on selected type
        let inputField;
        switch (data.format) {
          case "integer":
            inputField = <input type="number" 
            style={{ width: '180px', height: '0.7cm' }}
            value={data.value} 
            onChange={(e) => this.handleChange(e, i)} 
            onKeyDown={(e) => {
              if (
                !/[0-9]/.test(e.key) &&
                !['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)
              ) {
                e.preventDefault();
              }
            }}
            />;
            break;
          case "date":
            inputField = <input type="date" 
            style={{ width: '150px', height: '0.7cm' }}
            value={data.value} 
            onChange={(e) => this.handleChange(e, i)} 
            />;
            break;
          case "boolean":
            inputField = <input type="checkbox" 
            checked={data.value} 
            onChange={(e) => this.handleChange(e, i)} 
            />;
            break;
          case "dropdown":
              inputField = (
                <div>
                  <select style={{ width: '180px', height: '0.7cm' }} value={data.value}onChange={(e) => this.handleChange(e, i)} >
                  <option value=" "> Select option </option>
                    {data.options.map((option, index) => (
                      <option key={index} value={option}>{option}</option>
                    ))}
                  </select>
                  
                </div>
              );
              break;
          case 'checkbox':
              inputField = (
                <div>
                  {data.options && data.options.map((option, idx) => (
                    <>
                    <span key={idx}>
                      <label htmlFor={`option-${idx}`}>{option}</label>
                      <input 
                      type="checkbox" 
                      id={`option-${idx}`}
                      name={option}
                      checked={Array.isArray(data.value) ? data.value.find(cb => cb.label === option)?.value || false : false}
                      onChange={(e) => this.handleChange(e, i)}/> 
                    </span>&ensp;</>
                  ))}
                </div>
              );
            break;
          default:
            inputField = <input type="text" 
            style={{ width: '350px', height: '0.7cm' }}
            value={data.value} 
            onChange={(e) => this.handleChange(e, i)}
             />;
        }
       
        return (
          <div className="form-group" key={i}>
            <label>
            {data.name} : &emsp;</label>
            {inputField} &nbsp;
             <br /><br/>
          
          </div>
          
        );
      })}
        </form>    
        </div>

         
         <div style={{ width: '130px', height: '0.5cm', marginLeft:'170px', marginTop:'8px', marginBottom: '100px'  }}>
          
          <button style={{backgroundColor:'#664fa7', color:'white', cursor:'pointer'}} onClick={this.insertCreateTicketInfo}> Create Ticket</button>
          
         </div>
         <div id="copyright-footer">
            <p style={{fontSize:'12px'}}>
            <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
            </p>
          </div>
    </div>
    
    
    
  )
}
}


export default Issue;