import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AppBar,  Tabs, Tab, Toolbar,Typography, Box  } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// import { PowerSettingsNew } from '@material-ui/icons';
// import { get } from '../../global_files/serverRequests';
import { Link } from 'react-router-dom';
import globalVariables from "../../global_files/globalVariables";
// import Overview from './overview';
// import Priority from './priority';
// import Assignee from './assignee';
import { signOut,getFreshToken } from '../../global_files/auth';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={5} className='p-0'>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    const useStyles = theme => ({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        tabs: {
            flexGrow: 1,
            alignSelf: 'flex-end'
        },
    });


class admin_dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
          searchInput: '',
          ticketInfo:[],
        //   matches: [],
          content: '',
          tab_value: 0,
        //   currentPage: 1,
        //   rowsPerPage: 15,
        //   pages: {},
          anchorEl: null,
          is_show_password: false,
        };
      }
      
    
    handleTabs = (evt, tab_value) => {
        this.setState({ tab_value: tab_value })
    }
 
    validateSignedIn = async () => {
        let is_fresh_token = await getFreshToken()
        if (!is_fresh_token) {
            //this.props.history.replace("/sign_in");
            window.location.replace('/login')
            return
        }
    }


    render() {
        
        let user_name = localStorage.getItem(globalVariables.USER_NAME)

           
        return (
            
            <div className='vh-100 scroll_hidden'>
                               
                <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor:"#321e6e", color:"white", paddingTop:'2px', paddingLeft:"17px", }}>
                    <span>
                    {/* <Link to="/sem_home" className="link-no-underline" >Home</Link>&emsp; */}
                    <Link to ="/amc_count" className="link-no-underline"> Home</Link>&emsp;
                    <Link to ="/all_tech_issues" className="link-no-underline"> All Issues </Link>&emsp;
                    <Link to ="/technicians" className="link-no-underline"> Technicians</Link>&emsp;
                    <Link to ="/customers" className="link-no-underline"> Customers</Link>&emsp;
                    <Link to ="/elevators" className="link-no-underline"> Elevators</Link>&emsp;
                    {/* <Link to ="/custom_fields" className="link-no-underline"> Custom Fields</Link>&emsp; */}
                    <Link to ="/reports" className="link-no-underline"> Reports </Link>
                    </span>
                    <span className="user-name">
                    Logged in as : {user_name} &ensp;
                    <button id='logout'onClick={() => signOut()}><b>Logout</b></button>
                    </span>
                </div>
                <div style={{ display: 'flex', backgroundColor:"#664fa7", color:"white", paddingTop:'2px' }}>
                    <span><h3 style={{height:"30px", paddingLeft:"15px", marginTop:"1px", paddingTop:"5px"}}> Smart Elevator Maintenance &emsp; &gt;&gt; <Link id="navigation"> Issues' Summary</Link> </h3></span>
                    <span style={{ marginLeft: 'auto', marginRight:'10px', marginTop:"9px" }}>
                        <input
                            type="text"
                            id='search-input'
                            size="small"
                            value={this.state.searchTerm}
                            onChange={this.handleSearchInputChange}
                            placeholder=" search "
                        />
                        
                    </span>
                </div>
                <AppBar position="sticky" color='default'>
                    <Toolbar className='bg-white pr-0 pl-1'>
                                                
                                    <Tabs
                                        className={this.props.classes.tabs}
                                        style={{ alignSelf: 'flex-end' }}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="scrollable"
                                        scrollButtons="on"
                                        value={this.state.tab_value}
                                        onChange={this.handleTabs} >
                                        <Tab component="div" label={<span style={{ fontWeight: 'bold' }}> Issues' Priority</span>} />
                                        <Tab component="div" label={<span style={{ fontWeight: 'bold' }}> Assignee </span>} />
                                        <Tab component="div" label={<span style={{ fontWeight: 'bold' }}> Reporter</span>} />
                                    </Tabs>

                    </Toolbar>
                </AppBar>
                <div>
                    <TabPanel value={this.state.tab_value} index={0}>
                        {/* <Priority /> */}
                    </TabPanel>
                    <TabPanel value={this.state.tab_value} index={1}>
                        {/* <Assignee /> */}
                    </TabPanel>
                                        
                </div>
            </div>
        )
    }
}

export default withStyles(useStyles)(admin_dashboard)

