import React, { Component } from 'react';
import '../adminDash.css';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { get } from '../../global_files/serverRequests';
import globalVariables from "../../global_files/globalVariables";
import Tabpanels from './tab_component';
import urls from '../../global_files/urls';
import { getFreshToken } from '../../global_files/auth';



class view_all_issues extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editElevatorId:'',
      editSummary:'',
      editDescription:'',
      editlabel:'',
      editReporter:'',
      editAssignee:'',
      editStatus:'',
      editPriority:'',
      editStartDate:'',
      editTargetDate:'',
      ticketInfo:[],
      employee_list:[],
      elevator_list:[],
      currentPage: 1,
      rowsPerPage: 25,
      editTicket: null,
      searchInput:''

      
    };
  }

  componentDidMount(){
    this.getemployeeList();
    this.handleList();
    this.validateSignedIn();
  }

  // componentWillUnmount() {
  //   // fix Warning: Can't perform a React state update on an unmounted component
  //   this.setState = () => {
  //       return;
  //   };
  // }

validateSignedIn = async () => {
  let is_fresh_token = await getFreshToken()
  if (!is_fresh_token) {
    console.log("*************************is_fresh_token*******************************", is_fresh_token)
    alert("Your session has expired, please login again!!");
      window.location.replace('/login')
     return
  }
}

 handleList= async ()=>{
          let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
          let elevator_id = localStorage.getItem(globalVariables.SELECTED_ELEVATOR_id)
          
          console.log("----------access_tocken in issue list----------",access_tocken)
          let headers = {
            
            'content-Type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${access_tocken}`,
        }
        
        let data = await get(`${urls.BASE_URL}/api/tickets/ticket-list/${elevator_id}/`, headers)
             console.log("----------data in handle list----------",data);
               data = data.results
              // let global_ticket_id = [];
              // global_ticket_id = data.map(ticketId => ticketId.ticket_id);
              if (data && data.length > 0) {
                this.setState({
                  ticketInfo: data,
                  // global_ticket_id:global_ticket_id,
                });
                console.log("ticket info array : ",this.state.ticketInfo);
                
                }
  }

  
      getElevatorList= async ()=>{
        let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
        // let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)
        // let tech_Id = '299efeba-7215-4ebc-9e16-59e73cf36fd6'
        // let tech_Id = localStorage.getItem(globalVariables.SELECTED_TECH_ID)
        
        console.log("----------access_tocken in employee list----------",access_tocken);
        // console.log("**************selected technicians user id************* ",tech_Id);
        // console.log("**************selected technicians user id************* ",dealer_uuid);
        let headers = {
          // 'content-type': 'application/json'
          'content-Type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${access_tocken}`,
      }
      // let response = await get(`${urls.BASE_URL}/api/users/${tech_Id}/elevators/`, headers)
       let data = await get(`${urls.BASE_URL}/api/elevators/`, headers)
        // console.log("----------------------------------",data);
        // if (data !== false) {
          // let data = response.elevators;
            console.log("----------data in getElevator list----------",data);
            // let user_Id = [];
            if (data && data.length > 0) {
              // user_Id = data.map(employee => employee.user_id);
              // console.log("*******************************user_Id****",user_Id)
              this.setState({
                elevator_list: data,
                // user_id:user_Id,
              });
              
              console.log("ticket info array : ",this.state.elevator_list);
              // if (this.state.user_id && Array.isArray(this.state.user_id)) {
              //   this.state.user_id.forEach(user_id => this.getOpenTicketCount(user_id));
              // }
                        
            }
          }

       
      getemployeeList= async ()=>{
        let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
        let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)
        // localStorage.setItem(globalVariables.USER_ID, dealer_uuid)
        console.log("----------access_tocken in employee list----------",access_tocken)
        let headers = {
          // 'content-type': 'application/json'
          'content-Type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${access_tocken}`,
      }
      let response = await get(`${urls.BASE_URL}/api/account/${dealer_uuid}/employee_list/`, headers)
        // console.log("----------------------------------",data);
        // if (data !== false) {
          let data = response.results

            console.log("----------data in getemployeeList list----------",data);
            if (data && data.length > 0) {
              this.setState({
                employee_list: data,
                });
              console.log("**********employee_list**** : ",this.state.employee_list);
            }
    }


    
    handleEdit = (ticket) => {
                localStorage.setItem(globalVariables.SELECTED_TICKET, ticket);
                window.location.replace('/update_ticket')
    };

    handleDelete =  (ticketId) => {
      let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
      localStorage.setItem(globalVariables.SELECTED_TICKET, ticketId);
      let ticket_id = localStorage.getItem(globalVariables.SELECTED_TICKET);
        
        console.log("----------access_tocken in handleDelete list----------",access_tocken)
        
        const userConfirmed = window.confirm('Are you sure you want to delete this ticket?');

        if (!userConfirmed) {
          return; // If the user clicks "Cancel" in the confirmation dialog, abort the delete operation
        }
        
        try {
          const response = fetch(`${urls.BASE_URL}/api/tickets/ticket-delete/${ticket_id}/`, {
            method: 'DELETE',
            headers: {
              'content-Type': 'application/json; charset=UTF-8',
              'Authorization': `Bearer ${access_tocken}`,
            },
          });
      
          // if (response.status === 204) {
            // const data =  response.json(); 
            console.log(`Ticket with ID ${ticket_id} deleted successfully`, response);
            alert(`Ticket with ID ${ticket_id} deleted successfully`);
            this.handleList();
            window.location.reload();
            // } else {
          //   // Handle error cases
          //   console.error(`Failed to delete ticket with ID ${ticket_id}`);
          // }
        } catch (error) {
          console.error('Error during delete request:', error);
        }
        

    }
  
    handleSaveEdit = async () => {
      this.setState({
        editTicket: null,
      });
    };

    handleChangePage = (newPage) => {
      this.setState({
        currentPage: newPage,
      });
    };

    handleSearchInputChange = (e) => {
        this.setState({ searchInput: e.target.value });
    };


render(){
  const { ticketInfo, currentPage, rowsPerPage, searchInput } = this.state;
  // const { issues } = this.props;
  // const { employee_list } = this.state;

  const filteredTicketInfo = ticketInfo.filter((ticket) =>
  ticket.ticket_key.toLowerCase().includes(searchInput.toLowerCase())||
  ticket.status.toLowerCase().includes(searchInput.toLowerCase()) ||
  ticket.priority.toLowerCase().includes(searchInput.toLowerCase()) ||
  ticket.label.toLowerCase().includes(searchInput.toLowerCase()) 
  // || ticket.update_date.toString().includes(searchInput.toString())
  );

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredTicketInfo.slice(indexOfFirstRow, indexOfLastRow);

  return (
    <div className='vh-100 scroll_hidden'>
      
      <Tabpanels/>
      
          <h3 style={{paddingLeft:'8px'}}> Tickets </h3>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop:'0.3cm', paddingLeft:'8px'}}>
              <span>
              <input
                            type="text"
                            id='search-ticket'
                            size="small"
                            value={this.state.searchTerm}
                            onChange={this.handleSearchInputChange}
                            placeholder=" search Ticket "
                        />
              </span>
              <span>
                <Link to="/create_ticket_for_selected_elevator/technician">
                {/* <Link to="/elevatorsList"> */}
                  <Button id='contactButton' startIcon={<AddIcon />}> Add New Ticket</Button>
                </Link>
              </span>
          </div>
          <br/>
          <div>
            <table>
                <thead>
                  <tr>
                    <th>Ticket key</th>
                      <th>Status</th>
                      <th>Priority</th>
                      <th>Label</th>
                      <th>Assignee</th>
                      <th>Updated</th>
                      <th>Action</th>
                  </tr>
                </thead>   
                <tbody>
                  {currentRows.map((ticket) => (
                        <tr key={ticket.ticket_key}>

                          <td>
                            <span style={{
                                    display: 'inline-block',
                                    backgroundColor:"#664fa7",
                                    color:"white",
                                    paddingTop: '1.1px',
                                    paddingLeft:'2.5px',
                                    paddingRight:'2.5px',
                                    borderRadius: '4px',
                                    }}>
                              {ticket.ticket_key}
                            </span>
                          {/* </Link> */}
                          </td>

                          <td style={{ color:ticket.status.toLowerCase() === 'open' ? 'rgb(224, 15, 15)' : 'inherit' }}>{ticket.status}</td>
                          

                          <td>
                              {ticket.priority === 'High' ? (
                                <span style={{color:'rgb(224, 15, 15)', fontSize:'1.2em'}}> &uarr;&uarr; </span>
                              ) : ticket.priority === 'Medium' ? (
                                <span ><img width="12" height="12" src="https://img.icons8.com/metro/26/228BE6/equal-sign.png" alt="equal-sign"/> </span>
                              ) : ticket.priority === 'Critical' ? (
                                <span ><img width="12" height="17" src="https://img.icons8.com/fluency-systems-filled/48/FA5252/chevron.png" alt="chevron"/> </span>
                              ):null}
                              {ticket.priority}
                          </td>

                          <td>{ticket.label}</td>

                          <td>{ticket.assignee_name}</td>

                          <td>
                          {new Date(ticket.update_date).toLocaleString('en-US', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true,
                          })} </td>
                          

                          <td>
                            <button style={{ fontWeight:'bold'}} onClick={() => this.handleEdit(ticket.ticket_id)} >
                              Edit
                            </button> &nbsp;
                            <button style={{ color:'rgb(224, 15, 15)', fontWeight:'bold'}} onClick={() => this.handleDelete(ticket.ticket_id)}>
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                </tbody>
            </table>
          </div>
          <div style={{marginTop:'2.2cm', display: 'flex', justifyContent: 'center', marginBottom: '2.2cm' }}>
            <button id ='rowsPerPageButton' onClick={() => this.handleChangePage(currentPage - 1)} disabled={currentPage === 1}>
              {"< Prev"}
            </button>
            <span style={{fontSize:'small'}}>
              {`Page ${currentPage} / ${Math.ceil(ticketInfo.length / rowsPerPage)}`}
            </span>
            <button id ='rowsPerPageButton' onClick={() => this.handleChangePage(currentPage + 1)} disabled={currentPage === Math.ceil(ticketInfo.length / rowsPerPage)}>
              {"Next >"}
            </button>

          </div>
          <div id="copyright-footer">
            <p style={{fontSize:'12px'}}>
            <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
            </p>
          </div>       
    </div>
  );
}
}
export default view_all_issues;