import React from 'react';
import '../adminDash.css';
import { Link } from 'react-router-dom';
import Homepage from '../Admin/Homepage';
import Footer from './Footer';

function PricingDetails() {
   const handleContact = () => {
      // URL to redirect to
      const url = 'https://www.neudeep.com/Contactneudeep.php';
      // Redirect to the given URL
      window.location.href = url;
    };
   
  return (
    <div >
        <Homepage/>
        <div className="pricing_container" style={{ margin: '70px' }}>
        <div className='pricing_card' >
            <p id='tag'><b> Small</b></p>
            <p id='price'><b>13,000 Rs/Year </b> </p>
            <p style={{ fontSize:'14px' }}> &gt;&gt; Upto 100 assets <br/>
            &gt;&gt; 1GB Storage <br/>
            &gt;&gt; First 6 months free </p> 
            <p><button onClick={handleContact}>Buy Now</button></p>  
        </div>
        <div className='pricing_card'>
            <p id='tag'> <b>Medium</b></p>
            <p id='price'> <b>18,000 Rs/Year </b></p>
            <p style={{ fontSize:'14px' }}>&gt;&gt; 100 to 300 assets <br/>
            &gt;&gt; 2GB Storage <br/>
            &gt;&gt; First 6 months free </p> 
            <p><button onClick={handleContact}>Buy Now</button></p>   
        </div>
        <div className='pricing_card'>
            <p id='tag'><b>Business</b></p>
            <p id='price'> <b>22,000 Rs/Year</b> </p>
            <p style={{ fontSize:'14px' }}> &gt;&gt; More than 300 assets <br/>
            &gt;&gt; 5GB Storage <br/>
            &gt;&gt; First 6 months free </p> 
            <p><button onClick={handleContact}>Buy Now</button></p>    
        </div>
        
        </div>
        <div style={{marginTop:'130px'}}>
        <Footer/>
        </div>
     </div>
  );
}

export default PricingDetails;
