import React from 'react';
import '../adminDash.css';
import { Link } from 'react-router-dom';
// import Homepage from '../Admin/Homepage';
import LocationIcon from '../../assets/find_us_icon.png';
import EmailIcon from '../../assets/email-icon.png';
import phoneIcon from '../../assets/phone-icon.png';

function Footer() {
  return (
    <div>
    {/* <div style={{marginLeft:'8.5%', marginRight:'8.51%', marginTop:'25px', display:'flex', justifyContent:'space-evenly', color:'#fff', backgroundColor:'#362a3c'}}> */}
    <div id="footer-homePage">
        <div>
        <h3>About Us</h3>
        <p style={{fontSize:'14px'}}>Neudeep Technologies is Software Company,<br/>
        committed to provide the best of software solutions<br/>
        with global expertise to deliver best-in-class functionality.
        </p>
        </div>
        
        <div>
          <h3>Find Us</h3>
                   
          <p style={{fontSize:'14px'}}><img width="" height="30px" src={LocationIcon} alt="location"/>
           Neudeep Technologies Pvt Ltd. B-21,<br/>
           Manisha Blitz , Pune Solapur Road ,<br/>
            Hadapsar, Pune , Maharashtra. 411028
          </p>
        </div>

        <div>
        <h3>Contact Us</h3>
        <p style={{fontSize:'14px'}}>
        
          <img src={EmailIcon} alt="email"/>
          <em> <a href="mailto:sagar.swami@neudeep.in" style={{ 
            color: '#007bff', 
            fontFamily: 'Arial, sans-serif', 
            fontSize: '16px', 
            textDecoration: 'none' 
          }}> sagar.swami@neudeep.in</a>
          </em><br/>
          <img height="20px" src={phoneIcon} alt="phone"/> +91 9075310243
        
        </p>
        </div>
    </div>
    <div id="copyright-footer-homePage">
      <p style={{fontSize:'12px'}}>
      <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
      </p>
    </div>    
    </div>
  );
}
 
export default Footer;
