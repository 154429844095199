import React, { Component } from 'react';
// import { render } from 'react-dom';
import { signOut } from '../../global_files/auth';
import { Link } from 'react-router-dom';
import globalVariables from "../../global_files/globalVariables";

class ActivityTabs extends Component {


render(){
  let user_name = localStorage.getItem(globalVariables.USER_NAME);
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor:"#321e6e", color:"white", paddingTop:'2px', paddingLeft:"17px", }}>
        
        <span>
        <Link to ="/amc_count" className="link-no-underline"> Home</Link>&emsp;
        <Link to ="/all_tech_issues" className="link-no-underline"> All Issues </Link>&emsp;
        <Link to ="/technicians" className="link-no-underline"> Technicians</Link>&emsp;
        <Link to ="/customers" className="link-no-underline"> Customers</Link>&emsp;
        <Link to ="/elevators" className="link-no-underline"> Elevators</Link>&emsp;
        <Link to ="/custom_fields_for" className="link-no-underline"> Custom Fields</Link>&emsp; 
        {/* <Link to ="/reports" className="link-no-underline"> Reports </Link> */}
        <Link to ="/import" className="link-no-underline"> Settings </Link>&emsp;
        </span>

        <span className="user-name">
        Logged in as : {user_name} &ensp;
        <button id='logout'onClick={() => signOut()}><b>Logout</b></button>
        </span>
</div> 
  );
}
}
export default ActivityTabs;