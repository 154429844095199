// import globalVariables from './globalVariables';

export default class urls {

    static DEFAULT_IP = "43.205.44.220";
    static DEFAULT_DOMAIN_NAME ="semapp.in";
    // static IP_ADDRESS = localStorage.getItem(globalVariables.IP_ADDRESS)

    // static BASE_URL = `https://${this.DEFAULT_IP}` //"http://127.0.0.1:5000" "http://43.205.44.220:8080/"
    static BASE_URL = `https://semapp.in`
    // static LOGIN = urls.BASE_URL + "/admin_dashboard"
    static TOKEN_REFRESH = urls.BASE_URL + "/token_refresh"
    // static SIGNOUT = urls.BASE_URL + "/sign_out"
    static SIGNOUT = urls.BASE_URL + "/api/account/logout/"
    // static ALL_OPERATORS = urls.BASE_URL + "/get_all_operators"
    
   
    

    

    
}

