import React, { Component } from 'react';
import './adminDash.css';
import globalVariables from "../global_files/globalVariables";
import { post } from '../global_files/serverRequests';
import { isEmpty } from '../global_files/validation';
import urls from '../global_files/urls';
import { Link } from 'react-router-dom';
import CompanyLogo from '../assets/Neudeep_logo.jpeg';
import BackArrow from '../assets/icons8-back-arrow.gif';
import passwordEye from '../assets/icons8-eye-64.png';

class signIn extends Component {
    constructor(props) {
      super(props);
      this.state = {
        username: '',
        password: '',

        showDialog: false,
        showPasscodeForm: false,
        input_email_for_password_reset:'',
        userName_for_newPassword:'',
        received_passcode:'',
        new_password:'',
        confirm_password:'',
        email_for_save_new_pass:'',

        passwordShown:false,
      };
    }
  
    // Event handler for the username input field
    handleUsernameChange = (event) => {
      this.setState({ username: event.target.value });
    };
  
    // Event handler for the password input field
    handlePasswordChange = (event) => {
      this.setState({ password: event.target.value });
    };


isEmptyFields = () => {
  let data = [
      // this.state.ip_address,
      this.state.username,
      this.state.password
  ]
  return isEmpty(data)
}

jsonData = () => {
  let data = {
      "username": this.state.username.trim(),
      "password": this.state.password.trim()
      
  }
  localStorage.setItem(globalVariables.USER_NAME, this.state.username)
  return JSON.stringify(data)
}

  userLogin = async () => {
    if (this.isEmptyFields()) {
        alert("Fill All Fields !!!")
        return
    }
    this.startLoading()
    let headers = {
        'content-Type': 'application/json; charset=UTF-8',
    }
    let post_data = this.jsonData()
    console.log(post_data);
    let data = await post(`${urls.BASE_URL}/api/account/token/`, post_data, headers)
    console.log("--------------------user login--------------",data);
    if (!data.detail) {
        
              localStorage.setItem(globalVariables.AUTH_TOKEN, data['access'])
              localStorage.setItem(globalVariables.REFRESH_TOKEN, data['refresh'])
              localStorage.setItem(globalVariables.USER_ID, data['user_id'])
              localStorage.setItem(globalVariables.DEALER_EMAIL, data['admin_email'])
              
              if (data['user_type'] === "DealerUser" ){
                window.location.replace("/amc_count");
                let dealer_email = localStorage.getItem(globalVariables.DEALER_EMAIL)
                let dealer_user_id = localStorage.getItem(globalVariables.USER_ID)
                console.log("--------------------USER_EMAIL--------------",dealer_email);
                console.log("--------------------USER_ID--------------",dealer_user_id);
                
           }
           
      }
    else {
        alert('please check your sign in details  !!!')
        console.log(data);
    }
    this.stopLoading()
  }

handleSignUpClick =() =>{
    window.location.replace("/new_dealer_registration");
}



startLoading = () => {
  this.setState({ open_progress_bar: true })
}

stopLoading = () => {
  this.setState({ open_progress_bar: false })
}


/**********************forgot password section*********** */
handleForgotPasswordClick = () => {
  this.setState({ showDialog: true });
};

handleCheckboxChange = (e) => {
  this.setState({ showPasscodeForm: e.target.checked });
};

handleCloseDialog = () => {
  this.setState({ showDialog: false, showPasscodeForm: false });
};

sendMail = async () => {
  let data = {
    "email": this.state.input_email_for_password_reset    
}
// return JSON.stringify(data)
  let headers = {
    'content-Type': 'application/json; charset=UTF-8',
}
let post_data = JSON.stringify(data);

console.log("--------------------sendMail for login reset--------------",post_data);
let response = await post(`${urls.BASE_URL}/api/account/password/reset/`, post_data, headers)
console.log("--------------------user login--------------",response);
if(response.detail==="Password reset e-mail has been sent."){
  alert("Password reset e-mail has been sent");
}

}

SaveNewPassword = async () => {
  let data = {
    "email": this.state.email_for_save_new_pass  ,
    "new_password1": this.state.new_password,
    "new_password2": this.state.confirm_password,
    "uid": this.state.userName_for_newPassword,
    "token": this.state.received_passcode,
    }
    // return JSON.stringify(data)
      let headers = {
        'content-Type': 'application/json; charset=UTF-8',
    }
    let post_data = JSON.stringify(data)

    console.log("--------------------sendMail for login reset--------------",post_data);
    let response = await post(`${urls.BASE_URL}/api/account/password/reset/confirm/`, post_data, headers)
    console.log("--------------------user login--------------",response);
    if(response.detail==="Password has been reset with the new password."){
    alert("Your password is successfully reset with the new password!");
    window.location.replace("/login");
    }

}

togglePasswordVisiblity = () => {
  this.setState(prevState => ({
    passwordShown: !prevState.passwordShown
  }));
};
    
render() {
  const { showDialog, showPasscodeForm, passwordShown } = this.state;
  return (
    // <div className="main">

    //     <div style={{ display: 'flex', backgroundColor:"#6f60aa", color:"white", padding:'8px',  }}>
    //     <Link to="/" style={{color:'#fff', textDecoration: 'none', fontSize:'18px'}}><img src={BackArrow} alt="Back arrow" style={{ marginRight: '8px', borderRadius:'35px', width:'30px' }} /></Link>
    //     <em>Home</em>      
    //     </div>

    //  <div className="sub-main">
    //     <div>
    //        <h2>Welcome Admin,</h2>
    //        <div>
    //          <input type="text" id="signIn" placeholder="user name"  onChange={this.handleUsernameChange} className="name"/>
    //        </div>
    //        <div className="second-input">
    //          <input type="password" id="signInPassword" placeholder="password"  onChange={this.handlePasswordChange} className="name"/>
    //        </div>
           
    //       <div className="login-buttonOne">
    //       <button 
    //       id='loginOne'
    //       onClick={this.userLogin}
    //       >Login</button>
    //       </div>

    //       <div className="link">
    //           <a href="#" onClick={this.handleForgotPasswordClick}>Forgot password ?</a>
    //           {showDialog && (
    //       <div className="dialog-reset-password">
    //         {!showPasscodeForm ? (
    //           <div>
    //             <h2>Reset Password</h2>
    //             <input type="email" placeholder="Enter your email" required />
    //             <button>Send</button>
    //             <div>
    //               <input 
    //                 type="checkbox" 
    //                 id="received-passcode" 
    //                 onChange={this.handleCheckboxChange} 
    //               />
    //               <label htmlFor="received-passcode">Received passcode?</label>
    //             </div>
    //             <button onClick={this.handleCloseDialog}>Back</button>
    //           </div>
    //         ) : (
    //           <div>
    //             <h2>Enter Passcode</h2>
    //             <input type="text" placeholder="Username" required />
    //             <input type="text" placeholder="Received Passcode" required />
    //             <input type="password" placeholder="New Password" required />
    //             <input type="password" placeholder="Confirm Password" required />
    //             <input type="email" placeholder="Email" required />
    //             <button>Save</button>
    //             <div>
    //               <input 
    //                 type="checkbox" 
    //                 id="received-passcode" 
    //                 checked={showPasscodeForm}
    //                 onChange={this.handleCheckboxChange} 
    //               />
    //               <label htmlFor="received-passcode">Received passcode?</label>
    //             </div>
    //             <button onClick={this.handleCloseDialog}>Back</button>
    //           </div>
    //         )}
    //       </div>
    //     )}
    //       </div>

    //       <div>
    //       <button 
    //       id='SignUpButton'
    //       onClick={this.handleSignUpClick}>
    //         Sign Up
    //       </button>
    //       </div>
           
           
           
 
    //      </div>
       
    //   </div>
    // </div>

    <div className="main-container">
        {showDialog && (
          <div className="overlay">
            <div className="dialog-reset-password">
              {!showPasscodeForm ? (
                <div>
                  <h2>Reset Password</h2>
                  <input 
                  type="email" 
                  placeholder="Enter your email" 
                  value={this.state.input_email_for_password_reset}
                  onChange={(evt) => this.setState({ input_email_for_password_reset: evt.target.value })}
                  required />
                  <button className="send-reset-pass-mail" onClick={this.sendMail}>Send</button>
                  <div className="received-passcode">
                  <label htmlFor="received-passcode">Received passcode?</label>
                    <input 
                      type="checkbox" 
                      id="received-passcode" 
                      onChange={this.handleCheckboxChange} 
                    />
                    
                  </div>
                  <button className="back" onClick={this.handleCloseDialog}>Back</button>
                </div>
              ) : (
                <div>
                  <h2>Enter Passcode</h2>
                  <input type="text" placeholder="Username" value={this.state.userName_for_newPassword} onChange={(evt) => this.setState({ userName_for_newPassword: evt.target.value })} required />
                  <input type="text" placeholder="Received Passcode" value={this.state.received_passcode} onChange={(evt) => this.setState({ received_passcode: evt.target.value })} required />
                  <input type="password" placeholder="New Password" value={this.state.new_password} onChange={(evt) => this.setState({ new_password: evt.target.value })} required />
                  <input type="password" placeholder="Confirm Password" value={this.state.confirm_password} onChange={(evt) => this.setState({ confirm_password: evt.target.value })} required />
                  <input type="email" placeholder="Email" value={this.state.email_for_save_new_pass} onChange={(evt) => this.setState({ email_for_save_new_pass: evt.target.value })} required />
                  <button className="send-reset-pass-mail" onClick={this.SaveNewPassword}>Save</button>
                  <div className='received-passcode'>
                  <label htmlFor="received-passcode">Received passcode?</label>
                    <input 
                      type="checkbox" 
                      id="received-passcode" 
                      checked={showPasscodeForm}
                      onChange={this.handleCheckboxChange} 
                    />
                    
                  </div>
                  <button className="back" onClick={this.handleCloseDialog}>Back</button>
                </div>
              )}
            </div>
          </div>
        )}

        <div className={`main ${showDialog ? 'blur' : ''}`}>
          <div style={{ display: 'flex', backgroundColor: "#6f60aa", color: "white", padding: '8px',textDecoration: 'none', fontSize: '20px' }}>
            <Link to="/" >
              <img src={BackArrow} alt="Back arrow" style={{ marginRight: '8px', borderRadius: '35px', width: '30px' }} />
            </Link>
            <b><em>Home</em></b>
          </div>

          <div className="sub-main">
            <div>
              <h2>Welcome Admin,</h2>
              <div>
                <input 
                  type="text" 
                  id="signIn" 
                  placeholder="user name"  
                  onChange={this.handleUsernameChange} 
                  className="name"
                />
              </div>
              <div className="second-input">
                <input 
                  // type="password" 
                  type={passwordShown ? "text" : "password"}
                  id="signInPassword" 
                  placeholder="password"  
                  onChange={this.handlePasswordChange} 
                  className="name"
                  // className="input-field"
                />
                <img onClick={this.togglePasswordVisiblity} src={passwordEye} width='25' height='25' className="eye-icon"/>{" "}
              </div>
              <div className="login-buttonOne">
                <button 
                  id='loginOne'
                  onClick={this.userLogin}
                >
                  Login
                </button>
              </div>

              <div className="link">
                <a href="#" onClick={this.handleForgotPasswordClick}>Forgot password?</a> &nbsp;
                <a href="/delete_account" style={{color: "red"}}>Delete account?</a>
              </div>

              <div>
                <button 
                  id='SignUpButton'
                  onClick={this.handleSignUpClick}
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    
  );
}
}

export default signIn;