import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import LoginPage from './Pages/signIn';
import AdminHome from './Pages/Admin/admin_dashboard.jsx';
import IssueCreate from './Pages/Admin/Issue';
import EditTicket from './Pages/Admin/update_ticket';
import ElevatorsList from './Pages/Admin/elevators.jsx';
import TicketList from './Pages/Admin/Issues.jsx';
import Technicians from './Pages/Admin/technicianList.jsx';
import TechAllTickets from './Pages/Admin/view_all_issues';
import AllTickets from './Pages/Admin/all_tech_issues';
import CreateTicketForElevator from './Pages/Admin/CreateTicketElevatorTechWise.jsx';
import UpdateTicketFromTechWindow from './Pages/Admin/UpdateTicketFromTechWindow';

import Home from './Pages/Admin/Homepage.jsx';
import AmcCount from './Pages/Admin/AmcDashboard';
import CompletedMaintList from './Pages/AMC-Dashboard-content/maintenComp.jsx';
import InDueMaintenList from './Pages/AMC-Dashboard-content/maintenDue.jsx';
import NotRenewdMaintenList from './Pages/AMC-Dashboard-content/maintenNotRenewed.jsx';

import Report from './Pages/Reports/reports.jsx';
import PrioritySummary from './Pages/Reports/priority.jsx';
import AssigneeSummary from './Pages/Reports/assignee';

import CustomerTab from './Pages/Admin/CustomerTab.jsx';
import AddTechnicians from './Pages/Admin/AddTechnicians.jsx';
import EditTech from './Pages/Admin/EditTechnician.jsx';
import AddCustomers  from './Pages/Admin/AddCustomers.jsx';
import ElevatorsTab from './Pages/Admin/ElevatorsTab.jsx';
import AddElevator from './Pages/Admin/AddElevator.jsx';
import EditElevator from './Pages/Admin/EditElevator.jsx';
import EditCustomer from './Pages/Admin/EditCustomer.jsx';
import CustomeFields from './Pages/Admin/CustomeFIelds';
import CustomeFieldsFor from './Pages/Admin/CustomFieldsFor';
import CustomeFieldsList from './Pages/Admin/CustomFieldsList';
import Tech_issues from './Pages/Admin/Tech_issues';
import All_open_tickets from './Pages/Admin/All_open_tickets';
import EditHomePageTicket from './Pages/Admin/EditHomePageTickets';
import AllInprogressTickets from './Pages/Admin/AllInprogressTickets';
import AllOnholdTickets from './Pages/Admin/AllOnholdTickets';
import AllAcceptedTickets from './Pages/Admin/AllAcceptedTickets';
import AllClosedTickets from './Pages/Admin/AllClosedTickets';
import CustomerRaisedIssueList from './Pages/Admin/CustomerRaisedIssueList';
import Settings from './Pages/Admin/Settings';

import Homepage from './Pages/HomePage/About';
import PricingDetails from './Pages/HomePage/PricingDetails';
import About from './Pages/HomePage/About';
import PrivacyPolicyContent from './Pages/HomePage/PrivacyPolicyContent';
import AmcReportList from './Pages/AMC-Dashboard-content/AmcReportList';
import DealerRegistration from './Pages/DealerRegistration';
import DeleteAccount from './Pages/HomePage/DeleteAccountInstructions'


function App() {
  return (
    <BrowserRouter>
   
        <Routes>
        
          <Route path='/' element={<Homepage/>} />
          <Route path='/pricing_details' element={<PricingDetails/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/privacy_policy' element={<PrivacyPolicyContent/>} />
          <Route path='/delete_account' element={<DeleteAccount/>} />

          <Route path='/login' element={<LoginPage/>} /> 
          <Route path='/admin_dashboard' element={<AdminHome/>} />

          <Route path='/Issue' element={<IssueCreate/>} />
          <Route path='/editTicket/:ticket_key' element={<EditTicket/>} />
          <Route path='/elevatorsList' element={<ElevatorsList/>} />

          <Route path='/issues' element={<TicketList />} />
          

          <Route path='/technicians'  element={<Technicians/>} />
          <Route path='/elevators_all_tickets'  element={<TechAllTickets/>} />
          <Route path='/update_ticket'  element={<EditTicket/>} />
          <Route path='/all_tech_issues'  element={<AllTickets/>} />
          <Route path='/create_ticket_for_selected_elevator/technician' element={<CreateTicketForElevator/>} />
          <Route path='/update_ticket_from_home_page' element={<UpdateTicketFromTechWindow/>} />

          <Route path='/amc_count' element={<AmcCount/>}/>
          <Route path='/maintenance_completed' element={<CompletedMaintList/>} />
          <Route path='/maintenance_Indue' element={<InDueMaintenList/>} />
          <Route path='/maintenance_NotRenewed' element={<NotRenewdMaintenList/>} />
          <Route path='/view_amc_report' element={<AmcReportList/>} />

          <Route path='/reports' element={<Report/>} />
          <Route path='/customers' element={<CustomerTab/>} />
          <Route path='/elevators' element={<ElevatorsTab/>} />
          <Route path='/add_technicians' element={<AddTechnicians/>} />
          <Route path='/edit_tech' element={<EditTech/>} />
          <Route path='/edit_cust' element={<EditCustomer/>} />
          <Route path='/edit_elevator' element={<EditElevator/>} />

          <Route path='/add_customer' element={<AddCustomers/>} />
          <Route path='/add_elevator' element={<AddElevator/>} />
          <Route path='/custom_fields' element={<CustomeFields/>} />
          <Route path='/custom_fields_for' element={<CustomeFieldsFor/>} />
          <Route path='/custom_fields_list' element={<CustomeFieldsList/>} />

          <Route path='/tech_issues'  element={<Tech_issues/>} />

          <Route path='/priority_summary' element={<PrioritySummary/>} />
          <Route path='/assignee_summary' element={<AssigneeSummary/>} />

          <Route path='/all_open_tickets' element={<All_open_tickets/>} />
          <Route path='/edit_Open_ticket' element={<EditHomePageTicket/>} />
          <Route path='/all_Inprogress_tickets' element={<AllInprogressTickets/>} />
          <Route path='/all_OnHold_tickets' element={<AllOnholdTickets/>} />
          <Route path='/all_accepted_tickets' element={<AllAcceptedTickets/>} />
          <Route path='/all_closed_tickets' element={<AllClosedTickets/>} />
          <Route path='/import' element={<Settings/>} />
          <Route path='/customers_raised_issues' element={<CustomerRaisedIssueList/>} />
          <Route path='/new_dealer_registration' element={<DealerRegistration/>} />

        </Routes>

    </BrowserRouter>  
  );
}

export default App;
