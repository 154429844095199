import React, { Component } from "react";
import { Link } from 'react-router-dom';
import globalVariables from "../../global_files/globalVariables";
import { getFreshToken } from '../../global_files/auth';
import ActivityTabs from "./ActivityTabs";
import urls from '../../global_files/urls';
import { get, post } from '../../global_files/serverRequests';
import Deleteicon from '../../assets/icons8-trash-50.png';
import Editicon from '../../assets/icons8-edit.png';
import { DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";

class CustomeFields extends Component {
  constructor(props) {    // Constructor method for initializing state and props
    super(props);         // Calling the constructor of the parent class (Component)
    
    this.state = {        // Initializing the component's state
      val: [],
      type: '',
      name: '',
      // checkedOption: '',
      description:'',
      is_required:false,
      customFieldArray: props.customFieldArray || [], // Initialize with passed props or state
      order:0,
      UpdateFormClick:false,
      EditCustomFieldInfo: false,
      EditLabel: '',
      EditDesc:'' ,
      EditType: '',
      Tracker:'' ,
      EditIsRequired:false,

      newOption: null,
      options:[],
      dropdownOptions: '',
      multipleChoiceOptions: '',
    };
    this.handleDragEnd = this.handleDragEnd.bind(this);

  }
  

  componentDidMount(){
    this.validateSignedIn();
    this.getCustomFields();
    
   }

   validateSignedIn = async () => {
    let is_fresh_token = await getFreshToken()
    if (!is_fresh_token) {
        //this.props.history.replace("/sign_in");
        alert("Your session has expired, please login again!!");
        window.location.replace('/login')
        return
    }
  } 

  handleAdd = async() => {
      const { val, type, name, dropdownOptions, multipleChoiceOptions } = this.state; // Destructuring values from the component's state

      // Check if type and name are provided
      if(type === null || type === '' || name === ''){
        alert("Please select type and Name");
        return;
      }

      // Creating a new custom field object
      const newField = {
        type: type,
        name: name,
        options: (this.state.type === 'dropdown' || this.state.type === 'dropdown') ? [] : undefined
      };

      // Adding options if the field type is dropdown
      if (type === 'dropdown') {
        newField.options = dropdownOptions.split('\n').filter(option => option.trim() !== '');
      }

      if (type === 'checkbox') {
        newField.options = multipleChoiceOptions.split('\n').filter(option => option.trim() !== '');
      }

      // Updating the array of custom fields with the new field
      const updatedVal = [...val, newField];
          this.setState({ val: updatedVal, type: type, name: name }, () => {
          // Callback function to log the updated state after setState is completed
          console.log("----------adding ", type, name, updatedVal);
          // console.log("----------adding val", val.name, val.type, val.description);
        })

  };


  handleNameChange = (e) => {                           // Method to handle change in the name input field
    this.setState({ [e.target.name]: e.target.value }); // Setting the state with the updated value of name input
  };


  handleDelete = (i) => {             // Method to handle deletion of a custom field
          const { val } = this.state;
          const deletVal = [...val];        // Creating a copy of the array of custom fields
          deletVal.splice(i, 1);            // Removing the custom field at index i
          this.setState({ val: deletVal }); // Updating the state with the modified array of custom fields
  };



  InsertCustomFields = async () => {
        await this.updateOrder(); // Ensure order is updated before proceeding
        await this.handleAdd();
        let selected_tracker = localStorage.getItem(globalVariables.SELECTED_TRACKER);
        let data = {
            "name" :this.state.name,
            "description" : this.state.description,
            "format" : this.state.type,
            "tracker" : selected_tracker,
            "is_required" : this.state.is_required,
            "order" : this.state.order,
        }

        // Include options if the format is "selection"
        if (this.state.type === "dropdown") {
          data.options = this.state.val.find(item => item.type === "dropdown").options || [];
        }
        if (this.state.type === "checkbox") {
          data.options = this.state.val.find(item => item.type === "checkbox").options || [];
        }
            
        let post_data = JSON.stringify(data);
        console.log("selected option ========== post_data = ", post_data );
        console.log("selected tracker ========== tracker = ", selected_tracker );
        console.log("order ========== order = ", this.state.order );
        
        try {
          let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN);
          let headers = {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${access_tocken}`,
                        }
          let response = await post(`${urls.BASE_URL}/api/custom_fields/add_custom_field/`, post_data, headers)
          console.log("response for add custom field----> InsertCustomFields()---->", response );
          if (response){
            alert("Successfully saved created custom field!")
            window.location.reload();
          }
          // alert("Successfully saved created custom fields!")
              

        } catch (error) {
          console.error("Error in adding CustomFields:", error);
          alert("An error occurred while adding CustomFields. Please try again later.");
        }
  }

handleCheckboxChange = (e) => {
  
  const isChecked = e.target.checked;
    console.log("Checkbox checked:", isChecked);
     // Update the state and use a callback to log the updated state
     this.setState({ is_required: isChecked }, () => {
      console.log("is required ???---->", this.state.is_required);
    });
}


getCustomFields= async ()=>{
      // const {customFieldArray} = this.state
      let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
      let selected_tracker = localStorage.getItem(globalVariables.SELECTED_TRACKER);
      let headers = {
        'content-Type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${access_tocken}`,
    }
    if (selected_tracker === 'ticket'){
        let data = await get(`${urls.BASE_URL}/api/custom_fields/get_custom_fields_by_type/ticket/`, headers) 
        console.log("----------------getCustomFields ------------------",data);
          this.setState({
            customFieldArray: data
            })
      }

      else{
        let data = await get(`${urls.BASE_URL}/api/custom_fields/get_custom_fields_by_type/report/`, headers) 
        console.log("----------------getCustomFields ------------------",data);
          
          // Set state and determine the highest order value within the callback
          this.setState({ customFieldArray: data }, this.updateOrder);
            
      }

  }

  updateOrder = async() => {
        const { customFieldArray } = this.state;
        let highestOrder = 0;
        if (customFieldArray.length > 0) {
          highestOrder = Math.max(...customFieldArray.map(field => field.order));
        }
      
        // Increment the order value
        let newOrder = highestOrder + 1;
        this.setState({ order: newOrder });
  }


  renderInputField = (data, i) => {
        // Rendering input field based on selected type
        let inputField;
        switch (data.format) {
          case "integer":
            inputField = <input type="number" 
            style={{ width: '180px', height: '0.7cm' }}
            onKeyDown={(e) => {
              if (
                !/[0-9]/.test(e.key) &&
                !['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)
              ) {
                e.preventDefault();
              }
            }} 
            />;
            break;
          case "date":
            inputField = <input type="date" 
            style={{ width: '150px', height: '0.7cm' }}
            />;
            break;
          case "time":
              inputField = (
                <input
                  type="time"
                  style={{ width: '150px', height: '0.7cm' }}
                  />
              );
            break;
          case "boolean":
            inputField = <input type="checkbox" 
            />;
            break;
          case 'dropdown':
            inputField = (
              <select style={{ width: '180px', height: '0.7cm' }}>
                {data.options && data.options.map((option, index) => <option key={index} value={option}>{option}</option>)}
              </select>
            );
            break;
          case 'checkbox':
            inputField = (
              <div>
                {data.options && data.options.map((option, idx) => (
                  <>
                  <span key={idx}>
                    <label htmlFor={`option-${idx}`}>{option}</label>
                    <input type="checkbox" id={`option-${idx}`} />
                  </span>&ensp;
                  </>
                ))}
              </div>
            );
            break;
          default:
            inputField = <input type="text" 
            style={{ width: '350px', height: '0.7cm' }}
            />;
        }
         return { name: data.name, inputField, id: data.id };
  }

  
  /*******************************Sequence for custom fields*********************************************************** */
  
  handleDragEnd = (e) => {
    if (!e.destination) return;
    let newCustomFieldArray = Array.from(this.state.customFieldArray);
    let [source_data] = newCustomFieldArray.splice(e.source.index, 1);
    newCustomFieldArray.splice(e.destination.index, 0, source_data);
    // this.setState({ customFieldArray: newCustomFieldArray });
    this.setState({ customFieldArray: newCustomFieldArray }, () => {
      // Print the new indices
      console.log("Updated order:", newCustomFieldArray.map((item, index) => ({ id: item.id, order: index })));
      // Here you can save the new sequence, for example, making an API call
    });
  }

  sanitizeId = (id) => {
    // Replace special characters with underscores
    return id.replace(/[^\w\s]/gi, '_');
  };


  saveCustomFieldOrder = async() => {
    const { customFieldArray } = this.state;
    this.setState({ UpdateFormClick: false })
    const updatedOrder = customFieldArray.map((item, index) => ({ id: item.id, order: index }));
    console.log("updatedOrder --> ", updatedOrder);
    // Prepare the data to be sent to the API
    let data = { order: updatedOrder };
    let post_order_data = JSON.stringify(data);

    console.log("post_order_data --------", post_order_data);

    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    // let selected_tracker = localStorage.getItem(globalVariables.SELECTED_TRACKER);
    let headers = {
      'Content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${access_tocken}`,
  }

    // Replace with your actual API call
    const response = await post(`${urls.BASE_URL}/api/custom_fields/update_order/`,post_order_data, headers)
      console.log("url----", response);
   alert("Saved the new custom field order !");
  };


  handleUpdateFormClick = (e) =>{

     this.setState({ UpdateFormClick: true }, () => {
      console.log("UpdateFormClick---->", this.state.UpdateFormClick);
    });
  }

  /*****************************************Delete custom field **************************************************** */

  handleDeleteCustomField= async(custom_field_id, custom_field_label) =>{
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)

    console.log("----------------selected CustomField id ------------------",custom_field_id);
    let response = await fetch(`${urls.BASE_URL}/api/custom_fields/delete/${custom_field_id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${access_tocken}`,
      },
    }); 
      console.log("----------------getCustomFields ------------------",response);
      if(response.ok){
        alert(`Custom Field "${custom_field_label}" deleted successfully`);
      }
          window.location.reload()
  }

  /**************----------fetch custom field label,type,desc etc info----------************************* */

  GetDataToEditCustomField= async(custom_field_id) =>{
    this.setState({EditCustomFieldInfo: true }, () => {
      console.log("EditCustomFieldInfo flag---->", this.state.UpdateFormClick);
    });

    console.log("custom_field_id in Edit----", custom_field_id);

    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    // let selected_tracker = localStorage.getItem(globalVariables.SELECTED_TRACKER);
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${access_tocken}`,
  }

    // Replace with your actual API call
    const response = await get(`${urls.BASE_URL}/api/custom_fields/${custom_field_id}/`, headers)
      console.log("GetDataToEditCustomField data----", response);
      this.setState({
                      EditLabel: response.name,
                      EditDesc: response.description,
                      EditType: response.format,
                      Tracker: response.tracker,
                      EditIsRequired: response.is_required,
                      order: response.order,
                      customFieldId: response.id
      })
  //  alert("Saved the new custom field order !");
  }

  UpdateCustomField= async() => {
    let custom_field_id = this.state.customFieldId
    console.log("----------custom_field_id in UpdateCustomField----------",custom_field_id);

    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    console.log("----------access_tocken in issue list----------",access_tocken);
    let data={
              "name": this.state.EditLabel,
              "description": this.state.EditDesc,
              "format": this.state.EditType,
              "tracker": this.state.Tracker,
              "is_required": this.state.EditIsRequired,
              "order": this.state.order
            }

        let headers = {
          // 'content-type': 'application/json'
          'content-Type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${access_tocken}`,
      }

      let post_data = JSON.stringify(data);

      console.log("post_data in insertUpdatedTicketInfo---------->",post_data);
      // Parse the JSON string into an object
      let postDataObj = JSON.parse(post_data);

      for (const key in postDataObj) {
      if (key !== 'description' && (postDataObj[key] === null || postDataObj[key] === "")) {
        alert("Please fill in all the required fields.");
        return;
      }
      }

      let response = await post(`${urls.BASE_URL}/api/custom_fields/update/${custom_field_id}/`, post_data, headers)

      console.log("------UpdateCustomField-----", response);
      alert("Custom field updated successfully!");
      window.location.reload();
  }

  render() {
    const { val, type, name, description, customFieldArray, users, UpdateFormClick, EditCustomFieldInfo, options, dropdownOptions, multipleChoiceOptions} = this.state;
    let selected_tracker = localStorage.getItem(globalVariables.SELECTED_TRACKER);
    
    return (
      <div>
        <div style={{marginBottom:'120px'}}>
          <ActivityTabs/>
                {/* <div style={{ display: 'flex', backgroundColor:"#664fa7", color:"white", paddingTop:'2px' }}> */}
                <div className="sem-header-container">
                    <span><h3 style={{height:"30px", paddingLeft:"15px", marginTop:"1px", paddingTop:"5px"}}> Smart Elevator Maintenance &emsp; &gt;&gt;<Link id="navigation" to="/custom_fields_for"> Custom Fields For </Link> &gt;&gt; Create Custom fields &gt;&gt;
                    </h3></span>
                    <span style={{ marginLeft: 'auto', marginRight:'5px',marginTop:"9px" }}>
                        <input
                            type="text"
                            id='search-input'
                            size="small"
                            placeholder=" search "
                        />
                    </span>
                </div>
          <div style={{display:'flex', flexDirection:'row', alignItems: 'flex-start', justifyContent:'flex-start', marginLeft:'8%', marginTop:'2%', gap:'10%'}} >
            <div>
            <button onClick={ this.handleUpdateFormClick} style={{backgroundColor: "#6071aa", color:'#fff', size:'medium', cursor:'pointer'}}><b> Update Form </b></button>

              <div className="custom-bordered-div">

            {UpdateFormClick ? (
                        <form>
                        <DragDropContext onDragEnd={this.handleDragEnd}>
                        <Droppable droppableId="custom-field-droppable">
                          {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                              {customFieldArray.map((data, i) => {
                                const { name, inputField, id } = this.renderInputField(data, i);
                                return (
                                  <Draggable 
                                  key={id} 
                                  // draggableId={this.sanitizeId(name)}
                                  draggableId={`draggable-${id}`}
                                  index={i}>
                                    {(provided) => (
                                      <div
                                        className="form-group1"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        
                                        <label >
                                          {name}: 
                                        </label>
                                        {inputField} &emsp;
                                        <div className="delete-icon-container">
                                        
                                          <img width="20" height="20" marginRight="12px"  src={Editicon} id='edit_icon' alt="techIcon" title="Edit" 
                                          onClick={() => this.GetDataToEditCustomField(id)}/> &ensp;

                                          <img width="20" height="20" src={Deleteicon} id='delete_icon' alt="techIcon" title="Delete"  
                                          onClick={() => this.handleDeleteCustomField(id, name)}
                                          />
                                        
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                                
                          {val.map((data, i) => {
                        // Rendering input field based on selected type
                        // const { name, inputField, id } = this.renderInputField(data, i);
                        let inputField;
                        switch (data.type) {
                          case "integer":
                            inputField = <input type="number" 
                            style={{ width: '180px', height: '0.7cm' }}
                            onKeyDown={(e) => {
                              if (
                                !/[0-9]/.test(e.key) &&
                                !['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                            />;
                            break;
                          case "date":
                            inputField = <input type="date" 
                            style={{ width: '150px', height: '0.7cm' }}
                            />;
                            break;
                          case "time":
                              inputField = (
                                <input
                                  type="time"
                                  // value={new Date().toLocaleTimeString('it-IT', { hour12: false })}
                                  style={{ width: '150px', height: '0.7cm' }}
                                  // onChange={(e) => this.handleChange(e, i)}
                                />
                              );
                            break;
                          case "boolean":
                            inputField = <input type="checkbox" 
                            />;
                            break;
                          case "dropdown":
                            inputField = (
                              <select style={{ width: '180px', height: '0.7cm' }}>
                                <option value=" "> Select option </option>
                                {data.options && data.options.map((option, index) => <option key={index} value={option}>{option}</option>)}
                              </select>
                            );
                              break;
                          case 'checkbox':
                            inputField = (
                              <div>
                                {data.options && data.options.map((option, idx) => (
                                  <>
                                  <span key={idx}>
                                    <input type="checkbox" id={`option-${idx}`} />
                                      <label htmlFor={`option-${idx}`}>{option}</label>
                                  </span>&ensp;
                                  </>
                                ))}
                              </div>
                              );
                            break;   
                          default:
                            inputField = <input type="text" 
                            style={{ width: '350px', height: '0.7cm' }}
                            />;
                        }
                      
                        return (
                          <div className="form-group" style={{marginTop:"20px"}} key={i}>
                            <label>
                            {data.name}  : </label>
                            {inputField} &nbsp; 
                            
                            <button onClick={() => this.handleDelete(i)} style={{backgroundColor:'red', color:'#fff', cursor:'pointer'}}> X </button> &emsp;
                            <div>&ensp;
                            </div>
                          </div>
                          
                        );
                      })}<br/>
                      <button onClick={ this.saveCustomFieldOrder} style={{backgroundColor: "#75c69d", color:'#fff', size:'medium', cursor:'pointer'}}><b> Save Order </b></button>
                          </form>
            )
            :(
              customFieldArray.map((data, i) => {
                          // Rendering input field based on selected type
                          // const { name, inputField, id } = this.renderInputField(data, i);
                          let inputField;
                          switch (data.format) {
                            case "integer":
                              inputField = <input type="number" 
                              style={{ width: '180px', height: '0.7cm' }}
                              // value={data.value} 
                              // onChange={(e) => this.handleChange(e, i)} 
                              onKeyDown={(e) => {
                                if (
                                  !/[0-9]/.test(e.key) &&
                                  !['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              />;
                              break;
                            case "date":
                              inputField = <input type="date" 
                              style={{ width: '150px', height: '0.7cm' }}
                              // value={data.value} 
                              // onChange={(e) => this.handleChange(e, i)} 
                              />;
                              break;
                            case "time":
                                inputField = (
                                  <input
                                    type="time"
                                    // value={new Date().toLocaleTimeString('it-IT', { hour12: false })}
                                    style={{ width: '150px', height: '0.7cm' }}
                                    // onChange={(e) => this.handleChange(e, i)}
                                  />
                                );
                              break;
                            case "boolean":
                              inputField = <input type="checkbox" 
                              // checked={data.value} 
                              // onChange={(e) => this.handleChange(e, i)} 
                              />;
                              break;
                              case "dropdown":
                                inputField = (
                                  <div>
                                    <select style={{ width: '180px', height: '0.7cm' }} >
                                    <option value=" "> Select option </option>
                                      {data.options.map((option, index) => (
                                        <option key={index} value={option}>{option}</option>
                                      ))}
                                    </select>
                                    
                                  </div>
                                );
                                break;
                              case 'checkbox':
                                inputField = (
                                  <div>
                                    {data.options && data.options.map((option, idx) => (
                                      <>
                                      <span key={idx}>
                                        <label htmlFor={`option-${idx}`}>{option}</label>
                                        <input type="checkbox" id={`option-${idx}`} />
                                      </span> &ensp;
                                      </>
                                    ))}
                                  </div>
                                );
                                break;
                            default:
                              inputField = <input type="text" 
                              style={{ width: '350px', height: '0.7cm' }}
                              // value={data.value} 
                              // onChange={(e) => this.handleChange(e, i)}
                              />;
                          }
                        
                          return (
                            <div className="form-group" key={i}>
                              <label>
                              {data.name} : &emsp;</label>
                              {inputField} &nbsp;
                              <br /><br/>
                            
                            </div>
                            
                          );
                        })
                        )} 
                        <div>
                                                      
                        {val.map((data, i) => {
                        // Rendering input field based on selected type
                        // const { name, inputField, id } = this.renderInputField(data, i);
                        let inputField;
                        switch (data.type) {
                          case "integer":
                            inputField = <input type="number" 
                            style={{ width: '180px', height: '0.7cm' }}
                            // value={data.value} 
                            // onChange={(e) => this.handleChange(e, i)} 
                            onKeyDown={(e) => {
                              if (
                                !/[0-9]/.test(e.key) &&
                                !['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                            />;
                            break;
                          case "date":
                            inputField = <input type="date" 
                            style={{ width: '150px', height: '0.7cm' }}
                            // value={data.value} 
                            // onChange={(e) => this.handleChange(e, i)} 
                            />;
                            break;
                          case "time":
                              inputField = (
                                <input
                                  type="time"
                                  // value={new Date().toLocaleTimeString('it-IT', { hour12: false })}
                                  style={{ width: '150px', height: '0.7cm' }}
                                  // onChange={(e) => this.handleChange(e, i)}
                                />
                              );
                            break;
                          case "boolean":
                            inputField = <input type="checkbox" 
                            // checked={data.value} 
                            // onChange={(e) => this.handleChange(e, i)} 
                            />;
                            break;
                          case "dropdown":
                            inputField = (
                                <select style={{ width: '180px', height: '0.7cm' }}>
                                  <option value=" "> Select option </option>
                                  {data.options && data.options.map((option, index) => <option key={index} value={option}>{option}</option>)}
                                </select>
                              );
                            break;
                            case 'checkbox':
                              inputField = (
                                <div>
                                  {data.options && data.options.map((option, idx) => (
                                    <>
                                    <span key={idx}>
                                      <input type="checkbox" id={`option-${idx}`} />
                                      <label htmlFor={`option-${idx}`}>{option}</label>
                                    </span> &ensp;
                                    </>
                                  ))}
                                </div>
                              );
                              break;
                          default:
                            inputField = <input type="text" 
                            style={{ width: '350px', height: '0.7cm' }}
                            />;
                        }
                      
                        return (
                          <div className="form-group" style={{marginTop:"2px"}} key={i}>
                            <label>
                            {data.name}  : &emsp;</label>
                            {inputField} &ensp; 
                            
                            <button onClick={() => this.handleDelete(i)} style={{backgroundColor:'red', color:'#fff', border: 'none'}}> X </button> &emsp;
                            <div>&ensp;
                            </div>
                          </div>
                          
                        );
                      })}<br/>

                        </div>

              </div>
            </div>

            <div className="custom-bordered-div">
            {EditCustomFieldInfo ? (
              <form >
                <div className="form-group2">
                  <label>Tracker &nbsp;: &emsp;</label>
                      <input
                        type="text"
                        style={{width:'100px', height:'20px', fontSize:'14px', textAlign:'inherit'}}
                        value={this.state.Tracker}
                        readOnly
                      />
                    
                </div>
                <div className="form-group2">
                  <label>Format &nbsp;: &emsp;</label>
                    <select 
                    name="type"
                    style={{width:'105px', height:'20px', fontSize:'14px', textAlign:'inherit', marginTop:'2%'}}
                    value={this.state.EditType} 
                    onChange={(e) => this.setState({ EditType: e.target.value })}>
                      <option value="">Select Type</option>
                      <option value="integer">Number</option>
                      <option value="text">Text</option>
                      <option value="date">Date</option>
                      <option value="time">Time</option>
                      <option value="boolean">Checkbox</option>
                      <option value="dropdown">Selection</option>
                    </select>
                </div>
                <div className="form-group2">
                  <label>
                    Label&emsp;: &emsp;
                    <input
                      type="text"
                      name="name"
                      style={{width:'45%', height:'20px', fontSize:'14px', textAlign:'inherit', marginTop:'2%'}}
                      value={this.state.EditLabel}
                      // onChange={this.handleNameChange}
                      onChange={(evt) => this.setState({ EditLabel: evt.target.value })}
                    />
                  </label>
                </div>
                <div className="form-group2">
                <label>
                  Description &nbsp;: &ensp;</label>
                  <textarea
                            // type="text"
                            id="desc"
                            name="description"
                            // required
                            style={{ width: '280px', height: '120px', resize: 'none' }}
                            value={this.state.EditDesc}
                            onChange={(evt) => this.setState({ EditDesc: evt.target.value })}
                          />
                </div>
                <div className="form-group2">
                <label>Required?</label><input type="checkbox" checked={this.state.EditIsRequired} 
                  onChange={(evt) => this.setState({ EditIsRequired: evt.target.checked })}
                  // onChange={ this.handleCheckboxChange} 
                  />&ensp;
                </div>
                <div className="form-group2">
                {this.state.EditType === 'dropdown' && (
              <label>
                Options: &emsp;
                <textarea
                  style={{ width: '100%', height: '100px' }}
                  value={dropdownOptions}
                  onChange={(e) => this.setState({ dropdownOptions: e.target.value })}
                  placeholder="Enter options, each on a new line"
                />
              </label>
            )}

            {type === 'checkbox' && (
              <div className="form-group2">
                <label>
                  Options: &emsp;</label>
                  <textarea
                    style={{ width: '100%', height: '100px' }}
                    value={multipleChoiceOptions}
                    onChange={(e) => this.setState({ multipleChoiceOptions: e.target.value })}
                    placeholder="Enter options, each on a new line"
                  />
                
                </div>
              )}
              <br/>
                </div>
                {/* <button type="button" onClick={this.handleAdd} style={{backgroundColor: "#6071aa", color:'#fff', size:'medium', marginTop:'2%', cursor:'pointer'}}> Preview Field</button> <br/> */}
                <button onClick={ this.UpdateCustomField} style={{backgroundColor: "#75c69d", color:'#fff', size:'medium', cursor:'pointer', border: 'none'}}><b> Update Field </b></button>
      
              </form>
            ): (
              <form>
                <div className="form-group2">
                  <label>Tracker &nbsp;: &emsp;</label>
                      <input
                        type="text"
                        style={{width:'100px', height:'20px', fontSize:'14px'}}
                        value={selected_tracker}
                        readOnly
                      />
                    
                </div>
                
                <div className="form-group2">
                  <label>Format &nbsp;: &emsp;</label>
                    <select 
                    name="type"
                    style={{width:'120px', height:'20px', fontSize:'14px', marginTop:'2%'}}
                    value={type} 
                    onChange={(e) => this.setState({ type: e.target.value })}>
                      <option value="">Select Type</option>
                      <option value="integer">Number</option>
                      <option value="text">Text</option>
                      <option value="date">Date</option>
                      <option value="time">Time</option>
                      <option value="boolean">Checkbox</option>
                      <option value="dropdown">Drop-down List</option>
                      <option value="checkbox">Multiple Options</option>
                    </select>
                  
              </div>
              
              <div className="form-group2">
                <label>Label&emsp;: &emsp;</label>
                  <input
                    type="text"
                    name="name"
                    style={{width:'45%', height:'20px', fontSize:'14px', marginTop:'2%'}}
                    value={name}
                    onChange={this.handleNameChange}
                  />
                
              </div>
              
              <div className="form-group2">
              <label>Description &nbsp;: &ensp;</label>
                <textarea
                          // type="text"
                          id="desc"
                          name="description"
                          // required
                          style={{ width: '280px', height: '120px', resize: 'none', fontSize:'14px', marginTop:'2%' }}
                          value={description}
                          onChange={(evt) => this.setState({ description: evt.target.value })}
                        />
              </div>
              <div className="form-group2">
              <label>Required? &nbsp;: &ensp;</label>
              <input 
              type="checkbox" 
              checked={this.state.is_required} 
                // onChange={(evt) => this.setState({ is_required: evt.target.checked })}
                style={{ width: '17px', height: '17px', marginTop:'2%' }}
                onChange={ this.handleCheckboxChange} 
                />&ensp;
              </div>
              <div className="form-group2">
              {type === 'dropdown' && (
              <>
                <label>Options: &emsp;</label>
                  <textarea
                  style={{ width: '280px', height: '120px', resize: 'none', fontSize:'14px', marginTop:'2%' }}
                  value={dropdownOptions}
                  onChange={(e) => this.setState({ dropdownOptions: e.target.value })}
                  placeholder="Enter options, each on a new line" />
                </>
              
            )}
           </div>
              {type === 'checkbox' && (
              <div className="form-group2">
                <label>
                  Options: &emsp;</label>
                  <textarea
                    style={{ width: '280px', height: '120px', resize: 'none', fontSize:'14px', marginTop:'2%' }}
                    value={multipleChoiceOptions}
                    onChange={(e) => this.setState({ multipleChoiceOptions: e.target.value })}
                    placeholder="Enter options, each on a new line"
                  />
                
                </div>
              )}
              <br/>

              <button type="button" onClick={this.handleAdd} style={{backgroundColor: "#6071aa", color:'#fff', size:'medium', marginTop:'2%', cursor:'pointer', border: 'none'}}> Preview Field</button> <br/>
              <button onClick={ this.InsertCustomFields} style={{backgroundColor: "#75c69d", color:'#fff', size:'medium', cursor:'pointer', border: 'none'}}><b> Save Field </b></button>
              
              </form>
            )
          }
                  
            </div>

          </div>
          </div>
          <div id="copyright-footer" >
            <p style={{fontSize:'12px'}}>
            <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
            </p>
          </div>

      </div>
    );
  }
}

export default CustomeFields;
