import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { get } from '../../global_files/serverRequests';
import globalVariables from "../../global_files/globalVariables";
// import { render } from 'react-dom';
import { getFreshToken } from '../../global_files/auth';
import urls from '../../global_files/urls';



class overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employee_list:[],
      pending_ticket_count:0,
      openTicketCount:0,
      open_ticket_count:0,
      assigned_ticket_count:0,
      closed_ticket_count:0,
      inprogress_ticket_count:0,
      user_id: [],

      
    };
  }

  componentDidMount(){
    this.getemployeeList();
    this.validateSignedIn();
  }

  // componentWillUnmount() {
  //   // fix Warning: Can't perform a React state update on an unmounted component
  //   this.setState = () => {
  //       return;
  //   };
  // }

  validateSignedIn = async () => {
    let is_fresh_token = await getFreshToken()
    if (!is_fresh_token) {
        //this.props.history.replace("/sign_in");
        alert("Your session has expired, please login again!!");
        window.location.replace('/login')
        return
    }
}

  getemployeeList= async ()=>{
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)
    console.log("----------access_tocken in employee list----------",access_tocken);
    console.log("----------dealer_uuid in employee list----------",dealer_uuid);
    let headers = {
      // 'content-type': 'application/json'
      'content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${access_tocken}`,
  }
  let data = await get(`${urls.BASE_URL}/api/account/${dealer_uuid}/employee_list/`, headers)
    // console.log("----------------------------------",data);
    // if (data !== false) {
      data = data.results
        console.log("overview ----------data in employee list----------",data);
        let user_Id = [];
        if (data && data.length > 0) {
          user_Id = data.map(employee => employee.user_id);
          console.log("*******************************user_Id****",user_Id)
          this.setState({
            employee_list: data,
            user_id:user_Id,
          });
          
          console.log("ticket info array : ",this.state.employee_list);
          // if (this.state.user_id && Array.isArray(this.state.user_id)) {
          //   this.state.user_id.forEach(user_id => this.getTicketCount(user_id));
          // }
          this.getTicketCount();        
        }
      }


getTicketCount = async () => {
  try {
    let access_token = localStorage.getItem(globalVariables.AUTH_TOKEN);
    // let user_id = localStorage.getItem(globalVariables.SELECTED_TECH_ID)
    let elevator_id = localStorage.getItem(globalVariables.SELECTED_ELEVATOR_id)
    let headers = {
      'content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${access_token}`,
    };

    let data = await get(`${urls.BASE_URL}/api/tickets/ticket-list-count-elevator/${elevator_id}/`, headers);
                          // https://semapp.in/api/tickets/ticket-list-count-elevator/9/
    // let data = await get(`${urls.BASE_URL}/api/tickets/ticket-list-count-employee/${user_id}/`, headers);
    
    console.log("----------data in getOpenTicketCount list----------", data);

    if (data && (data.open !== undefined || data.closed !== undefined || data.onhold !== undefined)) {
      this.setState({
        open_ticket_count : data['open'],
        pending_ticket_count : data['onhold'],
        closed_ticket_count : data['closed'],
        assigned_ticket_count : data['accepted'], //inprogress
        inprogress_ticket_count : data['inprogress'] 
      });

    }

  } catch (error) {
    console.error('Error fetching open ticket count:', error);
    
  }
};

render(){

  const { employee_list } = this.state;
  // const { closeCount, openCount, onHoldCount } = this.state;
  // const { openTicketCount } = this.state;
  // const user_id = localStorage.getItem(globalVariables.selectedTechnician);
  // let Elevator_name = localStorage.getItem(globalVariables.SELECTED_ELEVATOR)
  // let tech_name = localStorage.getItem(globalVariables.SELECTED_TECH_NAME);

  // Check ---> employee_list is not empty before rendering
  if (!employee_list || employee_list.length === 0) {
    return <div>Loading...</div>;
  }

  // Map and join the values into a comma-separated string
  const sem_employees = employee_list.map((item) => item.username).join(', ');

  return (
    <div>
      <div>
          <h3 style={{fontFamily:'revert-layer',marginLeft:'45px'}}> Overview</h3> 
      </div>
      <div style={{ display: 'flex'}}>
      <span className="issue_tracking">
          <h4 style={{marginLeft:'5px'}}> Issue Tracking </h4>
              <table>
                    <thead>
                      <tr>
                          <th></th>
                          <th>Open</th>
                          <th>OnHold</th>
                          <th>Inprogress</th>
                          <th>Accepted</th>
                          <th>Closed</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                            <td> Tickets</td>
                            <td>{this.state.open_ticket_count}</td>
                            <td>{ this.state.pending_ticket_count}</td>
                            <td>{this.state.inprogress_ticket_count}</td>
                            <td>{this.state.assigned_ticket_count}</td>
                            <td>{ this.state.closed_ticket_count}</td>
                              
                      </tr> 
                    </tbody>
              </table>
              <br/>
              <Link to="/elevators_all_tickets" style={{ color: 'darkblue' }}>
              <p className="hover-effect"> View all issues</p> 
              </Link>  
      </span>
      <span className="employee_tracking">
          <h4><b>Employees</b> : </h4> <p style={{fontSize:'14px'}}>{sem_employees}</p>
          
      </span>
   </div><br/>
   <div id="copyright-footer">
            <p style={{fontSize:'12px'}}>
            <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
            </p>
          </div>
   </div>
   
  );
}
}
export default overview;
