// import axios from "axios";
import React, { Component } from 'react';
// import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { post, get } from '../../global_files/serverRequests';
import globalVariables from "../../global_files/globalVariables";
// import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';
// import CregImg from '../assets/newRegist.jpg'
// import "../Style/Admin.css"
import '../adminDash.css';
import GlobalTabpanel from './Tab_panel_global';
import { getFreshToken } from '../../global_files/auth';
import urls from '../../global_files/urls';
import ActivityTabs from "./ActivityTabs";


class AddElevator extends Component {

  constructor(props) {
    super(props);
    this.state = {

      matches: [],
      content: '',
     

      user_id: '',
      ele_name: '',
      building_name:'',
      ele_addr:'',
      zip_code:'',
      install_date:'',
      main_due_date:'',
      main_start_date:'',
      main_completed_date:'',
      main_cycle_days:'30',
      client_id:'',
      cust_email:'',

      customer_list:[],


      
    };
  }

  componentDidMount(){
    this.validateSignedIn();
    this.getCustomerList();
  }


  validateSignedIn = async () => {
    let is_fresh_token = await getFreshToken()
    if (!is_fresh_token) {
        //this.props.history.replace("/sign_in");
        alert("Your session has expired, please login again!!");
        window.location.replace('/login')
        return
    }
} 


jsonData = () => {
  let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)
  let data = {
    "user_id": [dealer_uuid],
    "elevator_name": this.state.ele_name,
    "building_name": this.state.building_name,
    "elevator_address": this.state.ele_addr,
    "elevator_zip": this.state.zip_code,
    "install_date": this.state.install_date,
    // "lat":"9°02′11.11″ North",
    // "long":"72°51′34.09″ East",
    "main_due_date": this.state.main_due_date,
    "main_start_date": this.state.main_start_date,
    // "main_completed_date" : this.state.main_completed_date,
    "main_cycle_days": this.state.main_cycle_days,
              
  }
  if (this.state.main_completed_date) {
    data.main_completed_date = this.state.main_completed_date;
  }
  console.log("-----JSON DATA -------->",data)
  return JSON.stringify(data)
}
  
  AddElevatorInfo = async () => {
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
          console.log("----------access_tocken in issue list----------",access_tocken)
          let headers = {
            // 'content-type': 'application/json'
            'content-Type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${access_tocken}`,
          }
    
    let post_data = this.jsonData()

    console.log("post_data in AddElevatorInfo---------->",post_data)

    // Parse the JSON string into an object
  let postDataObj = JSON.parse(post_data);
  // let main_completed_date = this.state.main_completed_date

  for (const key in postDataObj) {
    if (key !== 'main_completed_date' && ( postDataObj[key] === null || postDataObj[key] === undefined || postDataObj[key] === "")) {
      alert("Please fill in all the required fields.");
      return;
    }
  }
  try {
    let response = await post(`${urls.BASE_URL}/api/elevators/add/`, post_data, headers)
    
    // let password = this.state.cust_password
    console.log("============= successful  AddElevatorInfo  =======", response);
    
      alert("Elevator added successfully !");
      window.location.replace("/elevators");
    
  } catch (error) {
    console.error("Error in AddElevatorInfo :", error);
    alert("An error occurred while adding new elevator Info. Please try again later.");
  }
}



  handleDateChange = (event, dateKey) => {
    const selectedDate = event.target.value;
    const formattedDate = this.formatDate(selectedDate);

    this.setState({
      [dateKey]: formattedDate,
    });
  };

  formatDate = (inputDate) => {
    // Parse the input date assuming it's in "dd/mm/yyyy" format
    const [day, month, year] = inputDate.split('/');
    // Create a new Date object using the parsed values
    const parsedDate = new Date(`${year}-${month}-${day}`);
    // Format the date in "yyyy-MM-dd" format
    const formattedDate = parsedDate.toISOString().split('T')[0];
    return formattedDate;
  };

  getCustomerList= async ()=>{
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)
    localStorage.setItem(globalVariables.USER_ID,dealer_uuid)
    console.log("----------access_tocken in customer list----------",access_tocken);
    console.log("----------dealer_uuid in customer list----------",dealer_uuid);
    let headers = {
      // 'content-type': 'application/json'
      'content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${access_tocken}`,
  }
  let response = await get(`${urls.BASE_URL}/api/users/${dealer_uuid}/user_customer_list/`, headers) //'/users/${dealer_id}/user_customer_list/'
    // console.log("----------------------------------",data);
    // if (data !== false) {
      let data = response.results
        console.log("----------data in customer list----------",data);
        let userr_Id = [];
        if (data && data.length > 0) {

          this.setState({
            customer_list: data,
          });
          
          }
          console.log("============ customer array : ",this.state.customer_list);
                    
        // }
      }
  
  render(){
    const { customer_list } = this.state;
    return (
    <div>
        <ActivityTabs/>
                {/* <div style={{ display: 'flex', backgroundColor:"#664fa7", color:"white", paddingTop:'2px' }}> */}
                <div className="sem-header-container">
                    <span><h3 style={{height:"30px", paddingLeft:"15px", marginTop:"1px", paddingTop:"5px"}}> Smart Elevator Maintenance &emsp; &gt;&gt; <Link id="navigation" to="/elevators">Elevators</Link> &gt;&gt;&nbsp;
                    <Link id="navigation" >Add Elevators</Link> 
                    </h3></span>
                    <span style={{ marginLeft: 'auto', marginRight:'5px',marginTop:"9px" }}>
                        <input
                            type="text"
                            id='search-input'
                            size="small"
                            placeholder=" search "
                        />
                    </span>
                </div>
        <GlobalTabpanel/>
            <div className="bordered-div" >
            <div>
                  <label htmlFor="ele_name" style={{marginLeft : '95px'}}> Elevator Name &emsp;&emsp;&emsp;&emsp;&emsp;: &nbsp;&emsp;&nbsp;&nbsp;</label>
                    <input
                      type="text"
                      id="ele_name"
                      name="ele_name"
                    //   readOnly
                      style={{ width: '250px', height: '0.7cm' }}
                      value={this.state.ele_name}
                      onChange={(evt) => this.setState({ ele_name: evt.target.value })}
                    />
               </div>
               <br/>
               <div style={{ marginLeft:'30px'}}>
                  <label htmlFor="building_name" style={{marginLeft : '65px', marginRight:'30px'}}>Building Name <span style={{color:'red'}}>*</span>&ensp;&emsp;&emsp;&emsp;&emsp;: </label>
                  <input
                      type="text"
                      id="building_name"
                      name="building_name"
                      required
                      style={{ width: '250px', height: '0.7cm' }}
                      value={this.state.building_name}
                      onChange={(evt) => this.setState({ building_name: evt.target.value })}
                    />
               </div>
               <br/>
               <div>
                  <label htmlFor="ele_address" style={{marginLeft : '95px'}}> Elevator address <span style={{color:'red'}}>*</span>&ensp;&emsp;&emsp;&emsp; : &nbsp;&emsp;&ensp;</label>
                    <input
                      type="text"
                      id="elev_address"
                      name="elev_address"
                      required
                      style={{ width: '250px', height: '0.7cm' }}
                      value={this.state.ele_addr}
                      onChange={(evt) => this.setState({ ele_addr: evt.target.value })}
                    />
               </div>
               <br/>
               <div>
                  <label htmlFor="zip_code" style={{marginLeft : '95px'}}> Zip Code <span style={{color:'red'}}>*</span>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp; : &emsp;&ensp;&nbsp;</label>
                    <input
                      type="text"
                      id="zip_code"
                      name="zip_code"
                      required
                      style={{ width: '150px', height: '0.7cm' }}
                      
                      value={this.state.zip_code}
                      onChange={(evt) => this.setState({ zip_code: evt.target.value })}
                    />
               </div>
               <br/>
               <div>
                  <label htmlFor="cust_name" style={{marginLeft : '95px'}}> Customer Name <span style={{color:'red'}}>*</span> &emsp;&emsp;&emsp;&emsp;: &emsp;&ensp;&nbsp;</label>
                  <select
                    id="cust_name"
                    name="cust_name"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.user_id}
                    onChange={(evt) => this.setState({ user_id: evt.target.value })}
                  >
                    <option > ----- </option>
                    {customer_list.map((customer, index) => (
                      <option key={index} value={customer.email}>
                        {customer.username}
                      </option>
                    ))}
                    
                    
                    
                  </select>
               </div>
               <br/>
               <div>
               <label htmlFor="installtion_date" style={{marginLeft : '95px'}} >Installation Date &emsp;&emsp;&emsp;&emsp;&nbsp; : &emsp;&ensp;&nbsp;</label>
                  <input 
                  type="date" 
                  id="install_date" 
                  name="install_date" 
                  style={{ width: '150px', height: '0.7cm' }}
                  value={this.state.install_date}
                  onChange={(evt) => this.setState({ install_date: evt.target.value })}
                  ></input>
               </div>
               <br/>
               <div>
                  <label htmlFor="main_start_date" style={{marginLeft : '95px'}}> Maintenance Start Date <span style={{color:'red'}}>*</span> &nbsp;&nbsp;: &nbsp;&emsp;&ensp;</label>
                  <input 
                  type="date" 
                  id="main_start_date" 
                  name="main_start_date" 
                  style={{ width: '150px', height: '0.7cm' }}
                  value={this.state.main_start_date}
                  onChange={(evt) => this.setState({ main_start_date: evt.target.value })}
                  ></input>
               </div>
               <br/>
               <div>
                  <label htmlFor="main_due_date" style={{marginLeft : '95px'}}> Maintenance Due Date <span style={{color:'red'}}>*</span> &nbsp;&nbsp;&nbsp; : &nbsp;&emsp;&ensp;</label>
                  <input
                      type="date"
                      id="main_due_date"
                      name="main_due_date"
                      required
                      style={{ width: '150px', height: '0.7cm' }}
                      value={this.state.main_due_date}
                      onChange={(evt) => this.setState({ main_due_date: evt.target.value })}
                    />
               </div>
               <br/>
               <div>
                  <label htmlFor="main_completed_date" style={{marginLeft : '95px'}}> Maintenance Completed Date <span style={{color:'red'}}>*</span> &nbsp;&nbsp;&nbsp; : &nbsp;&emsp;&ensp;</label>
                  <input
                      type="date"
                      id="main_completed_date"
                      name="main_completed_date"
                      required
                      style={{ width: '150px', height: '0.7cm' }}
                      value={this.state.main_completed_date}
                      onChange={(evt) => this.setState({ main_completed_date: evt.target.value })}
                    />
               </div>
               <br/>
               <div>
                  <label htmlFor="main_cycle_days" style={{marginLeft : '95px'}}> Duration Cycle <span style={{color:'red'}}>*</span> &emsp;&emsp;&emsp;&emsp;&nbsp; : &emsp;&ensp;&nbsp;</label>
                  <select
                    id="main_cycle_days"
                    name="main_cycle_days"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.main_cycle_days}
                    onChange={(evt) => this.setState({main_cycle_days: evt.target.value })}
                  >
                    <option value="30"> Monthly </option>
                    <option value="90"> Quarterly </option>  
                  </select>
               </div>
               <br/>
               
            </div>
         
         <div style={{ width: '130px', height: '0.5cm', marginLeft:'170px', marginTop:'8px', marginBottom: '100px'  }}>
          
          <button style={{backgroundColor:'#664fa7', color:'white', cursor:'pointer'}} 
          onClick={this.AddElevatorInfo}
          > Add Elevator</button>
          
         </div>
           <div id="copyright-footer">
            <p style={{fontSize:'12px'}}>
            <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
            </p>
          </div>
    </div>
    
    
    
  )
}
}


export default AddElevator;