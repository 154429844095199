import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AppBar,  Tabs, Tab, Toolbar,Typography, Box  } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { getFreshToken } from '../../global_files/auth';


function TabPanel (props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={5} className='p-0'>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        // value: PropTypes.any.isRequired,
    };

    const useStyles = theme => ({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        tabs: {
            flexGrow: 1,
            alignSelf: 'flex-end'
        },
    });


class GlobalTabpanel extends Component {
    constructor(props) {
        super(props);
        this.state = {

          ticketInfo:[],
        //   matches: [],
          content: '',
          tab_value: 0,
          pages: {},
          anchorEl: null,
          is_show_password: false,
        };
      }
      
    componentDidMount(){
        this.validateSignedIn();
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = () => {
            return;
        };
      }
      
    validateSignedIn = async () => {
        let is_fresh_token = await getFreshToken()
        if (!is_fresh_token) {
            //this.props.history.replace("/sign_in");
            alert("Your session has expired, please login again!!");
            window.location.replace('/login')
            return
        }
    }

    handleTabs = (evt, tab_value) => {
        this.setState({ tab_value: tab_value })
    }

    
    render() {
   
        return (
            <div className='vh-100 scroll_hidden'>
                
                <AppBar position="sticky" color='default'>
                    <Toolbar className='bg-white pr-0 pl-1'>
                        {/* <img src={client_logo} style={{ height: '2.5rem' }} /> */}
                         
                                    <Tabs
                                        className={this.props.classes.tabs}
                                        style={{ alignSelf: 'flex-end' }}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="scrollable"
                                        scrollButtons="on"
                                        value={this.state.tab_value}
                                        onChange={this.handleTabs} >
                                        {/* <Tab component="div" label={<span style={{ fontWeight: 'bold' }}>OVERVIEW</span>} /> */}
                                        <Tab component="div" label={<span style={{ fontWeight: 'bold' }}> </span>} />
                                    </Tabs>

                    </Toolbar>
                </AppBar>

            </div>
        )
    }
}

export default withStyles(useStyles)(GlobalTabpanel)

