import React, { Component } from 'react';
// import { render } from 'react-dom';
import { signOut, getFreshToken } from '../../global_files/auth';
import { Link } from 'react-router-dom';
import globalVariables from "../../global_files/globalVariables";
import ActivityTabs from "./ActivityTabs";


class CustomFieldsFor extends Component {
    constructor(props) {    // Constructor method for initializing state and props
        super(props);         // Calling the constructor of the parent class (Component)
        this.state = {        // Initializing the component's state
          
          checkedOption: "",
        };
      }
    
      componentDidMount(){
        this.validateSignedIn();
       }
    
       validateSignedIn = async () => {
        let is_fresh_token = await getFreshToken()
        if (!is_fresh_token) {
            //this.props.history.replace("/sign_in");
            alert("Your session has expired, please login again!!");
            window.location.replace('/login')
            return
        }
      }

      handleRadioChange =  (event) => {
        const value = event.target.value;
        this.setState({ checkedOption: value },()=>{
        console.log("selected option for custom field", this.state.checkedOption );
        });   
    }

    SubmitCustomFieldsFor = () => {
        // let data = {
        //     "Custom_field_checked_Option" : this.state.checkedOption
        // }
        localStorage.setItem(globalVariables.SELECTED_TRACKER, this.state.checkedOption);
        // let post_data = JSON.stringify(data);
        console.log("selected option for custom field", this.state.checkedOption );
        // console.log("selected option ========== post_data = ", post_data );
        window.location.replace('/custom_fields');
    }

render(){

  return (
    <div>
        <ActivityTabs/>
                {/* <div style={{ display: 'flex', backgroundColor:"#664fa7", color:"white", paddingTop:'2px' }}> */}
                <div className="sem-header-container">
                    <span><h3 style={{height:"30px", paddingLeft:"15px", marginTop:"1px", paddingTop:"5px"}}> Smart Elevator Maintenance &emsp; &gt;&gt;
                    {/* <Link id="navigation" to="/custom_fields_list"> Created Custom Fields </Link> &gt;&gt; */}
                     Custom Fields For &gt;&gt;
                    {/* <Link id="navigation" to="/custom_fields"> Create Custom fields </Link>  */}
                    </h3></span>
                    <span style={{ marginLeft: 'auto', marginRight:'5px',marginTop:"9px" }}>
                        <input
                            type="text"
                            id='search-input'
                            size="small"
                            placeholder=" search "
                        />
                    </span>
                </div>
    <div className="customFor-bordered-div">
    <div>
       
            {/* <div>
                <h4>Add custom Fields for:</h4>
                <label> both Ticket section and Maintenance section : </label>
                <input 
                type="radio"
                name="customFields" 
                value="both"
                checked={this.state.checkedOption === "both"}
                onChange={this.handleRadioChange}
                />
            </div> */}

            <div>
                <label> Ticket section  : &emsp;&emsp;&nbsp;</label>
                <input 
                type="radio"
                name="customFields" 
                value="ticket"
                checked={this.state.checkedOption === "ticket"}
                onChange={this.handleRadioChange}
                />
            </div>
            <br/>
            <div>
                <label> AMC report section: </label>
                <input 
                type="radio"
                name="customFields" 
                value="report"
                checked={this.state.checkedOption === "report"}
                onChange={this.handleRadioChange}
                />
            </div>

            {/* <div>
                <label> Specific Ticket only : </label>
                <input 
                type="radio"
                name="customFields" 
                value="specificTicket"
                checked={this.state.checkedOption === "specificTicket"}
                onChange={this.handleRadioChange}
                />
            </div> */}

            {/* <div>
                <label> Specific AMC report only : </label>
                <input 
                type="radio"
                name="customFields" 
                value="specificAMC"
                checked={this.state.checkedOption === "specificAMC"}
                onChange={this.handleRadioChange}
                />
            </div> */}
            <br/>
            <div>
                <button style={{backgroundColor:'#664fa7', color:'white'}} onClick={this.SubmitCustomFieldsFor}> Submit</button>
            </div>
    </div>
  </div> 
        <div id="copyright-footer">
            <p style={{fontSize:'12px'}}>
            <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
            </p>
        </div>
  </div>
  );
}
}
export default CustomFieldsFor;