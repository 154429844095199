import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AppBar,  Tabs, Tab, Toolbar,Typography, Box  } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// import { PowerSettingsNew } from '@material-ui/icons';
// import { get } from '../../global_files/serverRequests';
import { Link } from 'react-router-dom';
import globalVariables from "../../global_files/globalVariables";
import Overview from './overview';
import Issue from './Issues';
import { getFreshToken } from '../../global_files/auth';
import ActivityTabs from "./ActivityTabs";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={5} className='p-0'>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    const useStyles = theme => ({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        tabs: {
            flexGrow: 1,
            alignSelf: 'flex-end'
        },
    });


class admin_dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
          searchInput: '',
          ticketInfo:[],
          content: '',
          tab_value: 0,

          anchorEl: null,
          is_show_password: false,
        };
      }
      
    
    handleTabs = (evt, tab_value) => {
        this.setState({ tab_value: tab_value })
    }

    componentDidMount(){
        this.validateSignedIn();
      }

 
    validateSignedIn = async () => {
        let is_fresh_token = await getFreshToken()
        if (!is_fresh_token) {
            alert("Your session has expired, please login again!!");
            window.location.replace('/login')
            return
        }
    }


    render() {
        
        let Elevator_name = localStorage.getItem(globalVariables.SELECTED_ELEVATOR)
           
        return (
            
            <div className='vh-100 scroll_hidden'>
                               
                <ActivityTabs/>
                {/* <div style={{ display: 'flex', backgroundColor:"#664fa7", color:"white", paddingTop:'2px' }}> */}
                <div className="sem-header-container">
                    <span><h3 style={{height:"30px", paddingLeft:"15px", marginTop:"1px", paddingTop:"5px"}}> Smart Elevator Maintenance &emsp; &gt;&gt; <Link id="navigation" to="/elevators">Elevators</Link> &gt;&gt; <em>{Elevator_name}</em>
                    </h3></span>
                    <span style={{ marginLeft: 'auto', marginRight:'10px', marginTop:"9px" }}>
                        <input
                            type="text"
                            id='search-input'
                            size="small"
                            // value={this.state.searchTerm}
                            // onChange={this.handleSearchInputChange}
                            placeholder=" search "
                        />
                        
                    </span>
                </div>
                <AppBar position="sticky" color='default'>
                    <Toolbar className='bg-white pr-0 pl-1'>
                        {/* <img src={client_logo} style={{ height: '2.5rem' }} /> */}
                         
                                    <Tabs
                                        className={this.props.classes.tabs}
                                        style={{ alignSelf: 'flex-end' }}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="scrollable"
                                        scrollButtons="on"
                                        value={this.state.tab_value}
                                        onChange={this.handleTabs} >
                                        <Tab component="div" label={<span style={{ fontWeight: 'bold' }}>OVERVIEW</span>} />
                                        <Tab component="div" label={<span style={{ fontWeight: 'bold' }}>ISSUES</span>} />
                                    </Tabs>

                    </Toolbar>
                </AppBar>
                <div>
                    <TabPanel value={this.state.tab_value} index={0}>
                        <Overview />
                    </TabPanel>

                    <TabPanel value={this.state.tab_value} index={1}>
                        <Issue />
                    </TabPanel>
                    
                </div>
            </div>
        );
    }
}

export default withStyles(useStyles)(admin_dashboard)

