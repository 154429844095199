import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AppBar,  Tabs, Tab, Toolbar,Typography, Box  } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import globalVariables from "../../global_files/globalVariables";
import Issue from './Issues';
import { signOut, getFreshToken } from '../../global_files/auth';

function TabComponent(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={5} className='p-0'>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabComponent.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    const useStyles = theme => ({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        tabs: {
            flexGrow: 1,
            alignSelf: 'flex-end'
        },
    });


class TabComponents extends Component {
    constructor(props) {
        super(props);
        this.state = {
          searchTerm: '',
          ticketInfo:[],
        //   matches: [],
          content: '',
          tab_value: 0,
          pages: {},
          anchorEl: null,
          is_show_password: false,
        };
      }
      
    componentDidMount(){
        this.validateSignedIn();
      }

    handleTabs = (evt, tab_value) => {
        this.setState({ tab_value: tab_value })
    }

    // componentWillUnmount() {
    //     // fix Warning: Can't perform a React state update on an unmounted component
    //     this.setState = () => {
    //         return;
    //     };
    //   }
    validateSignedIn = async () => {
        let is_fresh_token = await getFreshToken()
        if (!is_fresh_token) {
            //this.props.history.replace("/sign_in");
            alert("Your session has expired, please login again!!");
            window.location.replace('/login')
            return
        }
    }

    // handleSearchChange = (e) => {
    //     this.setState({ searchTerm: e.target.value });
    // };

    // handleSearch=()=>{
    //     // Filter elevators based on the search input
    //     const filteredTicketInfo = this.state.ticketInfo.filter((ticket) =>
    //     ticket.ticket_key.toLowerCase().includes(this.state.searchTerm.toLowerCase())||
    //     ticket.status.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
    //     ticket.priority.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
    //     ticket.label.toLowerCase().includes(this.state.searchTerm.toLowerCase())
    //     );
    //     this.setState({ filteredTicketInfo });
    //     }

    signOut = () => {
        
        window.location.replace('/login')
    }

   
    render() {

        let Elevator_name = localStorage.getItem(globalVariables.SELECTED_ELEVATOR);
        let user_name = localStorage.getItem(globalVariables.USER_NAME);
           
        return (
            <div className='vh-100 scroll_hidden'>
                <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor:"#321e6e", color:"white", paddingTop:'2px', paddingLeft:"17px" }}>
                    <span>
                    <Link to ="/amc_count" className="link-no-underline"> Home</Link>&emsp;
                    <Link to ="/all_tech_issues" className="link-no-underline"> All Issues </Link>&emsp;
                    <Link to ="/technicians" className="link-no-underline"> Technicians</Link>&emsp;
                    <Link to ="/customers" className="link-no-underline"> Customers</Link>&emsp;
                    <Link to ="/elevators" className="link-no-underline"> Elevators</Link>&emsp;
                    <Link to ="/custom_fields_for" className="link-no-underline"> Custom Fields</Link>&emsp; 
                    {/* <Link to ="/reports" className="link-no-underline"> Reports </Link> */}
                    <Link to ="/import" className="link-no-underline"> Settings </Link>&emsp;
                    </span>
                    <span className="user-name">
                        Logged in as : {user_name}&ensp;
                        <button id='logout'onClick={() => signOut()}><b>Logout</b></button>
                    </span>
                </div>
                {/* <div style={{ display: 'flex', backgroundColor:"#664fa7", color:"white", paddingTop:'2px' }}> */}
                <div className="sem-header-container">
                    <span><h3 style={{height:"30px", paddingLeft:"15px", marginTop:"1px", paddingTop:"5px"}}> Smart Elevator Maintenance &emsp; &gt;&gt; 
                    {/* <Link id="navigation" to="/technicians">Technicians</Link> &gt;&gt; 
                    {tech_name} &gt;&gt; */}
                    <Link id="navigation" to="/elevators">Elevators</Link> &gt;&gt; 
                    {Elevator_name} &gt;&gt; <Link id="navigation" to="/admin_dashboard">Overview and Issues</Link>
                    </h3></span>
                    <span style={{ marginLeft: 'auto', marginRight:'10px', marginTop:"9px" }}>
                        <input
                            type="text"
                            id='search-input'
                            size="small"
                            // value={this.state.searchTerm}
                            // onChange={this.handleSearchChange}
                            placeholder=" search "
                        />
                        {/* <button id='search' onClick={this.handleSearch} >Search</button> */}
                    </span>
                </div>
                <AppBar position="sticky" color='default'>
                    <Toolbar className='bg-white pr-0 pl-1'>
                        {/* <img src={client_logo} style={{ height: '2.5rem' }} /> */}
                         
                                    <Tabs
                                        className={this.props.classes.tabs}
                                        style={{ alignSelf: 'flex-end' }}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="scrollable"
                                        scrollButtons="on"
                                        value={this.state.tab_value}
                                        onChange={this.handleTabs} >
                                        {/* <Tab component="div" label={<span style={{ fontWeight: 'bold' }}>OVERVIEW</span>} /> */}
                                        {/* <Tab component="div" label={<span style={{ fontWeight: 'bold' }}>ACTIVITY</span>} /> */}
                                        <Tab component="div" label={<span style={{ fontWeight: 'bold' }}>ISSUES</span>} />
                                    </Tabs>

                    </Toolbar>
                </AppBar>
                <div>
                    {/* <TabPanel value={this.state.tab_value} index={0}>
                        <Overview />
                    </TabPanel> */}
                    {/* <TabPanel value={this.state.tab_value} index={1}>
                        <Activities />
                    </TabPanel> */}
                    <TabComponent index={0}>
                        <Issue />
                    </TabComponent>
                    
                </div>
            </div>
        )
    }
}

export default withStyles(useStyles)(TabComponents)

