import React, { Component } from 'react';
import globalVariables from "../../global_files/globalVariables";
import { Button, TableRow, TableCell, FormControlLabel, Switch, TextField } from '@material-ui/core';
import ActivityTabs from "./ActivityTabs";
import { get} from '../../global_files/serverRequests';
import urls from '../../global_files/urls';
import { getFreshToken } from '../../global_files/auth';



class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_otp_enable:'',
            searchInput: '',
            newVersion:'v 1.0.1',
        };
      }



      async componentDidMount() {
        const isSignedIn = await this.validateSignedIn();
        if (isSignedIn) {
            this.load_otp_status();
        }
      }
    
    validateSignedIn = async () => {
      let is_fresh_token = await getFreshToken()
      console.log("-------is_fresh_token----",is_fresh_token);

      if (!is_fresh_token) {
        alert("Your session has expired, please login again!!");
        window.location.replace('/login')
        return false;
      }
      return true;
    }

      changeOtpStatus = async () => {
        let data = {
            'send_otp': this.state.is_otp_enable
        } 

        data = JSON.stringify(data)

        console.log("data in chnge OTP status ",data)
        let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
        // let post_data = await post(`${urls.BASE_URL}/api/user-preferences/update_user_pref/`,data)
        const post_data = await fetch(`${urls.BASE_URL}/api/user-preferences/update_user_pref/`, {
            method: 'PATCH',
            headers: {
              // 'content-Type': 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${access_tocken}`,
            },
            body: data
        });
        console.log("response",post_data)

        if (post_data !== false) {
          console.log("response",post_data)
          alert("OTP status saved successfully!")
        }
        else {
            alert('Something went wrong. Please try again !!!')
        }
    }

    load_otp_status = async () => {
        let response = await get(`${urls.BASE_URL}/api/user-preferences/get_user_pref/`)
        console.log(" --------- load_otp_status response-------",response);

        if (response !== false) {
          console.log("load_otp_status in if---",response['send_otp']);
        //   console.log(response['send_otp'].toLowerCase());
        if(response['send_otp']==true)
        {
            console.log("load_otp_status in if2---",response['send_otp']);
            console.log("yes true")
            this.setState({ is_otp_enable: true })
    
        }else{
            console.log("no false")
            this.setState({ is_otp_enable: false })
        }
         
        }
        else {
            alert('Something went wrong. Please try again !!!')
        }
    }


render(){
  const { searchInput } = this.state;
  return (
    <div>
        <ActivityTabs/>
        {/* <div style={{ display: 'flex', backgroundColor:"#664fa7", color:"white", paddingTop:'2px' }}> */}
        <div className="sem-header-container">
                    <span><h3 style={{height:"30px", paddingLeft:"15px", marginTop:"1px", paddingTop:"5px"}}> Smart Elevator Maintenance &emsp; &gt;&gt; Settings </h3></span>
                    <span style={{ marginLeft: 'auto', marginRight:'10px', marginTop:'9px' }}>
                    <input
                        type="text"
                        id='search-input'
                        size="small"
                        value={searchInput}
                        onChange={this.handleSearchInputChange}
                        placeholder=" search "
                    />
                    {/* <button id='search'> Search</button> */}
                    
                    </span>
                    
        </div>
        <div style={{ display: 'flex', marginTop:'2%', marginLeft:'3%' }}>
          
          <TableRow style={{ display: 'flex' }}>
            <TableCell align='right'>
                App Version : 
            </TableCell>
            <TableCell style={{ borderBottom: "none" }}>
              
                  <TextField
                    value={this.state.newVersion}
                    variant="outlined"
                    color="primary"
                    size='small'
                    style={{width:'3.5cm'}}
                    />
                    
            </TableCell>
          </TableRow>
          </div><br/>
          <div style={{ display: 'flex', marginLeft:'3%' }}>
            <TableRow>
              <TableCell align='right' style={{ borderBottom: "none" }}>
                Notify OTP  : 
              </TableCell>
              <TableCell style={{ borderBottom: "none" }}>
               
                    <Switch
                      checked={this.state.is_otp_enable}
                      onChange={(evt) => this.setState({ is_otp_enable: !this.state.is_otp_enable })}
                      name="checkedB"
                      color="primary"
                      size='small'
                      />&emsp;&emsp;&ensp;
                  
                                        
                 

                    <Button
                      variant="contained"
                      size="small"
                      color='primary'
                      className="mt-2"
                      onClick={this.changeOtpStatus}>
                               
                      <b> save </b>
                    </Button>
                  </TableCell>
            </TableRow>
            </div>  
         
    <div id="copyright-footer">
            <p style={{fontSize:'12px'}}>
            <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
            </p>
          </div>
 </div>
  );
}
}

export default Settings;