import React, { Component } from 'react';
// import { render } from 'react-dom';
import '../adminDash.css';
import { Link } from 'react-router-dom';
import { get } from '../../global_files/serverRequests';
import globalVariables from "../../global_files/globalVariables";
import { getFreshToken } from '../../global_files/auth';
import urls from '../../global_files/urls';
import ActivityTabs from "./ActivityTabs";


class AllOnholdTickets extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
      ticketInfo:[],
      employee_list:[],

      currentPage: 1,
      rowsPerPage: 25,
      editTicket: null,
      searchTerm:'',

      
    };
    
  }

  componentDidMount(){
    this.validateSignedIn();
    this.handleList();
  }

  validateSignedIn = async () => {
    let is_fresh_token = await getFreshToken()
    if (!is_fresh_token) {
        alert("Your session has expired, please login again!!");
        window.location.replace('/login')
        return
    }
    
}

  handleList= async ()=>{
          let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
          let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)

          console.log("----------access_tocken in issue list----------",access_tocken)
          console.log("----------dealer_uuid in issue list----------",dealer_uuid)
          let headers = {
            // 'content-type': 'application/json'
            'content-Type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${access_tocken}`,
        }
         let data = await get(`${urls.BASE_URL}/api/tickets/all-ticket-list-dealer/${dealer_uuid}/?status=OnHold`, headers)  
        console.log("----------------------------------",data);
          
              if (data && data.length > 0) {
                this.setState({
                  ticketInfo: data,
                });
                console.log("ticket info array : ",this.state.ticketInfo);
                console.log("ticket info array : ")
                
                }

      }

  handleChangePage = (newPage) => {
          this.setState({
            currentPage: newPage,
          });
      };   
  
    

    /****************************eidt/update ***************/
    handleEdit = (ticket) => {
      
      localStorage.setItem(globalVariables.SELECTED_TICKET, ticket);
      window.location.replace('/edit_Open_ticket');
    };
  

    handleDelete = async (ticketId) => {
      let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
      localStorage.setItem(globalVariables.SELECTED_TICKET, ticketId);
      let ticket_id = localStorage.getItem(globalVariables.SELECTED_TICKET);
        
        console.log("----------access_tocken in handleDelete list----------",access_tocken)
        const userConfirmed = window.confirm('Are you sure you want to delete this ticket?');

        if (!userConfirmed) {
          return; // If the user clicks "Cancel" in the confirmation dialog, abort the delete operation
        }
        
        try {
          const response = await fetch(`${urls.BASE_URL}/api/tickets/ticket-delete/${ticket_id}/`, {
            method: 'DELETE',
            headers: {
              'content-Type': 'application/json; charset=UTF-8',
              'Authorization': `Bearer ${access_tocken}`,
            },
          });
      
          if (response.ok) {
            // const data =  response.json(); 
            console.log(`Ticket with ID ${ticket_id} deleted successfully`, response);
            alert(`Ticket with ID ${ticket_id} deleted successfully`);
            window.location.reload();
            } else {
          //   // Handle error cases
            console.error(`Failed to delete ticket with ID ${ticket_id}`);
          }
        } catch (error) {
          console.error('Error during delete request:', error);
        }
        

    }

    handleSearchChange = (e) => {
      this.setState({ searchTerm: e.target.value });
  };

  /*******************filter records from date- to date************* */

  handleFromDateChange = (date) => {
    console.log("Selected date:", date);
    this.setState({ fromDate: date }, () => {
      console.log("Updated from date:", this.state.fromDate);
    });
    // console.log("Updated from date:", this.state.fromDate);
  };

  handleToDateChange = (date) => {
    // this.setState({ toDate: date });
    this.setState({ toDate: date }, () => {
      console.log("Updated toDate :", this.state.toDate);
    });
    // console.log("tooooooooo date", this.state.toDate);
  };

    
render(){
    const { ticketInfo, currentPage, rowsPerPage, searchTerm, fromDate, toDate } = this.state;
    
        const filteredTicketInfo = ticketInfo.filter((ticket) =>
        ticket.ticket_key.toLowerCase().includes(searchTerm.toLowerCase())||
        ticket.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ticket.priority.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ticket.label.toLowerCase().includes(searchTerm.toLowerCase())||
        ticket.assignee_name.toLowerCase().includes(searchTerm.toLowerCase())
        );

        // Filter based on date range
        const filteredByDate = filteredTicketInfo.filter((ticket) => {
          const ticketDate = new Date(ticket.update_date);
          ticketDate.setHours(0, 0, 0, 0); // Set time to midnight to compare only the date
        
          if (fromDate && toDate) {
            const fromDateMidnight = new Date(fromDate);
            const toDateMidnight = new Date(toDate);
        
            fromDateMidnight.setHours(0, 0, 0, 0);
            toDateMidnight.setHours(0, 0, 0, 0);
        
            return ticketDate >= fromDateMidnight && ticketDate <= toDateMidnight;
          } else {
            return true;
          }
        });
    

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredByDate.slice(indexOfFirstRow, indexOfLastRow);

  return (
    <div className='vh-100 scroll_hidden'>
              <ActivityTabs/>
                {/* <div style={{ display: 'flex', backgroundColor:"#664fa7", color:"white", paddingTop:'2px' }}> */}
                <div className="sem-header-container">
                    <span><h3 style={{height:"30px", paddingLeft:"15px", marginTop:"1px", paddingTop:"5px"}}> Smart Elevator Maintenance &emsp; &gt;&gt; 
                    <Link id="navigation" to="/amc_count">Home</Link> &gt;&gt; All On-hold tickets &gt;&gt;
                    </h3></span>
                    <span style={{ marginLeft: 'auto', marginRight:'5px',marginTop:"9px" }}>
                        <input
                            type="text"
                            id='search-input'
                            size="small"
                            value={searchTerm}
                            onChange={this.handleSearchChange}
                            placeholder=" search "
                        />
                        
                    </span>
                </div>
        
      <div>
                
          <div style={{marginLeft:'10px', marginTop:'70px'}}>
          <hr style={{backgroundColor: '#e1e2ce', height:'1px', border:'0.1px'}}></hr>
          
          <label style={{color:'#0000ff'}}>Filters </label><br/>
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
            <span>
              
                <label style={{fontSize:'14px', color:'#664fa7'}}><b>From Date: </b>&nbsp;</label>
                  <input
                    type="date"
                    value={fromDate ? fromDate.toISOString().split('T')[0] : ''}
                    onChange={(e) => this.handleFromDateChange(new Date(e.target.value))}
                  />&emsp;
                                
                  <label style={{fontSize:'14px', color:'#664fa7'}}><b>To Date: </b>&nbsp;</label>
                  <input
                    type="date"
                    value={toDate ? toDate.toISOString().split('T')[0] : ''}
                    onChange={(e) => this.handleToDateChange(new Date(e.target.value))}
                  />
            </span>
            <span>
              <label style={{fontSize:'14px', color:'#664fa7'}}><b>Search with keywords: </b>&nbsp;</label>
                        <input
                            type="text"
                            id='search-keyword'
                            size="small"
                            value={searchTerm}
                            onChange={this.handleSearchChange}
                            placeholder=" ticket-key/  status/  priority/  assignee/  label "
                        />
                        {/* <button id='search' onClick={this.handleSearch} >Search</button> */}
            </span>
            </div>
            
          </div>
          <hr style={{backgroundColor: '#e1e2ce', height:'0.5px', border:'0.1px', marginTop:'15px'}}></hr>
          <br/>
          
          <div>
             
            <table>
                <thead>
                  <tr>
                    <th>Ticket key</th>
                    <th>Elevator Name</th>
                      <th>Status</th>
                      <th>Priority</th>
                      
                      <th>Label</th>
                      <th>Assignee</th>
                      <th>Updated</th>
                      <th>Action</th>
                  </tr>
                </thead>   
                <tbody>
                  {currentRows.map((ticket) => (
                        <tr key={ticket.ticket_key}>

                          <td>
                            <span style={{
                                    display: 'inline-block',
                                    backgroundColor:"#664fa7",
                                    color:"white",
                                    paddingTop: '1.1px',
                                    paddingLeft:'2.5px',
                                    paddingRight:'2.5px',
                                    borderRadius: '4px',
                                    }}>
                              {ticket.ticket_key}
                            </span>
                          </td>
                          <td>{ticket.elevator_name}</td>
                          <td style={{ color:ticket.status.toLowerCase() === 'open' ? 'rgb(224, 15, 15)' : 'inherit' }}>{ticket.status}</td>
                          

                          <td>
                              {ticket.priority === 'High' ? (
                                <span style={{color:'rgb(224, 15, 15)', fontSize:'1.2em'}}> &uarr;&uarr; </span>
                              ) : ticket.priority === 'Medium' ? (
                                <span ><img width="12" height="12" src="https://img.icons8.com/metro/26/228BE6/equal-sign.png" alt="equal-sign"/> </span>
                              ) : ticket.priority === 'Critical' ? (
                                <span ><img width="12" height="17" src="https://img.icons8.com/fluency-systems-filled/48/FA5252/chevron.png" alt="chevron"/> </span>
                              ):null}
                              {ticket.priority}
                          </td>
                          
                          <td>{ticket.label}</td>

                          <td>{ticket.assignee_name}</td>

                          <td>
                          {new Date(ticket.update_date).toLocaleString('en-US', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true,
                          })} </td>
                          

                          <td>
                            <button style={{ fontWeight:'bold'}} onClick={() => this.handleEdit(ticket.ticket_id)} > Edit </button> &nbsp;
                            <button style={{ color:'rgb(224, 15, 15)', fontWeight:'bold'}} onClick={() => this.handleDelete(ticket.ticket_id)}>
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                </tbody>
            </table>
                
          </div>

          <div style={{marginTop:'2.2cm', display: 'flex', justifyContent: 'center', marginBottom:'2.2cm' }}>
            <button id ='rowsPerPageButton' onClick={() => this.handleChangePage(currentPage - 1)} disabled={currentPage === 1}>
              {"< Prev"}
            </button>
            <span style={{fontSize:'small'}}>
              {`Page ${currentPage} / ${Math.ceil(ticketInfo.length / rowsPerPage)}`}
            </span>
            <button id ='rowsPerPageButton' onClick={() => this.handleChangePage(currentPage + 1)} disabled={currentPage === Math.ceil(ticketInfo.length / rowsPerPage)}>
              {"Next >"}
            </button>
          </div>
        </div> 
                
        <div id="copyright-footer">
            <p style={{fontSize:'12px'}}>
            <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
            </p>
          </div>
        </div>
  );
}
}
export default AllOnholdTickets;