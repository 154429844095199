import React from 'react';
import '../adminDash.css';
import { Link } from 'react-router-dom';
import Homepage from '../Admin/Homepage';
import Footer from './Footer';

function PrivacyPolicyContent() {
  return (
    <div >
        <Homepage/>
        <div  style={{paddingLeft:'8.6%',paddingRight:'8.6%' }}>
          <div className='privacy'>
          <p className="privacy-bordered-div" style={{fontFamily:'serif', fontSize:'1em' }}>
          <h3>Privacy Policy</h3>
Effective Date: 17-05-2024<br/>
Welcome to SEM-APP. We are committed to protecting your privacy and ensuring that your personal 
information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, 
use, and protect your information when you use our elevator maintenance application. 

<h4 style={{color:'#56cfe1'}}> Information We Collect </h4>
a. Personal Information : &nbsp;
We do not collect any personally identifiable information (PII) from users of our App. <br/>
b. Non-Personal Information : &nbsp;
We may collect non-personal information about the use of the App. This information is used solely for 
improving the performance and functionality of the App. 

<h4 style={{color:'#48bfe3'}}> Use of Information </h4>
a. Personal Information : &nbsp;
Since we do not collect personal information, we do not use it for any purposes. <br/>
b. Non-Personal Information : &nbsp;
We may use non-personal information to: <br/>
<ul>
  <li>Enhance the functionality of the App. </li>
  <li>Improve user experience. </li>
  <li>Analyze trends and usage patterns to improve the App. </li>
</ul>
 
<h4 style={{color:'#4ea8de'}}> Data Sharing and Disclosure </h4><br/>
a. Personal Information : &nbsp;
We do not share, sell, rent, or trade any personal information as we do not collect any PII from our users.<br/>
b. Non-Personal Information : &nbsp;
Non-personal information collected is not shared with any third parties. 

<h4 style={{color:'#5390d9'}}> Data Security </h4>
We implement reasonable security measures to protect against unauthorized access to or unauthorized 
alteration, disclosure, or destruction of data. However, please be aware that no security measures are 
perfect or impenetrable.

<h4 style={{color:'#5e60ce'}}> Changes to This Privacy Policy </h4>
We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new 
Privacy Policy within the App. You are advised to review this Privacy Policy periodically for any changes. 

<h4 style={{color:'#6930c3'}}> Contact Us </h4>
Neudeep Technologies Pvt Ltd. B-21, <br/>
Manisha Blitz , Pune Solapur Road , <br/>
Hadapsar, Pune,Maharashtra. 411028 <br/>
sagar.swami@neudeep.in<br/>
+91 9075310243 <br/>

<div style={{color:'#7400b8', marginTop:'15px'}}>
<b><em>By using the App, you agree to the terms and conditions of this Privacy Policy.
Thank you for using  SEMAPP</em></b>
</div><br/><br/>
          </p>
        </div>
        
        </div><br/>
        <Footer/>
    </div>
  );
}

export default PrivacyPolicyContent;
