import React, { Component } from 'react';
import { get } from '../../global_files/serverRequests';
import globalVariables from "../../global_files/globalVariables";
import '../adminDash.css';
import { getFreshToken } from '../../global_files/auth';
import urls from '../../global_files/urls';
import GlobalTabpanel from './Tab_panel_global';
import { Link } from 'react-router-dom';
import ActivityTabs from "./ActivityTabs";
import axios from "axios";


class EditElevator extends Component {

  constructor(props) {
    super(props);
    this.state = {
    employee_list:[],
    ticket_data:[],

    edit_elevator_name:'',
               
    edit_building_name: '',
    edit_elevator_address:'',
    edit_elevator_zip: '',
    edit_install_date:'',
    edit_main_state:'',
    edit_main_due_date:'',
    edit_main_start_date:'',
    edit_main_completed_date :'',
    assignee_id:'',
    user_id:[],
    subscriptionIds:[], 

    maintenance_state_recieved:''
    };
  }

  componentDidMount(){
     this.fetchElevatorData();
    this.getemployeeList();
    this.validateSignedIn();
    this.getPIDListToSendNotification();
  }

  validateSignedIn = async () => {
    let is_fresh_token = await getFreshToken()
    if (!is_fresh_token) {
      alert("Your session has expired, please login again!!");
        this.props.history.replace("/");
        // window.location.replace('/login')
        return
    }
    
}

   fetchElevatorData=async()=> {
    try {
      let selected_elevator_id = localStorage.getItem(globalVariables.SELECTED_ELEVATOR_id);
      let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    
    console.log("----------selected_elevator_id in fetchTechData----------",selected_elevator_id)
    let headers = {
      
      'content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${access_tocken}`,
  }
      
      const data = await fetch(`${urls.BASE_URL}/api/elevators/${selected_elevator_id}/`, {
        headers: headers,
        method: 'PATCH',
        // body: post_data,
    });
      let response = await data.json();

      // Step 3: Populate the input boxes with the fetched data
      // this.setState({ issueData: response });
      console.log("================fetchElevatorData ressss=============", response);
      console.log("================fetchElevatorData data=============", data);
      // if (response && response.length > 0) {
                  this.setState({
                    edit_elevator_name:response.elevator_name,
               
                    edit_building_name: response.building_name,
                    edit_elevator_address:response.elevator_address,
                     edit_elevator_zip: response.elevator_zip,
                     edit_install_date:response.install_date,
                     edit_main_state:response.main_state,
                     maintenance_state_recieved:response.main_state,
                     edit_main_due_date:response.main_due_date,
                     edit_main_start_date:response.main_start_date,
                     edit_main_completed_date : response.main_completed_date,
                     edit_main_cycle_days:response.main_cycle_days,
                     assignee_id : response.user_id[1],
                     user_id: response.user_id
            
            
                        
                   
               });
                  console.log("=========maintenance_state_recieved info  : ", this.state.maintenance_state_recieved);
            //       console.log("=========edit_tech_email info  : ", this.state.edit_cust_email);
            //       console.log("=========edit_first_name info  : ", this.state.edit_first_name);
            //       console.log("=========edit_last_name info  : ", this.state.edit_last_name);
          // }
    } catch (error) {
      console.error('Error fetching issue data:', error);
    }
  }
  

    jsonData = () => {
       
      let data = {    
               
        "building_name": this.state.edit_building_name,
        "elevator_address" : this.state.edit_elevator_address,
        "elevator_zip": this.state.edit_elevator_zip,
        "elevator_name" : this.state.edit_elevator_name,
        "main_state" : this.state.edit_main_state,
        "main_start_date": this.state.edit_main_start_date,
        "main_due_date" : this.state.edit_main_due_date,
        "install_date" : this.state.edit_install_date,
        "main_cycle_days" :this.state.edit_main_cycle_days,
        "user_id" : this.state.user_id,
                
      }
      if (this.state.edit_main_completed_date) {
        data.main_completed_date = this.state.edit_main_completed_date;
      }
      console.log("-----JSON DATA -------->",data)
      return JSON.stringify(data)
    }
  
  insertUpdatedElevatorInfo = async () => {
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    let selected_elevator_id = localStorage.getItem(globalVariables.SELECTED_ELEVATOR_id);

    // localStorage.setItem(globalVariables.USER_ID, selected_user_id);
        // let SelectedUserId = localStorage.getItem(globalVariables.USER_ID);
        // let cust_id = localStorage.getItem(globalVariables.USER_ID);
          console.log("----------access_tocken in insertUpdatedElevatorInfo----------",access_tocken)
          let headers = {
            // 'content-type': 'application/json'
            'content-Type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${access_tocken}`,
        }
    
    let post_data = this.jsonData()
    console.log("post_data in insertUpdatedElevatorInfo---------->",post_data);
    // Parse the JSON string into an object
  let postDataObj = JSON.parse(post_data);

  for (const key in postDataObj) {
    if (key !== 'main_completed_date' && (postDataObj[key] === null || postDataObj[key] === undefined || postDataObj[key] === "")) {
      alert("Please fill in all the required fields.");
      return;
    }
  }
  const response = await fetch(`${urls.BASE_URL}/api/elevators/${selected_elevator_id}/`, { 
    headers: headers,
    method: 'PATCH',
    body: post_data,
  });
  console.log("response from update elevator------->", response);
  if (response.ok){
      alert("Elevator info updated successfully");
      if ((this.state.maintenance_state_recieved != "Completed") && (this.state.edit_main_state == "Completed")){
        await this.SendNotification();
      }
      
       window.location.replace("/elevators");
  }
  
  }

  getemployeeList= async ()=>{
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)
    localStorage.setItem(globalVariables.USER_ID,dealer_uuid)
    console.log("----------access_tocken in employee list----------",access_tocken);
    console.log("----------dealer_uuid in employee list----------",dealer_uuid);
    let headers = {
      
      'content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${access_tocken}`,
  }
  let response = await get(`${urls.BASE_URL}/api/account/${dealer_uuid}/employee_list/`, headers)
    
      let data = response.results
        console.log("----------data in employee list----------",data);
        let userr_Id = [];
        if (data && data.length > 0) {
          userr_Id = data.map(employee => employee.user_id);
          console.log("*******************************user_Id****",userr_Id);
         
          this.setState({
            employee_list: data,
           
          });

          console.log("============ getemployeeList array : ",this.state.employee_list);
                    
        }
      }

      ChangeAssignee=(assignee_id )=>{ //user_id = [dealer_id, selected]
        console.log("------assignee_id----", assignee_id)
        let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)

        let user_id = [dealer_uuid, assignee_id];
        // this.setState({ user_id: user_id });
        this.setState({ assignee_id: assignee_id, user_id: user_id });
          
          // this.state.user_id = [dealer_uuid, assignee_id];
          
          // Assuming you want to console log the user_id
          console.log('User ID::::::::::::::::::', this.state.user_id);
        // };
      }

      getPIDListToSendNotification= async ()=>{
        let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN);
        let selected_elevator_id = localStorage.getItem(globalVariables.SELECTED_ELEVATOR_id);
         let headers = {
          'content-Type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${access_tocken}`,
      }
      let data = await get(`${urls.BASE_URL}/api/elevators/${selected_elevator_id}/customer_dealer_pid/`, headers) 
        console.log("----------------------------------",data);
        // if (data !== false) {
          //  data = data.app_pids
           this.setState({subscriptionIds:data})
            console.log("----------data in Elevator getPIDListToSendNotification list----------",data);
         
      }
        
        SendNotification = async () => {
          let ElevatorName = localStorage.getItem(globalVariables.SELECTED_ELEVATOR);
      
          let subscriptionIds =this.state.subscriptionIds
          console.log("----ticket key in SendNotification", ElevatorName);
          const headers = {
              'Content-Type': 'application/json; charset=utf-8',
              'Authorization': 'Basic ZjRhOGQ4ZGUtOWQxZS00M2I1LWE1MmYtYzFmNzBjYzdlNzA0'
          };
      
          const body = {
              app_id: '999400f4-2917-4a6e-bf38-28a1e25feb29',
              // included_segments: ['All'], // Send to all subscribers 
              include_external_user_ids: subscriptionIds, //----> specific users  include_player_ids: subscriptionIds, // Target specific users
              headings:{en: `Your 'Annual Maintenance Contract' is Updated !`},
              contents: { en: `${ElevatorName} elevator's maintenance is Completed` }, // Notification content
          };
      
          try {
              const response = await axios.post('https://onesignal.com/api/v1/notifications', body, { headers });
              alert("Successfully sent the notification for maintenance completion to the customer");
              console.log('Notification sent successfully:', response.data);
          } catch (error) {
              console.error('Error sending notification:', error.response.data);
          }
      };

  render(){
    const { employee_list } = this.state;
   
  return (
    <div>
            <ActivityTabs/>
                {/* <div style={{ display: 'flex', backgroundColor:"#664fa7", color:"white", paddingTop:'2px' }}> */}
                <div className="sem-header-container">
                    <span><h3 style={{height:"30px", paddingLeft:"15px", marginTop:"1px", paddingTop:"5px"}}> Smart Elevator Maintenance &emsp; &gt;&gt; &nbsp;
                    <Link id="navigation" to="/elevators"> Elevators </Link> &gt;&gt; &nbsp; Edit Elevator </h3></span>
                    <span style={{ marginLeft: 'auto', marginRight:'5px',marginTop:"9px" }}>
                        <input
                            type="text"
                            id='search-input'
                            size="small"
                            placeholder=" search "
                        />
                    </span>
                </div>
                <GlobalTabpanel/>
                <div className="bordered-div" >
            <div>
                  <label htmlFor="ele_name" style={{marginLeft : '95px'}}> Elevator Name &nbsp; : &emsp;&emsp;&emsp;&emsp;&nbsp;</label>
                    <input
                      type="text"
                      id="ele_name"
                      name="ele_name"
                      readOnly
                      style={{ width: '350px', height: '0.7cm' }}
                      value={this.state.edit_elevator_name}
                      // onChange={(evt) => this.setState({ edit_elevator_name: evt.target.value })}
                    />
               </div>
               <br/>
               <div style={{ marginLeft:'30px'}}>
                  <label htmlFor="building_name" style={{marginLeft : '65px', marginRight:'30px'}}>Building Name <span style={{color:'red'}}>*</span>&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp;&emsp;</label>
                  <input
                      type="text"
                      id="building_name"
                      name="building_name"
                      required
                      style={{ width: '350px', height: '0.7cm' }}
                      value={this.state.edit_building_name}
                      onChange={(evt) => this.setState({ edit_building_name: evt.target.value })}
                    />
               </div>
               <br/>
               <div>
                  <label htmlFor="ele_address" style={{marginLeft : '95px'}}> Elevator address <span style={{color:'red'}}>*</span>&ensp; : &nbsp;&emsp;&emsp;&ensp;</label>
                    <input
                      type="text"
                      id="elev_address"
                      name="elev_address"
                      required
                      style={{ width: '350px', height: '0.7cm' }}
                      value={this.state.edit_elevator_address}
                      onChange={(evt) => this.setState({ edit_elevator_address: evt.target.value })}
                    />
               </div>
               <br/>
               <div>
                  <label htmlFor="zip_code" style={{marginLeft : '95px'}}> Zip Code <span style={{color:'red'}}>*</span>&emsp; : &nbsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;</label>
                    <input
                      type="text"
                      id="zip_code"
                      name="zip_code"
                      required
                      style={{ width: '350px', height: '0.7cm' }}
                      
                      value={this.state.edit_elevator_zip}
                      onChange={(evt) => this.setState({ edit_elevator_zip: evt.target.value })}
                    />
               </div>
               <br/>
              
               <div>
               <label htmlFor="installtion_date" style={{marginLeft : '95px'}} >Installation Date &nbsp;&nbsp;: &ensp;&emsp;&emsp;&emsp;</label>
                  <input 
                  type="date" 
                  id="install_date" 
                  name="install_date" 
                  style={{ width: '150px', height: '0.7cm' }}
                  value={this.state.edit_install_date}
                  onChange={(evt) => this.setState({ edit_install_date: evt.target.value })}
                  // onChange={(evt) => this.handleDateChange(evt, 'create_date')}
                  ></input>
               </div>
               <br/>
               <div>
                  <label htmlFor="main_start_date" style={{marginLeft : '95px'}}> Maintenance Start Date <span style={{color:'red'}}>*</span> : &nbsp;</label>
                  <input 
                  type="date" 
                  id="main_start_date" 
                  name="main_start_date" 
                  style={{ width: '150px', height: '0.7cm' }}
                  value={this.state.edit_main_start_date}
                  onChange={(evt) => this.setState({ edit_main_start_date: evt.target.value })}
                  ></input>
               </div>
               <br/>
               <div>
                  <label htmlFor="main_due_date" style={{marginLeft : '95px'}}> Maintenance Due Date <span style={{color:'red'}}>*</span> &nbsp;:&ensp;</label>
                  <input
                      type="date"
                      id="main_due_date"
                      name="main_due_date"
                      required
                      style={{ width: '150px', height: '0.7cm' }}
                      value={this.state.edit_main_due_date}
                      onChange={(evt) => this.setState({ edit_main_due_date: evt.target.value })}
                    />
               </div>
               <br/>
               <div>
                  <label htmlFor="main_completed_date" style={{marginLeft : '95px'}}> Maintenance Completed Date <span style={{color:'red'}}>*</span> &nbsp;:&ensp;</label>
                  <input
                      type="date"
                      id="main_completed_date"
                      name="main_completed_date"
                      required
                      style={{ width: '150px', height: '0.7cm' }}
                      value={this.state.edit_main_completed_date}
                      onChange={(evt) => this.setState({ edit_main_completed_date: evt.target.value })}
                    />
               </div>
               <br/>
               <div>
                  <label htmlFor="main_cycle_days" style={{marginLeft : '95px'}}> Duration Cycle <span style={{color:'red'}}>*</span> &nbsp;&nbsp;&nbsp; : &emsp;&emsp;&emsp;</label>
                  <select
                    id="main_cycle_days"
                    name="main_cycle_days"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.edit_main_cycle_days}
                    onChange={(evt) => this.setState({edit_main_cycle_days: evt.target.value })}
                  >
                    <option value="30"> Monthly </option>
                    <option value="90"> Quarterly </option>
                 </select>
               </div>
               <br/>
               <div>
                  <label htmlFor="main_state" style={{marginLeft : '95px'}}> Maintenance State <span style={{color:'red'}}>*</span> &nbsp;&nbsp;&nbsp; : &emsp;&nbsp;</label>
                  <select
                    id="main_state"
                    name="main_state"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.edit_main_state}
                    onChange={(evt) => this.setState({edit_main_state: evt.target.value })}
                  >
                    <option value="Completed"> Completed </option>
                    <option value="InDue"> InDue </option>
                    <option value="OverDue"> OverDue </option>
                 </select>
               </div>
               <br/>
               <div>
                  <label htmlFor="assignee" style={{marginLeft : '95px'}}> Assign lift to Engineer <span style={{color:'red'}}>*</span> &ensp;: &nbsp;&nbsp;</label>
                  <select
                    id="assignee"
                    name="assignee"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.assignee_id}
                    onChange={(evt) => this.ChangeAssignee(evt.target.value)}
                    >
                    <option > Select Technician </option>
                    
                    {employee_list.map((item, index) => (
                      <option key={index} value={item.user_id}>
                        {item.username}
                      </option>
                    ))}
                  </select>
               </div>
               <br/>
               
            </div>

         
         <div style={{ width: '160px', height: '0.4cm', marginLeft:'130px', marginTop:'8px', marginBottom: '100px'  }}>
          <button style={{backgroundColor:'#664fa7', color:'white'}} onClick={this.insertUpdatedElevatorInfo}> update Elevator Info</button>
         </div> 

         <div id="copyright-footer">
            <p style={{fontSize:'12px'}}>
            <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
            </p>
          </div>
    </div>
    
    
    
  )
}
}


export default EditElevator;