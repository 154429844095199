import axios from "axios";
import React, { Component } from 'react';
import { post, get } from '../../global_files/serverRequests';
import globalVariables from "../../global_files/globalVariables";
import { Link } from 'react-router-dom';
import '../adminDash.css';
import TabpanelForAllTechIssues from './tabpanel_allTech_Issues';
import { getFreshToken } from '../../global_files/auth';
import urls from '../../global_files/urls';
import ActivityTabs from "./ActivityTabs";



class EditHomePageTicket extends Component {

  constructor(props) {
    super(props);
    this.state = {
     
    employee_list:[],
    ticket_data:[],

    is_required:'',

    customFieldArray:[],
    val: []
    
    };
  }

  componentDidMount(){
    this.fetchIssueData();
    this.getemployeeList();
    this.validateSignedIn();
    this.getCustomFields();
  }


validateSignedIn = async () => {
  let is_fresh_token = await getFreshToken()
  if (!is_fresh_token) {
    console.log("*************************is_fresh_token*******************************", is_fresh_token)
    alert("Your session has expired, please login again!!");
      window.location.replace('/login')
     return
  }
}

   fetchIssueData=async()=> {
    try {
      let ticket = localStorage.getItem(globalVariables.SELECTED_TICKET);
      let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    
    console.log("----------access_tocken in employee list----------",access_tocken)
    let headers = {
      
      'content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${access_tocken}`,
  }
      let response = await get (`${urls.BASE_URL}/api/tickets/ticket-details/${ticket}/`, headers);
      
      console.log("================Issue data =============", response);
      
                  this.setState({
                    // ticket_data: response,
                    editElevatorId: response.elevator_id,
                    getTicketId: response.ticket_id,
                    editTicketKey: response.ticket_key,
                    editSummary: response.summary,
                    editDescription: response.description,
                    editlabel: response.label,
                    editReporter: response.reporter_name,
                    editAssignee: response.assignee_name,
                    editStatus: response.status,
                    editResolution: response.resolution,
                    editPriority: response.priority,
                    editStartDate: response.start_date,
                    editTargetDate: response.target_date,
                    editCloseDate: response.close_date,
               });
                  console.log("=========editElevatorId info  : ", this.state.editPriority);
                  console.log("=========editElevatorId info  : ", this.state.editResolution);
                  console.log("=========editElevatorId info  : ", this.state.editReporter);
                  console.log("=========editElevatorId info  : ", this.state.editStatus);
          // }
    } catch (error) {
      console.error('Error fetching issue data:', error);
    }
  }
  
  getemployeeList= async ()=>{
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)
    console.log("----------access_tocken in employee list----------",access_tocken)
    let headers = {
      // 'content-type': 'application/json'
      'content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${access_tocken}`,
  }
  let data = await get(`${urls.BASE_URL}/api/account/${dealer_uuid}/employee_list/`, headers)
    // console.log("----------------------------------",data);
    
       data = data.results
        console.log("----------data in employee list----------",data);
        if (data && data.length > 0) {
          this.setState({
            employee_list: data,
          });
          console.log("ticket info array : ",this.state.employee_list);
        }

    }


    jsonData = () => {
      const { editAssignee, employee_list /* other fields */ } = this.state;

  // Find the corresponding user_id based on the selected username
  const selectedEmployee = employee_list.find((item) => item.username === editAssignee);
  const user_id = selectedEmployee ? selectedEmployee.user_id : null;
      let data = {
          
          // "elevator_id": this.state.editElevatorId,
          // "ticket_key":this.state.ticket_key,
          "ticket_id":this.state.ticket_id,
          
          "summary":this.state.editSummary, 
          "description":this.state.editDescription,
          "label":this.state.editlabel, 
          // "reporter":this.state.reporter_name,
          "assignee":user_id,
          "status":this.state.editStatus,
          "resolution":this.state.editResolution,
          "priority":this.state.editPriority,
          // "create_date":this.state.create_date,
          "start_date":this.state.editStartDate,
          "target_date":this.state.editTargetDate,
          "close_date":this.state.editCloseDate,
                
      }
      console.log("-----JSON DATA -------->",data);
      return JSON.stringify(data)
    }
  
  insertUpdatedTicketInfo = async () => {
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
          console.log("----------access_tocken in issue list----------",access_tocken)
          let headers = {
            // 'content-type': 'application/json'
            'content-Type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${access_tocken}`,
        }
    
    let post_data = this.jsonData()
    console.log("post_data in insertUpdatedTicketInfo---------->",post_data);
    // Parse the JSON string into an object
    let postDataObj = JSON.parse(post_data);

    for (const key in postDataObj) {
      if (key !== 'close_date' && (postDataObj[key] === null || postDataObj[key] === "")) {
        alert("Please fill in all the required fields.");
        return;
      }
    }

    let response = await post(`${urls.BASE_URL}/api/tickets/ticket-update/${this.state.getTicketId}/`, post_data, headers)
    if (response) {
        console.log("response in insertCreateTicketInfo ---->",response)
       
            alert('Ticket updated successfully !!!')
            
            console.log("=============successfull insertUpdatedTicketInfo =======",response);
           await this.SendNotification()
            window.location.replace("/all_open_tickets");
        }
        
    else {
        alert("Some error/Server error occured, can not update the ticket")
        console.log("============= Unsuccessfull insertUpdatedTicketInfo =======",response);
        
    }
  }

  SendNotification = async () => {
    let ticket_key = this.state.editTicketKey
    let editAssignee = this.state.editAssignee
    let status = this.state.editStatus
   
    console.log("----ticket key in SendNotification", ticket_key);
    const headers = {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Basic ZjRhOGQ4ZGUtOWQxZS00M2I1LWE1MmYtYzFmNzBjYzdlNzA0'
    };

    const body = {
        app_id: '999400f4-2917-4a6e-bf38-28a1e25feb29',
        included_segments: ['All'], // Send to all subscribers
        headings:{en: `Ticket ${ticket_key} is Updated !`},
        contents: { en: `Ticket status is ${status} and Assignee is ${editAssignee}` }, // Notification content
    };

    try {
        const response = await axios.post('https://onesignal.com/api/v1/notifications', body, { headers });
        console.log('Notification sent successfully:', response.data);
    } catch (error) {
        console.error('Error sending notification:', error.response.data);
    }
};

getCustomFields= async ()=>{
  let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
  let headers = {
    'content-Type': 'application/json; charset=UTF-8',
    'Authorization': `Bearer ${access_tocken}`,
}
let data = await get(`${urls.BASE_URL}/api/custom_fields/get_custom_fields_by_type/ticket/`, headers) 
  console.log("----------------getCustomFields ------------------",data);
      this.setState({
        customFieldArray: data
      //   customFieldName:data.name,
      //   customFieldType:data.format,
      //   customFieldDescription:data.description,
      //   is_required: data.is_required
      })
    //  data = data.app_pids
    //  this.setState({subscriptionIds:data})
    //   console.log("----------data in getPIDListToSendNotification list----------",data);
    
}

  handleChange = (e, i) => {            // Method to handle change in input value for a custom field
    const { val } = this.state;
    const inputdata = [...val];         // Creating a copy of the array of custom fields
  
    if (e.target.type === 'checkbox') {
      // If it's a checkbox, update the value in the state with true/false
      inputdata[i] = {  value: e.target.checked };
    } else {
      // For other input types, update the value with the new input value
      inputdata[i] = { value: e.target.value };
    }

    this.setState({ val: inputdata }); // Setting the updated array in the component's state

    console.log("------custom values in val array---------", this.state.val);

  };
  
  render(){
    const { employee_list, customFieldArray } = this.state;
    let user_name = localStorage.getItem(globalVariables.USER_NAME);
  return (
    <div>
         <ActivityTabs/>
                {/* <div style={{ display: 'flex', backgroundColor:"#664fa7", color:"white", paddingTop:'2px' }}> */}
                  <div className="sem-header-container">
                    <span><h3 style={{height:"30px", paddingLeft:"15px", marginTop:"1px", paddingTop:"5px"}}> Smart Elevator Maintenance &emsp; &gt;&gt; 
                    <Link to ="/amc_count" id="navigation"> Home</Link>&emsp;&gt;&gt;Edit &gt;&gt;
                    
                    </h3></span>
                    <span style={{ marginLeft: 'auto', marginRight:'5px',marginTop:"9px" }}>
                        <input
                            type="text"
                            id='search-input'
                            size="small"
                            placeholder=" search "
                        />
                    </span>
                </div>
        <TabpanelForAllTechIssues/>
            <div className="bordered-div" >
              <form style={{ marginLeft:'7%'}}>
               <div >
                  <label htmlFor="ele_Id" style={{marginLeft : '65px'}}>Elevator Id &ensp;: &nbsp;</label>
                  <input
                    id="ele_Id"
                    type="text"
                    // name="ele_Id"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.editElevatorId}
                    readOnly
                    // onChange={handleChange}
                  />
                  </div>
               <br/>
               <div>
                  <label htmlFor="key" > Ticket key &ensp; : &nbsp;</label>
                    <input
                      type="text"
                      id="key"
                      name="key"
                      required
                      style={{ width: '350px', height: '0.7cm' }}
                      value={this.state.editTicketKey}
                      readOnly
                      // onChange={handleChange}
                    />
               </div>
               <br/>
               <div>
                  <label htmlFor="label" > Label &ensp;&emsp;&emsp; : &nbsp;</label>
                    <input
                      type="text"
                      id="label"
                      name="label"
                      required
                      style={{ width: '350px', height: '0.7cm' }}
                      value={this.state.editlabel}
                      onChange={(e) => this.setState({ editlabel: e.target.value })}
                    />
               </div>
               <br/>
               <div>
                  <label htmlFor="summary" > Summary  &nbsp;&nbsp;&nbsp; : &nbsp;</label>
                  <input
                      type="text"
                      id="summary"
                      name="summary"
                      required
                      style={{ width: '350px', height: '0.7cm' }}
                      value={this.state.editSummary}
                      onChange={(e) => this.setState({ editSummary: e.target.value })}
                    />
               </div>
               <br/>
               <div>
                  <label htmlFor="desc"> Description  &nbsp;: &nbsp;</label>
                  <input
                      type="text"
                      id="desc"
                      name="desc"
                      required
                      style={{ width: '350px', height: '0.7cm' }}
                      value={this.state.editDescription}
                      onChange={(e) => this.setState({ editDescription: e.target.value })}
                    />
               </div>
               <br/>
               <div>
                  <label htmlFor="reporter" > Reporter  &emsp;&nbsp;&nbsp;: &nbsp;</label>
                 
                  <select
                    id="reporter"
                    name="reporter"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.editReporter}
                    readOnly
                    onChange={(e) => this.setState({ editReporter: e.target.value })}
                  >
                    <option > ----- </option>
                    <option  value={user_name} > {user_name} </option>
                    {employee_list.map((item, index) => (
                      <option key={index} value={item.username}>
                        {item.username}
                      </option>
                    ))}
                  </select>
               </div>
               <br/>
               <div>
                  <label htmlFor="assignee" > Assignee  &ensp;&nbsp;&nbsp; : &nbsp;</label>
                  <select
                    id="assignee"
                    name="assignee"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.editAssignee}
                    onChange={(e) => this.setState({ editAssignee: e.target.value })}
                  >
                    <option > ----- </option>
                    
                    {employee_list.map((item, index) => (
                      <option key={index} value={item.username}>
                        {item.username}
                      </option>
                    ))}
                 </select>
               </div>
               <br/>
               <div>
                  <label htmlFor="status" > Status   &emsp;&emsp;&nbsp;&nbsp;: &nbsp;</label>
                  <select
                    id="status"
                    name="status"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.editStatus}
                    onChange={(e) => this.setState({ editStatus: e.target.value })}
                  >
                    <option> -Select- </option>
                    <option value="Open"> Open </option>
                    <option value="InProgress"> In Progress </option>
                    <option value="OnHold"> On Hold </option>
                    <option value="Accepted"> Accepted </option>
                    <option value="Closed"> Closed </option>
                    
                    
                  </select>
               </div>
               <br/>
               <div>
                  <label htmlFor="resolution" > Resolution  &nbsp;&nbsp;: &nbsp;</label>
                  <select
                    id="resolution"
                    name="resolution"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.editResolution}
                    readOnly
                    // onChange={(e) => this.setState({ editResolution: e.target.value })}
                  >
                    <option> -Select- </option>
                    <option value="Unresolved"> Unresolved </option>
                    <option value="CompletedToSpec"> Completed To Spec </option>
                    <option value="CompletedNotSpec"> Completed Not Spec </option>
                    <option value="CannotComplete"> Cannot Complete </option>
                    <option value="Denied"> Denied </option>
                    <option value="Duplicate"> Duplicate </option>
                    
                    
                  </select>
               </div>
               <br/>
               <div>
                  <label htmlFor="priority"> Priority  &emsp;&ensp;&nbsp;&nbsp;: &nbsp;</label>
                  <select
                    id="priority"
                    name="priority"
                    style={{ width: '150px', height: '0.7cm' }}
                    value={this.state.editPriority}
                    // readOnly
                    onChange={(e) => this.setState({ editPriority: e.target.value })}
                  >
                    <option> -Select- </option>
                    <option value="Unspecified"> Unspecified </option>
                    <option value="Critical"> Critical </option>
                    <option value="High"> High </option>
                    <option value="Medium"> Medium </option>
                    <option value="Low"> Low </option>
                      
                  </select>
               </div>
               <br/>
               <div>
               <label htmlFor="startdate" > start Date &nbsp;&nbsp;&nbsp;: &nbsp;</label>
                  <input type="date" id="startdate" name="startdate" value={this.state.editStartDate} style={{ width: '150px', height: '0.7cm' }}
                  onChange={(e) => this.setState({ editStartDate: e.target.value })}
                //   readOnly 
                  ></input>
               </div>
               <br/>
               <div>
               <label htmlFor="Tdate" > Target Date  &nbsp;: &nbsp;</label>
                  <input 
                  type="date" 
                  id="Ttdate" 
                  name="Tdate" 
                  value={this.state.editTargetDate}style={{ width: '150px', height: '0.7cm' }}
                  onChange={(e) => this.setState({ editTargetDate: e.target.value })}
                //   readOnly
                  ></input>
               </div>
               <br/>
               <div>
               <label htmlFor="cdate" > Close Date  &nbsp;: &nbsp;</label>
                  <input
                   type="date" 
                   id="cdate" 
                   name="cdate"  
                   value={this.state.editCloseDate} 
                   style={{ width: '150px', height: '0.7cm' }}
                  onChange={(e) => this.setState({ editCloseDate: e.target.value })} 
                //   readOnly
                  ></input>
               </div>

               {customFieldArray.map((data, i) => {
        // Rendering input field based on selected type
        let inputField;
        switch (data.format) {
          case "integer":
            inputField = <input type="number" 
            value={data.value} 
            onChange={(e) => this.handleChange(e, i)} 
            onKeyDown={(e) => {
              if (
                !/[0-9]/.test(e.key) &&
                !['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)
              ) {
                e.preventDefault();
              }
            }}
            />;
            break;
          case "date":
            inputField = <input type="date" 
            value={data.value} 
            onChange={(e) => this.handleChange(e, i)} 
            />;
            break;
          case "boolean":
            inputField = <input type="checkbox" 
            checked={data.value} 
            onChange={(e) => this.handleChange(e, i)} 
            />;
            break;
          default:
            inputField = <input type="text" 
            value={data.value} 
            onChange={(e) => this.handleChange(e, i)}
             />;
        }
       
        return (
          <div style={{marginTop:"20px"}} key={i}>
            {data.name} &nbsp; : &emsp;
            <input
                    type={inputField}
                    value={data.value}
                    onChange={(e) => this.handleChange(e, i)}
                  />
            {/* {inputField} &nbsp; */}
             <br />
            {/* {data.description && (
                <>
                  <br />
                  {data.description} &nbsp; : &emsp;
                  <input
                    type="text"
                    value={data.value}
                    onChange={(e) => this.handleChange(e, i)}
                  />
                </>
              )}&nbsp; */}
           
          </div>
          
        );
      })}
      </form>
         </div>
         
         <div style={{ width: '160px', height: '0.4cm', marginLeft:'130px', marginTop:'8px', marginBottom: '100px'  }}>
          <button style={{backgroundColor:'#664fa7', color:'white'}} 
          onClick={this.insertUpdatedTicketInfo}
           > update Ticket</button>
         </div> 
         <div id="copyright-footer">
            <p style={{fontSize:'12px'}}>
            <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
            </p>
          </div>
    </div>
    
    
    
  )
}
}


export default EditHomePageTicket;